import {combineReducers} from 'redux';
import {settlementConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingSuccessfulSettlements = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS:
         return true;
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_ERROR:
         return false;
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS:
         return false;
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingFailedSettlements = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_FAILED_SETTLEMENTS:
         return true;
      case settlementConstants.REQUEST_FAILED_SETTLEMENTS_ERROR:
         return false;
      case settlementConstants.REQUEST_FAILED_SETTLEMENTS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSearchingSettlement = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_SEARCH_SETTLEMENT:
         return true;
      case settlementConstants.SEARCH_SETTLEMENT_ERROR:
         return false;
      case settlementConstants.SEARCH_SETTLEMENT_SUCCESS:
         return false;
      case settlementConstants.SEARCH_SETTLEMENT_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const successfulSettlementsList = (state = [], action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS: {
         return action.successfulSettlements.data.docs;
      }
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case settlementConstants.SEARCH_SETTLEMENT_SUCCESS: {
         return action.settlement.data.docs;
      }
      case settlementConstants.SEARCH_SETTLEMENT_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const failedSettlementsList = (state = [], action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_FAILED_SETTLEMENTS_SUCCESS: {
         return action.settlements.data.docs;
      }
      case settlementConstants.SEARCH_SETTLEMENT_SUCCESS: {
         return action.settlement.data.docs;
      }
      case settlementConstants.SEARCH_SETTLEMENT_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS: {
         // const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.successfulSettlements.data;
         const result = {
            hasNextPage,
            nextPage,
            totalPages,
            totalDocs,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      // case settlementConstants.SEARCH_SETTLEMENT_SUCCESS: {
      //    const {hasNextPage, nextPage, totalDocs, limit, page} =
      //       action.settlement.data;
      //    const result = {hasNextPage, nextPage, totalDocs, limit, page};

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingSuccessfulSettlements,
   IsRequestingFailedSettlements,
   successfulSettlementsList,
   failedSettlementsList,
   IsSearchingSettlement,
   pagination,
});

export default rootReducer;
