import {loanConstants} from '../_constants';

const searchLoan = (model) => ({
   type: loanConstants.SEARCH_LOAN,
   data: model,
});

const getAllLoanRequest = (model) => ({
   type: loanConstants.GET_ALL_LOAN_REQUEST,
   data: model,
});

const getAllLoan = (model) => ({
   type: loanConstants.GET_ALL_LOAN,
   data: model,
});

const getLoanDetails = (model) => ({
   type: loanConstants.GET_LOAN_DETAILS,
   data: model,
});

const verifyLoan = (model) => ({
   type: loanConstants.VERIFY_LOAN,
   data: model,
});

const grantLoan = (model) => ({
   type: loanConstants.GRANT_LOAN,
   data: model,
});

const settleLoan = (model) => ({
   type: loanConstants.SETTLE_LOAN,
   data: model,
});

const declineLoan = (model) => ({
   type: loanConstants.DECLINE_LOAN,
   data: model,
});

const getAllLoanGranted = (model) => ({
   type: loanConstants.GET_ALL_LOAN_GRANTED,
   data: model,
});

export const loanActions = {
   searchLoan,
   getAllLoanRequest,
   getAllLoan,
   getLoanDetails,
   verifyLoan,
   grantLoan,
   settleLoan,
   declineLoan,
   getAllLoanGranted,
};
