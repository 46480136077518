import {ReactComponent as EditIcon} from '../../_assets/icons/pen_edit.svg';
// import {ReactComponent as AddressIcon} from '../../_assets/icons/location.svg';
import {ReactComponent as NextKinIcon} from '../../_assets/icons/kin.svg';
import {ReactComponent as WorkInfoIcon} from '../../_assets/icons/work_info.svg';
// import {ReactComponent as CardDetailsIcon} from '../../_assets/icons/card_details.svg';
import {ReactComponent as TransactionsIcon} from '../../_assets/icons/kin.svg';

export const profileDetails = [
   {
      activeCode: 1,
      icon: <EditIcon className="w-100 h-100" />,
      infoName: 'Basic information',
      options: 'Name, Email, Phone Number, Date Joined',
   },
   // {
   //    activeCode: 2,
   //    icon: <AddressIcon className="w-100 h-100" />,
   //    infoName: 'Address',
   //    options: 'Residential Address, Work Address',
   // },
   {
      activeCode: 3,
      icon: <NextKinIcon className="w-100 h-100" />,
      infoName: 'Next of Kin Information',
      options: 'Account Name, Bank, Relationship, Address',
   },
   {
      activeCode: 4,
      icon: <WorkInfoIcon className="w-100 h-100" />,
      infoName: 'Work information',
      options: 'Occupation, Salary, Salary Date, Staff Number',
   },
   // {
   //    activeCode: 5,
   //    icon: <CardDetailsIcon className="w-100 h-100" />,
   //    infoName: 'Bank Details',
   //    options: 'Bank Name, Bank Number',
   // },
   {
      activeCode: 6,
      icon: <TransactionsIcon className="w-100 h-100" />,
      infoName: 'Loan details',
      options: 'See loan details',
   },
];
