import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import './index.scss';
import LetteredAvatar from 'lettered-avatar';

// import img from '../../_assets/icons/1user-hash.svg';
import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
// import {numberWithCommas} from '../../_helpers';
import {customerActions} from '../../_actions';
import {CSVLink} from 'react-csv';

export function WalletCustomer() {
   const dispatch = useDispatch();

   useEffect(
      (
         model = {
            query: {},
         },
      ) => {
         dispatch(customerActions.getWalletCustomers(model));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   const IsRequestingWalletCustomers = useSelector(
      (s) => s.customer.IsRequestingWalletCustomers,
   );
   const IsSearchingCustomer = useSelector(
      (s) => s.customer.IsSearchingCustomer,
   );
   const walletCustomersList = useSelector(
      (s) => s.customer.walletCustomersList,
   );
   const pagination = useSelector((s) => s.customer.pagination);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(customerActions.getWalletCustomers(model));
   };

   const [searchInput, setSearchInput] = useState('');

   const clearText = () => {
      setSearchInput('');
   };

   const searchCustomer = () => {
      const model = {
         query: searchInput,
         type: 'wallet',
         limit: 10,
         sort: {
            firstname: 1,
         },
      };
      dispatch(customerActions.searchCustomer(model));
   };

   const [isOpen, setIsOpen] = useState(false);
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
   };

   const [isStatusOpen, setIsStatusOpen] = useState(false);
   const [select, setSelect] = useState(false);
   const toggleStatusList = () => {
      setIsStatusOpen((wasOpen) => !wasOpen);
      setSelect((wasOpen) => !wasOpen);
   };

   const handleFilter = (e, val) => {
      e.stopPropagation();
      setIsStatusOpen(false);
      console.log('val', val);
      const filterModel = {
         status: val === 1 ? true : false,
         filter: true,
      };

      dispatch(customerActions.getWalletCustomers(filterModel));
   };

   // const [isDateOpen, setIsDateOpen] = useState(false);
   // const toggleDate = () => {
   //    // setIsDateOpen(true);
   //    setIsDateOpen((wasOpen) => !wasOpen);
   // };

   const [selected, setSelected] = useState(0);
   const options = [
      {label: 'Status', val: 0},
      {label: 'Active', val: 1},
      {label: 'Inactive', val: 2},
   ];

   return (
      <MDBRow className="customer-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search ">
               <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     value={searchInput}
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon
                     className="icons i-x"
                     style={{width: '1rem'}}
                     onClick={clearText}
                  />
                  <span className="i-search">
                     <SearchIcon
                        onClick={searchCustomer}
                        style={{width: '1rem'}}
                     />
                  </span>
                  {isOpen && (
                     <div className="collaps">
                        <div
                           onClick={toggleStatusList}
                           className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                           <span className="d-block">
                              {options[selected].label}
                           </span>
                           <ArrDown className="ml-4" />
                           {isStatusOpen && (
                              <div className="status-list position-absolute">
                                 <ul className="">
                                    {options
                                       .filter((i) => i.val !== 0)
                                       .map((i, n) => (
                                          <li
                                             key={n}
                                             onClick={(e) => {
                                                // e.stopPropagation();
                                                // setIsOpen(false);
                                                handleFilter(e, i.val);
                                                // setSelected(i.val);
                                             }}>
                                             <Link className="w-100 d-block">
                                                {i.label}
                                             </Link>
                                          </li>
                                       ))}
                                 </ul>
                              </div>
                           )}
                        </div>
                     </div>
                  )}
               </div>
            </div>

            <div className="filter d-flex">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               {walletCustomersList.length > 0 && (
                  <CSVLink data={walletCustomersList} className="csv">
                     Download CSV
                  </CSVLink>
               )}
               <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div>
               {/* {isOpen && (
                  <div className="collaps">
                     <div
                        onClick={toggleDate}
                        className="collaps-item mr-5 position-relation">
                        Date Range <ArrDown className="ml-4" />
                        {isDateOpen && (
                           <div className="date p-3 position-absolute">
                              <h5>Some date functions here...</h5>
                              <p>down here...</p>
                           </div>
                        )}
                     </div>
                     <div
                        onClick={toggleStatusList}
                        className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrDown className="ml-4" />
                        {isStatusOpen && (
                           <div className="status-list position-absolute">
                              <ul className="">
                                 {options
                                    .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             // e.stopPropagation();
                                             // setIsOpen(false);
                                             setSelected(i.val);
                                          }}>
                                          <Link className="w-100 d-block">
                                             {i.label}
                                          </Link>
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
               )} */}
            </div>
         </div>

         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr className="d-table-row">
                     <th scope="col">Name</th>
                     <th scope="col">Email</th>
                     <th scope="col">Phone Number</th>
                     <th scope="col">Balance</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingWalletCustomers || IsSearchingCustomer ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {walletCustomersList?.length > 0 ? (
                        walletCustomersList.map((row) => {
                           return (
                              <Link
                                 // to={`/customer/wallet/${row.user_id}`}
                                 to={{
                                    pathname: `/customer/wallet/${row?.user_id}`,
                                    state: {customerId: row?._id},
                                 }}
                                 className="d-table-row align-items-center">
                                 <th
                                    scope="row"
                                    //className="d-flex justify-content-center align-items-center"
                                 >
                                    {/* {select && (
                                       <MDBInput
                                          className="mr-2"
                                          style={{
                                             width: '.65rem',
                                             height: '.65rem',
                                          }}
                                          type="checkbox"
                                          id="checkbox"
                                       />
                                    )} */}
                                    <LetteredAvatar
                                       name={row.firstname + ' ' + row.lastname}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass:
                                             'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>
                                       {row.firstname + ' ' + row.lastname}
                                    </span>
                                 </th>
                                 <td>{row.user?.email}</td>
                                 <td>
                                    {row.user?.phone_number?.replace(
                                       /^.{3}/g,
                                       '0',
                                    )}
                                 </td>
                                 <td>
                                    {/* {row.user?.isActive
                                       ? 'Active'
                                       : 'Not Active'} */}
                                    {row?.is_active ? 'Active' : 'Not Active'}
                                 </td>
                              </Link>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {!IsRequestingWalletCustomers && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
         {/* <div className="pagination d-flex align-items-center mt-3">
            <Link
               to="/"
               className="arrow d-flex align-items-center justify-content-center">
               <Prev style={{width: '1.3rem'}} />
            </Link>
            <ul className="d-flex justify-content-center mx-4">
               <li className="active">
                  <Link to="/" className="active">
                     1
                  </Link>
               </li>
               <li>
                  <Link to="/">2</Link>
               </li>
               <li>
                  <Link to="/">3</Link>
               </li>
               <li>
                  <Link to="/">4</Link>
               </li>
            </ul>
            <Link
               to="/"
               className="arrow d-flex align-items-center justify-content-center">
               <Next style={{width: '1.3rem'}} />
            </Link>
         </div> */}
      </MDBRow>
   );
}

export * from './view';
