import React, {useState, useCallback} from 'react';
import {MDBRow} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
// import Modal from 'react-modal';
import './index.scss';
import {userActions, appActions} from '../../_actions';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function Security() {
   const dispatch = useDispatch();
   const auth = useSelector((s) => s.auth);
   const user = useSelector((s) => s.user.user);

   // const customStyles = {
   //    content: {
   //       top: '50%',
   //       left: '50%',
   //       right: 'auto',
   //       bottom: 'auto',
   //       marginRight: '-50%',
   //       transform: 'translate(-50%, -50%)',
   //       backgroundColor: '#ffffff',
   //       borderRadius: '15px',
   //    },
   // };

   const [showInput, setShowInput] = useState(false);
   const [canSubmit, setCanSubmit] = useState(false);
   const [passError, setPassError] = useState('');
   const [oldPasswordValueError, setPasswordError] = useState('');
   const [newPasswordValueError, setNewPasswordError] = useState('');
   const [confirmNewPasswordValueError, setConfirmNewPasswordError] =
      useState('');

   const changePassword = (row) => {
      setShowInput(true);
   };

   const validatePassword = useCallback((val, type) => {
      if (val.length === 0) {
         setPasswordError('');
         // setPasswordSet(false);
         return;
      }

      if (!/^[a-zA-Z0-9]+$/.test(val)) {
         type === 'old'
            ? setPasswordError('Contains invalid character(s)!')
            : type === 'new'
            ? setNewPasswordError('Contains invalid character(s)!')
            : setConfirmNewPasswordError('Contains invalid character(s)!');
         // setPasswordSet(false);
         return;
      }

      if (!/[A-Z]/.test(val)) {
         type === 'old'
            ? setPasswordError('Should contain a capital letter!')
            : type === 'new'
            ? setNewPasswordError('Should contain a capital letter!')
            : setConfirmNewPasswordError('Should contain a capital letter!');
         // setPasswordError('Should contain a capital letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[a-z]/.test(val)) {
         type === 'old'
            ? setPasswordError('Should contain a small letter!')
            : type === 'new'
            ? setNewPasswordError('Should contain a small letter!')
            : setConfirmNewPasswordError('Should contain a small letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[0-9]/.test(val)) {
         type === 'old'
            ? setPasswordError('Should contain a number!')
            : type === 'new'
            ? setNewPasswordError('Should contain a number!')
            : setConfirmNewPasswordError('Should contain a number!');
         // setPasswordSet(false);
         return;
      }

      if (val.length < 8) {
         type === 'old'
            ? setPasswordError('Minimum of 8 characters required!')
            : type === 'new'
            ? setNewPasswordError('Minimum of 8 characters required!')
            : setConfirmNewPasswordError('Minimum of 8 characters required!');
         // setPasswordSet(false);
         return;
      }

      setPasswordError('');
      setNewPasswordError('');
      setConfirmNewPasswordError('');
      // setPasswordSet(true);
   }, []);

   const handleOldPasswordChange = useCallback((itemValue) => {
      validatePassword(itemValue, 'old');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleNewPasswordChange = useCallback((itemValue) => {
      validatePassword(itemValue, 'new');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleConfirmNewPasswordChange = useCallback((itemValue) => {
      validatePassword(itemValue, 'confirm');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSubmit = (data) => {
      data['id'] = user?.user?._id;

      if (data.confirm_new_password === data.new_password) {
         delete data['confirm_new_password'];
         setPassError('');
         dispatch(userActions.changePassword(data));
      } else {
         setPassError('Passwords do not match');
         dispatch(appActions.clearMessages(data));
      }
   };

   const errorMessage = auth?.errorMessage;
   const oldPasswordError = errorMessage?.errors?.filter(
      (error) => error.param === 'old_password',
   );
   const passwordError = errorMessage?.errors?.filter(
      (error) => error.param === 'new_password',
   );

   const [oldPassword, setOldPassword] = useState(false);
   const [newPassword, setNewPassword] = useState(false);
   const [confirmPassword, setConfirmPassword] = useState(false);

   return (
      <MDBRow className="settings-page m-0 w-100 mb-5">
         <div className="role-permission w-100">
            <div className="super-admin mb-4">
               <h4 className="h4">Update Password</h4>
               {showInput ? (
                  <Formsy
                     onValidSubmit={handleSubmit}
                     onValid={() => setCanSubmit(true)}
                     onInvalid={() => setCanSubmit(false)}
                     className="__form px-md-4 px-3 py-4 d-flex flex-column">
                     {/* <p className="error mb-3">{errorMessage?.message}</p> */}
                     <div className="d-flex align-items-center">
                        <TextInput
                           name="old_password"
                           wrapperClassName="input-wrapper"
                           mainWrapperClassName="w-100 mb-3 mb-s-4"
                           type={oldPassword ? 'text' : 'password'}
                           placeholder="********"
                           useFocusedError
                           labelText="Old Password"
                           labelClassName="label"
                           className="input111 border-0 w-100"
                           validationError="Please provide your password!"
                           required
                           handleChange={handleOldPasswordChange}
                           // clearError={clearError}
                        />
                        <i
                           onClick={() => setOldPassword(!oldPassword)}
                           className="ml-n4 mt-2 pass-icon">
                           {oldPassword ? (
                              <Visibility style={{width: '1rem'}} />
                           ) : (
                              <VisibilityOff style={{width: '1rem'}} />
                           )}
                        </i>
                     </div>
                     <p style={{color: 'red'}}>{oldPasswordValueError}</p>
                     <p style={{color: 'red', fontSize: '11px'}}>
                        {oldPasswordError &&
                           oldPasswordError[0] &&
                           oldPasswordError[0].msg}
                     </p>
                     <div className="d-flex align-items-center">
                        <TextInput
                           name="new_password"
                           wrapperClassName="input-wrapper"
                           mainWrapperClassName="w-100 mb-3 mb-s-4"
                           type={newPassword ? 'text' : 'password'}
                           placeholder="********"
                           useFocusedError
                           labelText="New Password"
                           labelClassName="label"
                           className="input111 border-0 w-100"
                           validationError="Please provide your password!"
                           required
                           handleChange={handleNewPasswordChange}
                           // clearError={clearError}
                        />
                        <i
                           onClick={() => setNewPassword(!newPassword)}
                           className="ml-n4 mt-2 pass-icon">
                           {newPassword ? (
                              <Visibility style={{width: '1rem'}} />
                           ) : (
                              <VisibilityOff style={{width: '1rem'}} />
                           )}
                        </i>
                     </div>
                     <p style={{color: 'red'}}>
                        {newPasswordValueError || passError}
                     </p>
                     <div className="d-flex align-items-center">
                        <TextInput
                           name="confirm_new_password"
                           wrapperClassName="input-wrapper"
                           mainWrapperClassName="w-100 mb-3 mb-s-4"
                           type={confirmPassword ? 'text' : 'password'}
                           placeholder="********"
                           useFocusedError
                           labelText="Confirm New Password"
                           labelClassName="label"
                           className="input111 border-0 w-100"
                           validationError="Please provide your password!"
                           required
                           handleChange={handleConfirmNewPasswordChange}
                           // clearError={clearError}
                        />
                        <i
                           onClick={() => setConfirmPassword(!confirmPassword)}
                           className="ml-n4 mt-2 pass-icon">
                           {confirmPassword ? (
                              <Visibility style={{width: '1rem'}} />
                           ) : (
                              <VisibilityOff style={{width: '1rem'}} />
                           )}
                        </i>
                     </div>
                     <p style={{color: 'red'}}>
                        {confirmNewPasswordValueError || passError}
                     </p>
                     <p style={{color: 'red', fontSize: '11px'}}>
                        {passwordError &&
                           passwordError[0] &&
                           passwordError[0].msg}
                     </p>
                     <button disabled={!canSubmit} className="w-100">
                        {auth.IsSettingPassword ? (
                           <div class="submit-btn checking w-100" role="status">
                              <i class="fa fa-spinner fa-spin"></i> Changing...
                           </div>
                        ) : (
                           <div className="submit-btn checking w-100">
                              Change Password
                           </div>
                        )}
                        {/* <div className="submit-btn checking w-100">
                              Change Password
                           </div> */}
                     </button>
                  </Formsy>
               ) : (
                  <p
                     className="p px-md-4 px-2 pb-4 d-flex flex-column w-50"
                     style={{
                        color: '#3377ef',
                        cursor: 'pointer',
                     }}
                     onClick={changePassword}>
                     Change Password
                  </p>
               )}
            </div>
         </div>
      </MDBRow>
   );
}
