import React, {useEffect, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import Formsy from 'formsy-react';
import queryString from 'query-string';
import './index.scss';

import TextInput from '../../_components/TextInput';
import SharedBackground from '../../_components/SharedBackground';
import Verification from '../Verification';
// import {userConstants} from '../../_constants';
import {appActions, userActions} from '../../_actions';
// import BlueYellow from '../../_assets/images/blueandyellow.svg';
import {ReactComponent as Error} from '../../_assets/icons/error.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function NewUser({match, location, history}) {
   let params = queryString.parse(location.search);
   const {token} = match.params;
   const dispatch = useDispatch();
   const adminUserAcceptedInvitationSuccessfully = useSelector(
      (s) => s.user.adminUserAcceptedInvitationSuccessfully,
   );
   const IsCreatingAdminUser = useSelector((s) => s.user.IsCreatingAdminUser);
   const adminUserCreated = useSelector((s) => s.user.adminUserCreated);

   const auth = useSelector((s) => s.auth);
   const error = useSelector((s) => s.user.errorMessage);

   const [oldPassword, setOldPassword] = useState(false);
   const [passError, setPasswordError] = useState('');

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [canSubmit, setCanSubmit] = useState(false);

   const validatePassword = useCallback((val) => {
      if (val.length === 0) {
         setPasswordError('');
         // setPasswordSet(false);
         return;
      }

      if (!/^[a-zA-Z0-9]+$/.test(val)) {
         setPasswordError('Contains invalid character(s)!');
         // setPasswordSet(false);
         return;
      }

      if (!/[A-Z]/.test(val)) {
         setPasswordError('Should contain a capital letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[a-z]/.test(val)) {
         setPasswordError('Should contain a small letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[0-9]/.test(val)) {
         setPasswordError('Should contain a number!');
         // setPasswordSet(false);
         return;
      }

      if (val.length < 8) {
         setPasswordError('Minimum of 8 characters required!');
         // setPasswordSet(false);
         return;
      }

      setPasswordError('');
      // setPasswordSet(true);
   }, []);

   const handlePasswordChange = useCallback((itemValue) => {
      validatePassword(itemValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (params?.new_user === 'false') {
         const data = token;
         dispatch(userActions.acceptInvitation(data));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [params?.new_user === 'false']);

   useEffect(() => {
      if (adminUserAcceptedInvitationSuccessfully) {
         history.push('/login');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [adminUserAcceptedInvitationSuccessfully]);

   const handleSubmit = (data) => {
      // setCodeError('');
      // // setEmailError('');
      // setFirstNameError('');
      // setLastNameError('');
      // setPasswordError('');
      data['token'] = token;
      // merchantConstants.MERCHANT_ROLE_ID;
      dispatch(userActions.createAdminUser(data));
   };
   const errorMessage = auth?.errorMessage;
   const passwordError = errorMessage?.errors?.filter(
      (error) => error.param === 'password',
   );

   if (error) {
      return (
         <SharedBackground>
            <MDBRow className="h-100 w-100 m-0 log-page">
               {/* <MDBContainer className="align-items-center"> */}
               <div className="right d-flex align-items-center justify-content-center h-100 w-100">
                  <div className="__form py-4">
                     <Error className="img-fluid tick" />
                     <br />
                     <br />
                     <br />
                     <br />
                     <h3>{error?.message}</h3>
                     <Link to="/login">
                        <button
                           // disabled={!canSubmit}
                           className="d-block my-3 py-3">
                           Proceed to Login
                        </button>
                        {/* <button type="submit" className="signupbtn">
                        
                     </button> */}
                     </Link>
                  </div>
               </div>
               {/* </MDBContainer> */}
            </MDBRow>
         </SharedBackground>
      );
   }

   if (params?.new_user === 'true') {
      return (
         <SharedBackground>
            {!adminUserCreated && (
               <MDBRow className="h-100 w-100 m-0 log-page">
                  <div className="right d-flex align-items-center justify-content-center h-100 w-100">
                     <Formsy
                        onValidSubmit={handleSubmit}
                        onValid={() => setCanSubmit(true)}
                        onInvalid={() => setCanSubmit(false)}
                        className="__form px-md-4 px-3 py-4 d-flex flex-column">
                        <h3 className="mx-auto">Sign Up </h3>
                        <p style={{color: 'red'}}>{errorMessage?.message}</p>
                        <TextInput
                           wrapperClassName="input-wrapper"
                           mainWrapperClassName="w-100 mb-2 mb-s-4"
                           useFocusedError
                           name="firstname"
                           type="text"
                           labelClassName="label"
                           labelText="First Name"
                           className="input11 border-0 w-100"
                           placeholder="Kobe"
                           required
                        />
                        <TextInput
                           wrapperClassName="input-wrapper"
                           mainWrapperClassName="w-100 mb-2 mb-s-4"
                           useFocusedError
                           name="lastname"
                           type="text"
                           labelClassName="label"
                           labelText="Last Name"
                           className="input11 border-0 w-100"
                           placeholder="Brown"
                           required
                        />
                        <div className="d-flex align-items-center">
                           <TextInput
                              name="password"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              type={oldPassword ? 'text' : 'password'}
                              placeholder="********"
                              useFocusedError
                              labelText="Password"
                              labelClassName="label"
                              className="input pl-4 border-0 w-100"
                              validationError="Please provide your password!"
                              required
                              // clearError={clearError}
                              handleChange={handlePasswordChange}
                           />
                           <i
                              onClick={() => setOldPassword(!oldPassword)}
                              className="ml-n4 mt-3 pass-icon">
                              {oldPassword ? (
                                 <Visibility style={{width: '1rem'}} />
                              ) : (
                                 <VisibilityOff style={{width: '1rem'}} />
                              )}
                           </i>
                        </div>
                        <p style={{color: 'red'}}>{passError}</p>
                        <p style={{color: 'red'}}>
                           {passwordError &&
                              passwordError[0] &&
                              passwordError[0].msg}
                        </p>
                        <div className="d-flex align-items-center">
                           <TextInput
                              name="password"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              type={oldPassword ? 'text' : 'password'}
                              placeholder="********"
                              useFocusedError
                              labelText="Confirm Password"
                              labelClassName="label"
                              className="input pl-4 border-0 w-100"
                              validationError="Please provide your password!"
                              required
                              // clearError={clearError}
                              handleChange={handlePasswordChange}
                           />
                           <i
                              onClick={() => setOldPassword(!oldPassword)}
                              className="ml-n4 mt-3 pass-icon">
                              {oldPassword ? (
                                 <Visibility style={{width: '1rem'}} />
                              ) : (
                                 <VisibilityOff style={{width: '1rem'}} />
                              )}
                           </i>
                        </div>
                        {/* <Link to="/forgot-password" className="__link mb-3">
                           Forgot password?
                        </Link> */}
                        <button
                           disabled={!canSubmit}
                           className="d-block my-3 py-3">
                           {IsCreatingAdminUser ? (
                              <div
                                 class="spinner-border text-white"
                                 role="status">
                                 <span class="sr-only">Loading...</span>
                              </div>
                           ) : (
                              'Sign Up'
                           )}
                        </button>
                     </Formsy>
                  </div>
               </MDBRow>
            )}
            {adminUserCreated && <Verification />}
         </SharedBackground>
      );
   } else {
      return null;
   }
}
