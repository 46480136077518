import {call, put, takeLatest, all} from 'redux-saga/effects';
import {transactionConstants, userConstants} from '../../_constants';
import {
   createRequestWithToken,
   checkStatus,
   getObjectFromStorage,
} from '../../_helpers';

function* getAllTransactions({data}) {
   yield put({type: transactionConstants.REQUEST_ALL_TRANSACTIONS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      // ?type=transaction
      let allTransactionsUri = `${transactionConstants.ALL_TRANSACTIONS_URI}`;

      if (data?.page) {
         allTransactionsUri = `${allTransactionsUri}?page=${data.page}`;
      }
      // const req = createRequestWithToken(allTransactionsUri, { method: 'PATCH', body: JSON.stringify(data) })((user?.token ) || (userPassword && userPassword.token));

      const req = createRequestWithToken(allTransactionsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      if (response.status === 204) {
         yield put({
            type: transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS,
         allTransactions: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: transactionConstants.REQUEST_ALL_TRANSACTIONS_ERROR,
      });
   }
}

function* searchTransaction({data}) {
   yield put({type: transactionConstants.REQUEST_SEARCH_TRANSACTION});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      // /${data.searchName} ?type=${data.type}
      let searchTransactionUri = `${transactionConstants.ALL_TRANSACTIONS_URI}/search`;

      const req = createRequestWithToken(searchTransactionUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: transactionConstants.SEARCH_TRANSACTION_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.SEARCH_TRANSACTION_SUCCESS,
         transaction: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: transactionConstants.SEARCH_TRANSACTION_ERROR,
         error: errorMessage,
      });
   }
}

function* getAllTransactionsSagaWatcher() {
   yield takeLatest(
      transactionConstants.GET_ALL_TRANSACTIONS,
      getAllTransactions,
   );
}

function* searchTransactionWatcher() {
   yield takeLatest(transactionConstants.SEARCH_TRANSACTION, searchTransaction);
}

export default function* rootSaga() {
   yield all([getAllTransactionsSagaWatcher(), searchTransactionWatcher()]);
}
