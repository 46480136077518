import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import classNames from 'classnames';
import './index.scss';
import Granted from './granted';
import Request from './request';
import Summary from './summary';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';

// import img from '../../_assets/icons/1user-hash.svg';
import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';
import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';

// import TablePaginationActions from '../../_components/TablePaginationAction';
// import Loader from '../../_helpers/loader';
import {loanActions} from '../../_actions';
import {CSVLink} from 'react-csv';

export default function Loan() {
   const dispatch = useDispatch();
   const allLoanRequestList = useSelector((s) => s.loan.allLoanRequestList);
   const allLoanGrantedList = useSelector((s) => s.loan.allLoanGrantedList);

   const [shown, setShown] = useState(1);

   const [isOpen, setIsOpen] = useState(false);
   // const toggleList = () => {
   //    setIsOpen((wasOpen) => !wasOpen);
   // };

   const [select, setSelect] = useState(false);
   const [isStatusOpen, setIsStatusOpen] = useState(false);
   const toggleStatusList = () => {
      setIsStatusOpen((wasOpen) => !wasOpen);
      setSelect((wasOpen) => !wasOpen);
   };
   const [searchInput, setSearchInput] = useState('');
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
   };
   const searchLoan = () => {
      // const model = {
      //    searchName: searchInput,
      //    type: shown === 1 ? 'requested' : 'granted',
      // };
      const model = {
         query: searchInput,
         type: shown === 1 ? 'requested' : 'granted',
         limit: 10,
         sort: {
            firstname: 1,
         },
      };
      dispatch(loanActions.searchLoan(model));
   };

   const clearText = () => {
      // setIsDateOpen(true);
      setSearchInput('');
   };

   useEffect(() => {
      if (searchInput === '') {
         dispatch(
            shown === 1
               ? loanActions.getAllLoanRequest()
               : loanActions.getAllLoanGranted(),
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchInput]);

   const [selected, setSelected] = useState(0);
   const options = [
      // {label: 'Verification', val: 0},
      {label: 'Applied', val: 'applied'},
      {label: 'Processing', val: 'processing'},
   ];

   const [modalIsOpen, setModalIsOpen] = useState(false);

   const [secModalIsOpen, setSecModalIsOpen] = useState(false);

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
      },
   };

   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);
   // };

   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      setModalIsOpen(false);
   };

   return (
      <MDBRow className="loan-page m-0 w-100 mb-5">
         <div className="history d-flex">
            <ul className="d-flex">
               <li>
                  <div
                     onClick={() => setShown(1)}
                     className={classNames('w-100 d-block', {
                        active: shown === 1,
                     })}>
                     Loan Request
                  </div>
               </li>
               <li>
                  <div
                     onClick={() => setShown(2)}
                     className={classNames('w-100 d-block', {
                        active: shown === 2,
                     })}>
                     Loan Granted
                  </div>
               </li>
               <li>
                  <div
                     onClick={() => setShown(3)}
                     className={classNames('w-100 d-block', {
                        active: shown === 3,
                     })}>
                     Loan Summary
                  </div>
               </li>
            </ul>
         </div>
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search ">
               <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon
                     className="icons i-x"
                     style={{width: '1rem'}}
                     onClick={clearText}
                  />
                  <span className="i-search" onClick={searchLoan}>
                     <SearchIcon style={{width: '1rem'}} />
                  </span>
                  {isOpen && (
                     <div className="collaps">
                        <div
                           onClick={toggleStatusList}
                           className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                           <span className="d-block">
                              {options[selected].label}
                           </span>
                           <ArrDown className="ml-4" />
                           {isStatusOpen && (
                              <div className="status-list position-absolute">
                                 <ul className="">
                                    {options
                                       .filter((i) => i.val !== 0)
                                       .map((i, n) => (
                                          <li
                                             key={n}
                                             onClick={(e) => {
                                                // e.stopPropagation();
                                                // setIsOpen(false);
                                                setSelected(i.val);
                                             }}>
                                             <Link className="w-100 d-block">
                                                {i.label}
                                             </Link>
                                          </li>
                                       ))}
                                 </ul>
                              </div>
                           )}
                        </div>
                     </div>
                  )}
               </div>
            </div>
            <div className="filter d-flex">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               <CSVLink
                  data={shown === 1 ? allLoanRequestList : allLoanGrantedList}
                  className="csv">
                  Download CSV
               </CSVLink>
               {/* <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div> */}
               {/* {isOpen && (
                  <div className="collaps">
                     <div
                        onClick={toggleStatusList}
                        className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrDown className="ml-4" />
                        {isStatusOpen && (
                           <div className="status-list position-absolute">
                              <ul className="">
                                 {options
                                    .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             // e.stopPropagation();
                                             // setIsOpen(false);
                                             setSelected(i.val);
                                          }}>
                                          <Link className="w-100 d-block">
                                             {i.label}
                                          </Link>
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
               )} */}
            </div>
         </div>

         {shown === 1 && <Request />}

         {shown === 2 && <Granted />}

         {shown === 3 && <Summary />}

         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={() => setModalIsOpen(false)}>
            <Formsy className="__form d-flex flex-column">
               <h4 className="text-left mb-3">Grant Loan</h4>
               <p className="text-left loan mb-4">
                  Loans are transferred to customer's account
               </p>
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="name"
                  type="text"
                  labelClassName="label"
                  labelText="Customer Name"
                  className="input1 border-0 w-100"
                  placeholder="Lade jones"
                  disabled
               />
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="limit"
                  type="text"
                  labelClassName="label"
                  labelText="Limit"
                  className="input2 border-0 w-100"
                  placeholder="20,000"
               />
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="name"
                  type="text"
                  labelClassName="label"
                  labelText="Amount"
                  className="input2 border-0 w-100"
                  placeholder="20,000"
               />
               <div className="input-wrapper">
                  <label className="label d-flex">Due Date</label>
                  <input type="text" placeholder="20,000" className="input2" />
               </div>
               <button className="w-100">
                  <div
                     onClick={setSecModalIsOpenToTrue}
                     className="submit-btn mt-3 w-100">
                     Submit
                  </div>
               </button>
            </Formsy>
         </Modal>

         <Modal
            isOpen={secModalIsOpen}
            style={customStyles}
            onRequestClose={() => setSecModalIsOpen(false)}>
            <div className="content d-flex flex-column">
               <AcctCredited className="credited" style={{width: '6rem'}} />
               <p className="py-2">Customer's account has been credited</p>
               <button className="content-btn">
                  <Link to="/customer/loan">Home</Link>
               </button>
            </div>
         </Modal>
      </MDBRow>
   );
}
