import React, {useEffect, useState} from 'react';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'react-modal';
import './index.scss';

import LetteredAvatar from 'lettered-avatar';
// import TextInput from '../../_components/TextInput';
import AdminUserForm from './form';
import UpdateAdminUserForm from './editForm';
import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
// import {capitalizeFirstLetter} from '../../_helpers';
// import img from '../../_assets/icons/1user-hash.svg';
// import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
import {ReactComponent as Delete} from '../../_assets/icons/DeleteIcon.svg';
// import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';
import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';
// import {ReactComponent as Remove} from '../../_assets/icons/1remove.svg';
import {userActions} from '../../_actions';
import {capitalizeFirstLetter} from '../../_helpers';
import {userConstants} from '../../_constants';

export default function Admin() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(userActions.getAdminUsers());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const [modalIsOpen, setModalIsOpen] = useState(false);
   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);
   // };

   // const [secModalIsOpen, setSecModalIsOpen] = useState(false);

   // const [open, setOpen] = useState(false);
   // const [adminId, setAdminId] = useState('');
   const [update, setUpdate] = useState(false);
   const [deleteUser, setDeleteUser] = useState(false);
   const [userData, setUserData] = useState({});

   // const toggleOpen = (id) => {
   //    setAdminId(id);
   //    setOpen((open) => !open);
   // };

   const toggleUpdate = (row) => {
      // dispatch(appActions.clearMessages());
      setUpdate((update) => !update);
      // console.log('UPDATE', row);
      setUserData(row);
   };

   // const toggleDelete = (row) => {
   //    console.log('UPDATE', row);
   //    setUserData(row);
   //    setDeleteUser((deleteUser) => !deleteUser);
   // };

   const handleDelete = () => {
      const _id = userData?._id;
      if (_id) {
         dispatch(userActions.deleteAdminUser(_id));
      }
      setDeleteUser(false);
   };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   // const [isOpen, setIsOpen] = useState(false);
   // const toggleList = () => {
   //    setIsOpen((wasOpen) => !wasOpen);
   // };

   // const [selected, setSelected] = useState(0);

   // const options = [
   //    {label: 'Customer Relation', val: 0},
   //    {label: 'Admin', val: 1},
   //    {label: 'Super Admin', val: 2},
   // ];

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(userActions.getAdminUsers(model));
   };

   const user = useSelector((s) => s.user);
   const adminUsersList = useSelector((s) => s.user.adminUsersList);
   const userDetails = useSelector((s) => s.user.user);
   const IsRequestingAdminUsers = useSelector(
      (s) => s.user.IsRequestingAdminUsers,
   );
   const {totalDocs, limit, page, totalPages} = user?.pagination;
   const total = totalDocs;
   const pages = page - 1;
   const userDetail = userDetails.user;
   return (
      <MDBRow className="settings-page m-0 w-100 mb-5">
         <div className="user-management w-100">
            {userDetail?.role.includes(userConstants.ADMIN_ROLE_ID) && (
               <AdminUserForm type="add" />
            )}
            <div className="customer-list mt-3 w-100">
               <MDBTable
                  className="profile mb-5"
                  responsive
                  borderless
                  hover
                  align="middle">
                  <MDBTableHead>
                     <tr>
                        <th scope="col">User Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                     </tr>
                  </MDBTableHead>
                  {user.IsRequestingAdminUsers ? (
                     <div className="loader d-flex justify-content-center w-100">
                        <Loader className="ball-clip-rotate d-flex justify-content-center pt5 text-center">
                           <div />
                        </Loader>
                     </div>
                  ) : (
                     <MDBTableBody>
                        {adminUsersList?.length > 0 ? (
                           adminUsersList.map((row) => {
                              return (
                                 <div className="d-table-row" key={row._id}>
                                    <th scope="row">
                                       <LetteredAvatar
                                          name={
                                             row.firstname + ' ' + row.lastname
                                          }
                                          options={{
                                             size: 50,
                                             twoLetter: true,
                                             //shape: 'square',
                                             bgColor: '',
                                             imgClass:
                                                'image-responsive user-image',
                                             imgWidth: '3rem',
                                             imgHeight: '3rem',
                                          }}
                                       />
                                       {/* <img
                                          src={img}
                                          alt="profile-img"
                                          className="rounded-circle img-fluid"
                                          style={{
                                             width: '3rem',
                                             height: '3rem',
                                          }}
                                       /> */}
                                       <span>
                                          {row.firstname + ' ' + row.lastname}
                                       </span>
                                    </th>
                                    <td>{row.user_id?.email || row.email}</td>
                                    <td>
                                       {capitalizeFirstLetter(row?.role?.name)}
                                    </td>
                                    <td>
                                       {row.user_id?.isActive || row.isActive
                                          ? 'Active'
                                          : 'Not Active'}
                                    </td>
                                    <td>
                                       <Link
                                          onClick={() => toggleUpdate(row)}
                                          className="more w-100 d-block text-center">
                                          <Edit
                                             className="mr-2"
                                             style={{
                                                width: '.8rem',
                                             }}
                                          />
                                          Edit
                                       </Link>
                                       {/* <Link
                                          onClick={() => toggleOpen(row._id)}
                                          className="more position-relative">
                                          More{' '}
                                          <ArrDown
                                             className="ml-4"
                                             style={{width: '.55rem'}}
                                          />
                                          {open && adminId === row._id && (
                                             <div className="dropdownmenu position-absolute">
                                                <li>
                                                   <Link
                                                      onClick={() =>
                                                         toggleUpdate(row)
                                                      }
                                                      className="w-100 d-block text-center">
                                                      <Edit
                                                         className="mr-2"
                                                         style={{
                                                            width: '.8rem',
                                                         }}
                                                      />
                                                      Edit
                                                   </Link>
                                                </li>
                                                <li>
                                                   <Link
                                                      className="w-100 d-block text-center"
                                                      onClick={() =>
                                                         toggleDelete(row)
                                                      }>
                                                      <Remove
                                                         className="mr-2"
                                                         style={{
                                                            width: '.55rem',
                                                         }}
                                                      />
                                                      Remove
                                                   </Link>
                                                </li>
                                             </div>
                                          )}
                                       </Link> */}
                                    </td>
                                 </div>
                              );
                           })
                        ) : (
                           <th scope="row">
                              <td align="center" colSpan={5}>
                                 No result to display
                              </td>
                           </th>
                        )}
                     </MDBTableBody>
                  )}
               </MDBTable>
               {deleteUser && (
                  <Modal
                     isOpen={deleteUser}
                     style={customStyles}
                     onRequestClose={() => setDeleteUser(false)}>
                     <div className="content role w-100 d-flex align-items-center flex-column">
                        <Delete className="credited" style={{width: '6rem'}} />
                        <h3 className="m-0 role d-flex justify-content-center py-2">
                           Delete User
                        </h3>
                        <p className="text-center role">
                           Are you sure you want to remove user from <br /> this
                           application?
                        </p>
                        <div className="action-btn w-100 d-flex align-items-center justify-content-between">
                           <button
                              className="role-btn d-flex align-items-center justify-content-center cancel"
                              onClick={() => setDeleteUser(false)}>
                              Cancel
                           </button>
                           <button
                              className="role-btn d-flex align-items-center justify-content-center delete"
                              onClick={() => handleDelete()}>
                              Delete
                           </button>
                        </div>
                     </div>
                  </Modal>
               )}
            </div>
         </div>
         {!IsRequestingAdminUsers && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
         {/* <TablePaginationActions
            rowsPerPageOptions={[]}
            colSpan={2}
            count={total}
            rowsPerPage={limit}
            page={pages}
            totalPages={totalPages}
            onChangePage={handleChangePage}
         /> */}
         {update && (
            <UpdateAdminUserForm userData={userData} openModal={update} />
         )}

         {/* <div className="add-new"> */}
         {/* <Modal
               isOpen={modalIsOpen}
               style={customStyles}
               onRequestClose={() => setModalIsOpen(false)}>
               <Formsy className="__form d-flex flex-column">
                  <h4 className="text-left mb-3">Invite New User</h4>
                  <p className="text-left loan mb-4">
                     Loans are transferred to customer's account
                  </p>
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-2 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="User Name"
                     className="input11 border-0 w-100"
                     placeholder="Finance Accountant"
                     validations="isEmail"
                     validationError="This is not a valid email!"
                     required
                  />
                  <div className="form-group w-100">
                     <label className="label mb-0">Role</label>
                     <div
                        onClick={toggleList}
                        className="input222 d-flex position-relative align-items-center justify-content-between ">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrowDown style={{width: '1rem'}} />
                        {isOpen && (
                           <div
                              onRequestClose={() => setIsOpen(false)}
                              className="list position-absolute">
                              <ul className="list-items">
                                 {options
                                    // .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             setIsOpen(false);
                                             setSelected(i.val);
                                          }}>
                                          {i.label}
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-2 mb-s-4"
                     useFocusedError
                     name="email"
                     type="email"
                     labelClassName="label"
                     labelText="Password"
                     className="input11 border-0 w-100"
                     placeholder="finance123"
                     validations="isEmail"
                     validationError="The email is invalid!"
                     required
                  />
                  <button className="w-100">
                     <div
                        onClick={setSecModalIsOpenToTrue}
                        className="submit-btn w-100">
                        Submit
                     </div>
                  </button>
               </Formsy>
            </Modal>

            <Modal
               isOpen={secModalIsOpen}
               style={customStyles}
               onRequestClose={() => setSecModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <h3 className="m-0 d-flex justify-content-center py-2">
                     Invite Sent
                  </h3>
                  <p>Invite has been sent to the mail</p>
               </div>
            </Modal> */}
         {/* <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         </div> */}
      </MDBRow>
   );
}
