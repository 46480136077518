import React from 'react';
import {useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import './index.scss';
import './view.scss';

// import img from '../../_assets/icons/1custprofile.svg';
import img2 from '../../_assets/icons/1user-hash.svg';

// import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {
   numberWithCommas,
   readableDateString,
   // capitalizeFirstLetter,
} from '../../_helpers';
// import {merchantActions} from '../../_actions';

export default function SettlementHistory({match}) {
   // const dispatch = useDispatch();
   const IsRequestingMerchantSettlementHistory = useSelector(
      (s) => s.merchant.IsRequestingMerchantSettlementHistory,
   );
   const merchantSettlementList = useSelector(
      (s) => s.merchant.merchantSettlementList,
   );
   // const pagination = useSelector((s) => s.merchant.transactionPagination);
   // const {totalDocs, limit, page, totalPages} = pagination;
   // const total = totalDocs;
   // const pages = page - 1;

   // const handleChangePage = (event, pages) => {
   //    const {uuid} = match.params;

   //    const model = {
   //       page: pages,
   //       uuid: uuid,
   //    };

   //    dispatch(merchantActions.getMerchantTransactionHistory(model));
   // };

   return (
      <>
         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Company Name</th>
                     {/* <th scope="col">Product</th> */}
                     <th scope="col">Amount</th>
                     <th scope="col">Settlement Date</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingMerchantSettlementHistory ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {merchantSettlementList?.length > 0 ? (
                        merchantSettlementList.map((row) => {
                           return (
                              <div className="d-table-row">
                                 <th scope="row">
                                    <img
                                       src={img2}
                                       alt="profile-img"
                                       className="rounded-circle img-fluid"
                                       style={{width: '3rem', height: '3rem'}}
                                    />
                                    <span>{row.merchant_name || 'Oando'}</span>
                                 </th>
                                 {/* <td>{capitalizeFirstLetter(row.transaction_type)}</td> */}
                                 <td>{numberWithCommas(row.amount)}</td>
                                 <td>{readableDateString(row.created_at)}</td>
                              </div>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           This merchant has not performed any transaction on
                           this platform
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {/* {!IsRequestingMerchantSettlementHistory && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )} */}
      </>
   );
}
