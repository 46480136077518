import {appConstants} from './app.constant';

const loan = 'customers/loan';
const loanSummary = 'loan';

export const loanConstants = {
   REQUEST_SEARCH_LOAN: 'REQUEST_SEARCH_LOAN',
   SEARCH_LOAN_REQUEST_SUCCESS: 'SEARCH_LOAN_SUCCESS',
   SEARCH_LOAN_GRANTED_SUCCESS: 'SEARCH_LOAN_GRANTED_SUCCESS',
   SEARCH_LOAN_ERROR: 'SEARCH_LOAN_ERROR',
   SEARCH_LOAN_SUCCESS_WITHOUT_DATA: 'SEARCH_LOAN_SUCCESS_WITHOUT_DATA',

   REQUEST_ALL_LOAN_REQUESTS: 'REQUEST_ALL_LOAN_REQUESTS',
   REQUEST_ALL_LOAN_REQUESTS_SUCCESS: 'REQUEST_ALL_LOAN_REQUESTS_SUCCESS',
   REQUEST_ALL_LOAN_REQUESTS_ERROR: 'REQUEST_ALL_LOAN_REQUESTS_ERROR',

   REQUEST_ALL_LOAN: 'REQUEST_ALL_LOAN',
   REQUEST_ALL_LOAN_SUCCESS: 'REQUEST_ALL_LOAN_SUCCESS',
   REQUEST_ALL_LOAN_ERROR: 'REQUEST_ALL_LOAN_ERROR',

   REQUEST_LOAN_DETAILS: 'REQUEST_LOAN_DETAILS',
   REQUEST_LOAN_DETAILS_ERROR: 'REQUEST_LOAN_DETAILS_ERROR',
   REQUEST_LOAN_DETAILS_SUCCESS: 'REQUEST_LOAN_DETAILS_SUCCESS',

   REQUEST_VERIFY_LOAN: 'REQUEST_VERIFY_LOAN',
   REQUEST_VERIFY_LOAN_ERROR: 'REQUEST_VERIFY_LOAN_ERROR',
   REQUEST_VERIFY_LOAN_SUCCESS: 'REQUEST_VERIFY_LOAN_SUCCESS',

   REQUEST_GRANT_LOAN: 'REQUEST_GRANT_LOAN',
   REQUEST_GRANT_LOAN_ERROR: 'REQUEST_GRANT_LOAN_ERROR',
   REQUEST_GRANT_LOAN_SUCCESS: 'REQUEST_GRANT_LOAN_SUCCESS',

   REQUEST_SETTLE_LOAN: 'REQUEST_SETTLE_LOAN',
   REQUEST_SETTLE_LOAN_ERROR: 'REQUEST_SETTLE_LOAN_ERROR',
   REQUEST_SETTLE_LOAN_SUCCESS: 'REQUEST_SETTLE_LOAN_SUCCESS',

   REQUEST_DECLINE_LOAN: 'REQUEST_DECLINE_LOAN',
   REQUEST_DECLINE_LOAN_ERROR: 'REQUEST_DECLINE_LOAN_ERROR',
   REQUEST_DECLINE_LOAN_SUCCESS: 'REQUEST_DECLINE_LOAN_SUCCESS',

   REQUEST_ALL_LOAN_GRANTED: 'REQUEST_ALL_LOAN_GRANTED',
   REQUEST_ALL_LOAN_GRANTED_SUCCESS: 'REQUEST_ALL_LOAN_GRANTED_SUCCESS',
   REQUEST_ALL_LOAN_GRANTED_ERROR: 'REQUEST_ALL_LOAN_GRANTED_ERROR',

   GET_ALL_LOAN: 'GET_ALL_LOAN',
   GET_ALL_LOAN_REQUEST: 'GET_ALL_LOAN_REQUEST',
   GET_LOAN_DETAILS: 'GET_LOAN_DETAILS',

   GRANT_LOAN: 'GRANT_LOAN',
   SETTLE_LOAN: 'SETTLE_LOAN',
   SEARCH_LOAN: 'SEARCH_LOAN',

   GET_ALL_LOAN_GRANTED: 'GET_ALL_LOAN_GRANTED',
   VERIFY_LOAN: 'VERIFY_LOAN',
   DECLINE_LOAN: 'DECLINE_LOAN',

   LOAN_URI: `${appConstants.BASE_URI}${loan}/`,
   LOAN_SUMMARY_URI: `${appConstants.BASE_URI}${loanSummary}`,
};
