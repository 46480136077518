import React, {useEffect} from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import LetteredAvatar from 'lettered-avatar';
// import Modal from 'react-modal';
import './index.scss';

// import Formsy from 'formsy-react';
// import TextInput from '../../_components/TextInput';
// import AdminUserForm from './form';
import TablePaginationActions from '../../_components/TablePaginationAction';
import {
   // numberWithCommas,
   readableDateString,
   capitalizeFirstLetter,
} from '../../_helpers';
import Loader from '../../_helpers/loader';

// import img from '../../_assets/icons/1user-hash.svg';
// import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
// import {ReactComponent as ArrowDown} from '../../_assets/icons/1arrow-down.svg';
// import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';
// import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';
// import {ReactComponent as Remove} from '../../_assets/icons/1remove.svg';
import {loanActions} from '../../_actions';
// import Loan from '.';

export default function Requested() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(loanActions.getAllLoanRequest());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(loanActions.getAllLoanRequest(model));
   };

   const loan = useSelector((s) => s.loan);
   const allLoanRequestList = useSelector((s) => s.loan.allLoanRequestList);
   const {totalDocs, limit, page, totalPages} = loan?.pagination;
   const total = totalDocs;
   const pages = page - 1;
   // console.log('allLoanRequestList', allLoanRequestList);

   return (
      <div className="customer-list mt-3 w-100">
         <MDBTable
            className="profile mb-5"
            responsive
            borderless
            hover
            align="middle">
            <MDBTableHead>
               <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Status</th>
                  <th scope="col">Request Date</th>
                  <th scope="col">Verification</th>
                  {/* <th scope="col">Approve</th> */}
               </tr>
            </MDBTableHead>
            {loan.IsRequestingAllLoanRequest || loan.IsSearchingLoan ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTableBody>
                  {allLoanRequestList?.length > 0 ? (
                     allLoanRequestList.map((row) => {
                        return (
                           <Link
                              to={`/loan-customer/${row.loan_details._id}`}
                              className="d-table-row align-items-center">
                              {/* <div className="d-table-row" key={row._id}> */}
                              <th scope="row">
                                 <LetteredAvatar
                                    name={row.firstname + ' ' + row.lastname}
                                    options={{
                                       size: 50,
                                       twoLetter: true,
                                       //shape: 'square',
                                       bgColor: '',
                                       imgClass: 'image-responsive user-image',
                                       imgWidth: '3rem',
                                       imgHeight: '3rem',
                                    }}
                                 />
                                 <span>
                                    {row.firstname + ' ' + row.lastname}
                                 </span>
                              </th>
                              <td>{row.user_details?.email}</td>
                              <td>
                                 {row.user_details?.phone_number?.replace(
                                    /^.{3}/g,
                                    '0',
                                 )}
                              </td>
                              <td>
                                 {capitalizeFirstLetter(
                                    row.loan_details.loan_status,
                                 )}
                              </td>
                              <td>{readableDateString(row.created_at)}</td>
                              <td>
                                 {row.loan_details.is_verified
                                    ? 'Verified'
                                    : 'Not Verified'}
                              </td>
                              {/* </div> */}
                           </Link>
                        );
                     })
                  ) : (
                     <th scope="row">
                        <td align="center" colSpan={5}>
                           No result to display
                        </td>
                     </th>
                  )}
               </MDBTableBody>
            )}
         </MDBTable>
         {!loan.IsRequestingAllLoanRequest && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </div>
   );
}
