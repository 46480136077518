import {appConstants} from './app.constant';

const settlements = 'account';

export const settlementConstants = {
   GET_SUCCESSFUL_SETTLEMENTS: 'GET_SETTLEMENT',
   GET_FAILED_SETTLEMENTS: 'GET_SETTLEMENTS',
   SEARCH_SETTLEMENT: 'SEARCH_SETTLEMENT',
   VERIFY_BVN: 'VERIFY_BVN',

   SETTLEMENTS: 'settlements',

   REQUEST_VERIFY_BVN: 'REQUEST_VERIFY_BVN',
   REQUEST_VERIFY_BVN_ERROR: 'REQUEST_VERIFY_BVN_ERROR',
   REQUEST_VERIFY_BVN_SUCCESS: 'REQUEST_VERIFY_BVN_SUCCESS',

   REQUEST_SUCCESSFUL_SETTLEMENTS: 'REQUEST_SUCCESSFUL_SETTLEMENTS',
   REQUEST_SUCCESSFUL_SETTLEMENTS_ERROR: 'REQUEST_SUCCESSFUL_SETTLEMENTS_ERROR',
   REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS:
      'REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS',
   REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS_WITHOUT_DATA:
      'REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS_WITHOUT_DATA',

   REQUEST_FAILED_SETTLEMENTS: 'REQUEST_FAILED_SETTLEMENTS',
   REQUEST_FAILED_SETTLEMENTS_ERROR: 'REQUEST_FAILED_SETTLEMENTS_ERROR',
   REQUEST_FAILED_SETTLEMENTS_SUCCESS: 'REQUEST_FAILED_SETTLEMENTS_SUCCESS',

   REQUEST_SEARCH_SETTLEMENT: 'REQUEST_SEARCH_SETTLEMENT',
   SEARCH_SETTLEMENT_SUCCESS: 'SEARCH_SETTLEMENT_SUCCESS',
   SEARCH_SETTLEMENT_ERROR: 'SEARCH_SETTLEMENT_ERROR',
   SEARCH_SETTLEMENT_SUCCESS_WITHOUT_DATA:
      'SEARCH_SETTLEMENT_SUCCESS_WITHOUT_DATA',

   SETTLEMENT_KEY: 'settlement',
   ALL_SETTLEMENTS_URI: `${appConstants.BASE_URI}${settlements}`,
};
