import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import LetteredAvatar from 'lettered-avatar';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {
   numberWithCommas,
   readableDateString,
   capitalizeFirstLetter,
} from '../../_helpers';
import {transactionActions} from '../../_actions';
import {CSVLink} from 'react-csv';

export default function Transactions() {
   const dispatch = useDispatch();

   useEffect(() => {
      const model = {
         type: 'transaction',
      };
      dispatch(transactionActions.getAllTransactions(model));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const IsRequestingAllTransactions = useSelector(
      (s) => s.transaction.IsRequestingAllTransactions,
   );
   const IsSearchingTransaction = useSelector(
      (s) => s.transaction.IsSearchingTransaction,
   );
   const allTransactionsList = useSelector(
      (s) => s.transaction.allTransactionsList,
   );
   const {totalDocs, limit, page, totalPages} = useSelector(
      (s) => s.transaction.pagination,
   );
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages + 1,
      };
      const filterModel = {
         from: startDate,
         to: endDate,
         page: pages + 1,
      };
      dispatch(
         transactionActions.getAllTransactions(
            toDate === false ? model : filterModel,
         ),
      );
   };

   const [searchInput, setSearchInput] = useState('');
   const [toDate, setToDate] = useState(false);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());

   // console.log('toDate', toDate);
   // console.log('startDate', startDate);

   const searchTransaction = () => {
      const model = {
         query: searchInput,
         // type: 'transaction',
      };
      dispatch(transactionActions.searchTransaction(model));
   };

   // const handleFilter = () => {
   //    const model = {
   //       from: startDate,
   //       to: endDate,
   //    };
   //    dispatch(transactionActions.getAllTransactions(model));
   // };

   useEffect(() => {
      if (!toDate) {
         const model = {
            type: 'transaction',
         };
         dispatch(transactionActions.getAllTransactions(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [toDate]);

   const clearText = () => {
      // setIsDateOpen(true);
      setSearchInput('');
   };

   useEffect(() => {
      if (searchInput === '') {
         const model = {
            type: 'transaction',
         };
         dispatch(transactionActions.getAllTransactions(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchInput]);
   useEffect(() => {
      if (toDate) {
         // console.log('startDate?.split(T)[0]', endDate)?.split('T')[0] + 'T00:00:00.000Z' ?.split('T')[0] + 'T00:00:00.000Z';
         const model = {
            from: startDate,
            to: endDate,
            type: 'transaction',
         };
         dispatch(transactionActions.getAllTransactions(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [endDate]);

   const [isOpen, setIsOpen] = useState(false);
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
      setToDate(false);
   };

   const [select, setSelect] = useState(false);
   const [isStatusOpen, setIsStatusOpen] = useState(false);

   const toggleStatusList = () => {
      setIsStatusOpen((wasOpen) => !wasOpen);
      setSelect((wasOpen) => !wasOpen);
   };

   // const [isDateOpen, setIsDateOpen] = useState(false);
   // const toggleDate = () => {
   //    // setIsDateOpen(true);
   //    setIsDateOpen((wasOpen) => !wasOpen);
   // };

   const [selected, setSelected] = useState(0);
   const options = [
      {label: 'Status', val: 0},
      {label: 'Ascending Order', val: 1},
      {label: 'Descending Order', val: 2},
   ];

   const filterStatus = (val) => {
      setSelected(val);
      const model = {
         filter: 'amount',
         limit: 10,
         sort: {
            amount: val === 1 ? 1 : -1,
         },
      };
      console.log('model', model);
      dispatch(transactionActions.getAllTransactions(model));
   };

   return (
      <MDBRow className="transaction-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search filter">
               {isOpen ? (
                  <div className="collaps">
                     <div
                        // onClick={toggleDate}
                        className="collaps-item mr-5 position-relation">
                        From Date Range:
                        {/* <ArrDown className="ml-4" /> */}
                        <DatePicker
                           selected={startDate}
                           onSelect={() => setToDate(true)}
                           onChange={(date) => setStartDate(date)}
                        />
                     </div>
                     {toDate && (
                        <div
                           // onClick={toggleDate}
                           className="collaps-item mr-5 position-relation">
                           To Date Range:
                           <DatePicker
                              selected={endDate}
                              // onSelect={handleFilter}
                              onChange={(date) => setEndDate(date)}
                           />
                        </div>
                     )}
                  </div>
               ) : (
                  <div className="input-group d-flex align-items-center">
                     <input
                        type="text"
                        className="for"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                     />
                     <XIcon
                        className="icons i-x"
                        style={{width: '1rem'}}
                        onClick={clearText}
                     />
                     <span className="i-search" onClick={searchTransaction}>
                        <SearchIcon style={{width: '1rem'}} />
                     </span>
                  </div>
               )}

               {isOpen && (
                  <div className="collaps mt-5">
                     <div
                        onClick={toggleStatusList}
                        className="collaps-item my-2 d-flex position-relative align-items-center justify-content-between dropdwn">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrDown className="ml-4" />
                        {isStatusOpen && (
                           <div className="status-list position-absolute">
                              <ul className="">
                                 {options
                                    .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             // e.stopPropagation();
                                             // setIsOpen(false);
                                             // setSelected(i.val);
                                             filterStatus(i.val);
                                          }}>
                                          <Link className="w-100 d-block">
                                             {i.label}
                                          </Link>
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
               )}
            </div>
            <div className="filter d-flex">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               <CSVLink data={allTransactionsList} className="csv">
                  Download CSV
               </CSVLink>
               <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div>
            </div>
         </div>
         {/* {select && (
            <MDBInput
               className="mr-2"
               style={{width: '.65rem', height: '.65rem'}}
               type="checkbox"
               id="checkbox"
            />
         )} */}

         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Sender's Name</th>
                     <th scope="col">Merchant's Name</th>
                     <th scope="col">Sub User's Name</th>
                     {/* <th scope="col">Transaction Type</th> */}
                     <th scope="col">Fund Type</th>
                     <th scope="col">Status</th>
                     <th scope="col">Narration</th>
                     <th scope="col">Amount</th>
                     <th scope="col">Date</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingAllTransactions || IsSearchingTransaction ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {allTransactionsList?.length > 0 ? (
                        allTransactionsList.map((row) => {
                           return (
                              <div className="d-table-row">
                                 <th scope="row d-flex align-items-center justify-content-center">
                                    {/* {select && (
                                       <MDBInput
                                          className="mr-2"
                                          style={{
                                             width: '.65rem',
                                             height: '.65rem',
                                          }}
                                          type="checkbox"
                                          id="checkbox"
                                       />
                                    )} */}
                                    <LetteredAvatar
                                       name={row.sender_name}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass:
                                             'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>
                                       {capitalizeFirstLetter(row.sender_name)}
                                    </span>
                                 </th>
                                 <td>
                                    {capitalizeFirstLetter(row.receiver_name)}
                                 </td>
                                 <td>{row?.cashier?.username}</td>
                                 {/* <td>
                                    {capitalizeFirstLetter(
                                       row.transaction_type,
                                    )}
                                 </td> */}
                                 <td>{capitalizeFirstLetter(row.fund_type)}</td>
                                 <td>{capitalizeFirstLetter(row.status)}</td>
                                 <td>
                                    {capitalizeFirstLetter(
                                       row?.narration || 'None',
                                    )}
                                 </td>
                                 <td>{numberWithCommas(row.amount)}</td>
                                 <td>{readableDateString(row.created_at)}</td>
                              </div>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           No transaction has been made on this platform today
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {!IsRequestingAllTransactions && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </MDBRow>
   );
}
