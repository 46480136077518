import React, {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {MDBBadge, MDBNavbarBrand} from 'mdbreact';
import {useLocationCode} from '../../_navigation/useLocationCode';
import {matchPath, useLocation} from 'react-router-dom';
import useOnClickOutside from '../../_components/OnClickOutside';
import './index.scss';

// import {ReactComponent as Bell} from '../../_assets/icons/Ellipse.svg';
// import {ReactComponent as Notification} from '../../_assets/icons/Notification.svg';
import {ReactComponent as Logout} from '../../_assets/icons/Logout.svg';
import {ReactComponent as Settings} from '../../_assets/icons/1settings.svg';
import img from '../../_assets/icons/1user-white.svg';

export default function Header({history, logoutAdmin}) {
   const code = useLocationCode();
   const {pathname} = useLocation();
   const totalDocs = useSelector((s) => s.auth.totalDocs);
   // const customerDetails = useSelector((s) => s.customer.customerDetails);
   // const walletCustomersList = useSelector(
   //    (s) => s.customer.walletCustomersList,
   // );

   const pageName = useCallback(() => {
      switch (code) {
         case 1:
            return 'Dashboard';
         case 2:
            const viewLoanCustomer = matchPath(
               pathname,
               '/customer/loan/:uuid',
            );
            const viewWalletCustomer = matchPath(
               pathname,
               '/customer/wallet/:uuid',
            );
            const viewRegisteredCustomer = matchPath(
               pathname,
               '/customer/registered/:uuid',
            );
            const loanCustomer = matchPath(pathname, '/customer/loan/');
            const walletCustomer = matchPath(pathname, '/customer/wallet/');
            const registeredCustomer = matchPath(
               pathname,
               '/customer/registered/',
            );
            if (loanCustomer?.isExact || viewLoanCustomer?.isExact)
               return 'Customer / Loan';
            if (walletCustomer?.isExact || viewWalletCustomer?.isExact)
               return 'Customer / Wallet';
            if (registeredCustomer?.isExact || viewRegisteredCustomer?.isExact)
               return 'Customer / Registered';
            return `Customer / Loan`;
         case 3:
            const viewRegularMerchant = matchPath(
               pathname,
               '/merchant/regular/:uuid',
            );
            const viewPremiumMerchant = matchPath(
               pathname,
               '/merchant/premium/:uuid',
            );
            const regularMerchant = matchPath(pathname, '/merchant/regular');
            const premiumMerchant = matchPath(pathname, '/merchant/premium');
            if (regularMerchant?.isExact || viewRegularMerchant?.isExact)
               return `Merchant / Regular`;
            if (premiumMerchant?.isExact || viewPremiumMerchant?.isExact)
               return 'Merchant / Premium';
            return 'Merchant / Regular';
         case 4:
            return 'Loan';
         case 5:
            return 'Settlement';
         // case 6:
         //    return 'Admin';
         case 6:
            return 'Transactions';
         case 7:
            const viewBranches = matchPath(pathname, '/teams');
            const viewBranchMarketers = matchPath(pathname, '/teams/:uuid');
            const viewMarketerCustomers = matchPath(
               pathname,
               '/teams/marketers/:uuid/customers',
            );
            if (viewBranches?.isExact) return 'Teams';
            if (viewBranchMarketers?.isExact) return 'Team Marketers';
            if (viewMarketerCustomers?.isExact) return 'Marketer Customers';
            // if (
            //    viewBranches?.isExact ||
            //    viewBranchMarketers?.isExact ||
            //    viewMarketerCustomers?.isExact
            // )
            //    return 'Teams';
            // return 'Teams';
         case 8:
            return 'Settings';
         default:
            return null;
      }
   }, [code, pathname])();

   const num = useCallback(() => {
      switch (code) {
         case 2:
            return 'Customer';
         case 3:
            return 'Merchant';
         case 4:
            return 'Loan';
         case 5:
            return 'Settlement';
         case 6:
            return 'Transactions';
         case 7:
            return 'Teams';
         case 8:
            return 'Settings';
         default:
            return null;
      }
   }, [code])();

   const [open, setOpen] = useState(false);
   const toggleOpen = () => {
      setOpen((open) => !open);
   };
   const logout = () => {
      setOpen((open) => !open);
      //history.push('/');
      logoutAdmin();
   };
   const goToSettings = () => {
      setOpen((open) => !open);
      history.push('/settings');
   };
   // const [openProfile, setOpenProfile] = useState(false);
   const userDropdownRef = useRef();
   useOnClickOutside(userDropdownRef, () => {
      if (open) setOpen(false);
   });

   return code === 9 ? null : (
      <div className="header col-12">
         <div className="row justify-content-between dsh-brand">
            <div className="dsh-name d-flex align-items-center">
               <MDBNavbarBrand href="#" className="dsh-brand-name">
                  {pageName}
                  {num && (
                     <MDBBadge className="ml-2 badge">
                        {totalDocs?.totalDocs}
                     </MDBBadge>
                  )}
                  <span className="sr-only">no of customers</span>
                  {/* <span>
                     {' '}
                     /{' '}
                     {customerDetails?.user?.firstname +
                        ' ' +
                        customerDetails?.user?.lastname}
                  </span> */}
               </MDBNavbarBrand>
            </div>
            <div className="profile-img position-relative d-flex align-items-center">
               <div className="notification d-flex align-items-center justify-content-center">
                  {/* <Link href="#!">
                     <Bell className="ellipse" style={{width: '.4rem'}} />
                     <Notification style={{width: '1rem'}} />
                  </Link> */}
               </div>
               <div onClick={toggleOpen} className="profile">
                  <img
                     src={img}
                     alt="profile-img"
                     className="ml-2 rounded-circle img-fluid"
                     style={{width: '3rem'}}
                  />
               </div>
               {open && (
                  <div
                     ref={userDropdownRef}
                     className="profile-settings position-absolute">
                     <ul>
                        <li className="d-flex" onClick={goToSettings}>
                           <Settings
                              width=".9rem"
                              height=".9rem"
                              className="mr-2"
                           />{' '}
                           Settings
                        </li>
                        <li className="d-flex" onClick={logout}>
                           <Logout
                              width=".9rem"
                              height=".9rem"
                              className="mr-2"
                           />{' '}
                           Logout
                        </li>
                     </ul>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
}
