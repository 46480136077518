import {appConstants} from '../_constants';

const setSnackBar = (data) => ({
   type: appConstants.SET_SNACKBAR,
   message: data.message,
   variant: data.variant,
});

const clearSnackBar = () => ({
   type: appConstants.CLEAR_SNACKBAR,
});

const clearMessages = () => ({
   type: appConstants.CLEAR_MESSAGES,
});

export const appActions = {
   setSnackBar,
   clearSnackBar,
   clearMessages,
};
