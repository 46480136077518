import {branchConstants} from '../_constants';

const getBranches = (model) => ({
   type: branchConstants.GET_ALL_BRANCHES,
   data: model,
});

const createBranch = (model) => ({
   type: branchConstants.CREATE_BRANCH,
   data: model,
});

const searchBranch = (model) => ({
   type: branchConstants.SEARCH_BRANCH,
   data: model,
});

const createMarketer = (model) => ({
   type: branchConstants.CREATE_MARKETER,
   data: model,
});

const getBranchMarketers = (model) => ({
   type: branchConstants.GET_BRANCH_MARKETERS,
   data: model,
});

const searchBranchMarketer = (model) => ({
   type: branchConstants.SEARCH_BRANCH_MARKETER,
   data: model,
});

const getMarketerCustomers = (model) => ({
   type: branchConstants.GET_MARKETER_CUSTOMERS,
   data: model,
});

const deleteBranch = (_id) => ({
   type: branchConstants.DELETE_BRANCH,
   _id,
});

const updateBranch = (model) => ({
   type: branchConstants.UPDATE_BRANCH,
   data: model,
});

export const branchActions = {
   getBranches,
   createBranch,
   searchBranch,
   createMarketer,
   getBranchMarketers,
   searchBranchMarketer,
   getMarketerCustomers,
   deleteBranch,
   updateBranch,
};
