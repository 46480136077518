import React, {useEffect, useState} from 'react';
import {MDBRow} from 'mdbreact';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'react-modal';
import './index.scss';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';

import {branchActions} from '../../_actions';
import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';

export default function BranchForm({
   type,
   branchData,
   modalIsOpen,
   setModalIsOpen,
}) {
   const dispatch = useDispatch();

   const branchCreatedSuccessfully = useSelector(
      (s) => s.branch.branchCreatedSuccessfully,
   );
   const branchUpdatedSuccessfully = useSelector(
      (s) => s.branch.branchUpdatedSuccessfully,
   );
   const IsCreatingBranch = useSelector((s) => s.branch.IsCreatingBranch);
   const IsUpdatingBranch = useSelector((s) => s.branch.IsUpdatingBranch);
   const errorMessage = useSelector((s) => s.branch.errorMessage);

   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);
   // };

   useEffect(() => {
      if (branchCreatedSuccessfully || branchUpdatedSuccessfully) {
         setModalIsOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [branchCreatedSuccessfully, branchUpdatedSuccessfully]);

   const [canSubmit, setCanSubmit] = useState(false);

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   const nameError = errorMessage?.errors.filter(
      (error) => error.param === 'name',
   );
   // console.log('errorMessage', errorMessage);
   // console.log('nameError', nameError);

   const locationError = errorMessage?.errors.filter(
      (error) => error.param === 'location',
   );

   const handleSubmit = (data) => {
      if (type === 'edit') {
         data['id'] = branchData._id;
      }
      type === 'add'
         ? dispatch(branchActions.createBranch(data))
         : dispatch(branchActions.updateBranch(data));
      // dispatch(branchActions.createBranch(data));
   };

   return (
      <MDBRow className=" m-0 w-100">
         <div className="user-management w-100">
            {type === 'add' ? (
               <div onClick={setModalIsOpen} className="button ml-auto">
                  Add Branch
               </div>
            ) : (
               <Link
                  onClick={setModalIsOpen}
                  className="w-100 d-block text-center">
                  <Edit
                     className="mr-2"
                     style={{
                        width: '.8rem',
                     }}
                  />
                  Edit
               </Link>
            )}
            <div className="add-new">
               <Modal
                  isOpen={modalIsOpen}
                  style={customStyles}
                  onRequestClose={() => setModalIsOpen(false)}>
                  <Formsy
                     onValidSubmit={handleSubmit}
                     onValid={() => setCanSubmit(true)}
                     onInvalid={() => setCanSubmit(false)}
                     className="__form d-flex flex-column">
                     <h4 className="text-left mb-3">
                        {type === 'add'
                           ? 'Create New Branch'
                           : "Update Branch's Details"}
                     </h4>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="name"
                        type="text"
                        labelClassName="label"
                        labelText="Branch Name"
                        className="input11 border-0 w-100"
                        placeholder="Gbagada"
                        required
                        value={branchData?.name}
                     />
                     <p style={{color: 'red'}}>{nameError?.[0]?.msg}</p>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="location"
                        type="text"
                        labelClassName="label"
                        labelText="Location"
                        className="input11 border-0 w-100"
                        placeholder="Gbagada"
                        required
                        value={branchData?.location}
                     />
                     <p class="text-red">{locationError?.[0]?.msg}</p>
                     <button
                        disabled={!canSubmit}
                        className="w-100 mt-3 mt-s-4">
                        {IsCreatingBranch || IsUpdatingBranch ? (
                           <div class="text-white" role="status">
                              <i class="fa fa-spinner fa-spin"></i>{' '}
                              {type === 'add' ? 'Adding' : 'Updating'}
                           </div>
                        ) : (
                           <div className="submit-btn w-100">
                              {' '}
                              {type === 'add' ? 'Add' : 'Update'}{' '}
                           </div>
                        )}
                     </button>
                  </Formsy>
               </Modal>
            </div>
         </div>
      </MDBRow>
   );
}
