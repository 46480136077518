import {
   appConstants,
   userConstants,
   forgotPasswordConstants,
} from '../../_constants';
import {combineReducers} from 'redux';
import {
   updateObject,
   updateItemInArray,
   // updateUserInArray,
} from '../../_helpers';

const IsRequestingUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.GET_REQUEST:
         return true;
      case userConstants.GET_FAILURE:
         return false;
      case userConstants.GET_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAdminUsers = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_ADMIN_USERS:
         return true;
      case userConstants.REQUEST_ADMIN_USERS_SUCCESS:
         return false;
      case userConstants.REQUEST_ADMIN_USERS_ERROR:
         return false;
      default:
         return state;
   }
};

const IsRegisteringAdminUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_REGISTER_ADMIN_USER:
         return true;
      case userConstants.REGISTER_ADMIN_USER_SUCCESS:
         return false;
      case userConstants.REGISTER_ADMIN_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const adminUserRegistered = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_REGISTER_ADMIN_USER:
         return false;
      case userConstants.REGISTER_ADMIN_USER_SUCCESS:
         return true;
      case userConstants.REGISTER_ADMIN_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsCreatingAdminUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_CREATE_ADMIN_USER:
         return true;
      case userConstants.CREATE_ADMIN_USER_SUCCESS:
         return false;
      case userConstants.CREATE_ADMIN_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const adminUserCreated = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_CREATE_ADMIN_USER:
         return false;
      case userConstants.CREATE_ADMIN_USER_SUCCESS:
         return true;
      case userConstants.CREATE_ADMIN_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingAdminUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_UPDATE_ADMIN_USER:
         return true;
      case userConstants.UPDATE_ADMIN_USER_SUCCESS:
         return false;
      case userConstants.UPDATE_ADMIN_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const adminUserUpdated = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_UPDATE_ADMIN_USER:
         return false;
      case userConstants.UPDATE_ADMIN_USER_SUCCESS:
         return true;
      case userConstants.UPDATE_ADMIN_USER_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsVerifyingUserBvn = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_USER_BVN:
         return true;
      case userConstants.VERIFY_USER_BVN_ERROR:
         return false;
      case userConstants.VERIFY_USER_BVN_SUCCESS:
         return false;
      case userConstants.VERIFY_CUSTOMER_USER_BVN_SUCCESS:
         return false;
      default:
         return state;
   }
};

const userBvnVerified = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_USER_BVN:
         return false;
      case userConstants.VERIFY_USER_BVN_ERROR:
         return false;
      case userConstants.VERIFY_USER_BVN_SUCCESS:
         return true;
      case userConstants.VERIFY_CUSTOMER_USER_BVN_SUCCESS:
         return true;
      default:
         return state;
   }
};

// const IsResettingPassword = (state = false, action) => {
//    switch (action.type) {
//       case userConstants.RESET_PASSWORD_REQUEST:
//          return true;
//       case userConstants.RESET_PASSWORD_SUCCESS:
//          return false;
//       case userConstants.RESET_PASSWORD_FAILURE:
//          return false;
//       default:
//          return state;
//    }
// };

// const passwordChangeSuccess = (state = false, action) => {
//    switch (action.type) {
//       case userConstants.RESET_PASSWORD_REQUEST:
//          return false;
//       case userConstants.RESET_PASSWORD_SUCCESS:
//          return true;
//       case userConstants.RESET_PASSWORD_ERROR:
//          return false;
//       case appConstants.CLEAR_MESSAGES:
//          return false;
//       default:
//          return state;
//    }
// };

const IsDeletingAdminUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_DELETE_ADMIN_USER:
         return true;
      case userConstants.DELETE_ADMIN_USER_SUCCESS:
         return false;
      case userConstants.DELETE_ADMIN_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsAuthenticated = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGOUT_SUCCESS:
         return false;
      case userConstants.LOGIN_SUCCESS:
         return true;
      default:
         return state;
   }
};

const user = (state = null, {type, user}) => {
   switch (type) {
      case userConstants.GET_SUCCESS:
         return updateObject(state, user);
      case userConstants.REGISTER_SUCCESS:
         return updateObject(state, user);
      case userConstants.LOGIN_SUCCESS:
         return updateObject(state, user);
      // case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
      //    return updateObject(state, user);
      case userConstants.LOGOUT_SUCCESS:
         return null;
      case userConstants.TOKEN_HAS_EXPIRED:
         return null;
      default:
         return state;
   }
};

// const newUserPassword = (state = null, {type, user}) => {
//    switch (type) {
//       case userConstants.RESET_PASSWORD_SUCCESS:
//          return updateObject(state, user);
//       case appConstants.CLEAR_MESSAGES:
//          return null;
//       default:
//          return state;
//    }
// };

// const updatedChild = (state = null, {type, user}) => {
//    switch (type) {
//       case userConstants.UPDATE_ADMIN_USER_SUCCESS:
//          return updateObject(state, user);
//       default:
//          return state;
//    }
// };

// const emptyState = {
//    firstname: '',
//    lastname: '',
//    email: '',
//    role: '',
// };

const exportedAdminUsers = (state = [], action) => {
   switch (action.type) {
      case userConstants.REQUEST_EXPORT_ALL_USERS_SUCCESS: {
         return action.userData.data;
      }
      default:
         return state;
   }
};

const adminUserAcceptedInvitationSuccessfully = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_ACCEPT_INVITATION:
         return false;
      case userConstants.ACCEPT_INVITATION_SUCCESS:
         return true;
      case userConstants.ACCEPT_INVITATION_ERROR:
         return false;
      default:
         return state;
   }
};

const adminUsersList = (state = [], action) => {
   switch (action.type) {
      case userConstants.REQUEST_ADMIN_USERS_SUCCESS:
         return action.adminUsers.data.docs;
      // case userConstants.REGISTER_ADMIN_USER_SUCCESS: {
      //    const newList = [...state, action.user.admin_user];

      //    return newList;
      // }
      // case userConstants.SEARCH_ADMIN_USER_SUCCESS:
      //    return action.adminUser.data.docs;
      // case userConstants.SEARCH_ADMIN_USER_SUCCESS_WITHOUT_DATA:
      //    return updateObject(state, emptyState);
      // case userConstants.UPDATE_ADMIN_USER_SUCCESS: {
      //    const newList = updateUserInArray(
      //       state,
      //       action.user.user._id,
      //       true,
      //       (adminUser) => {
      //          const user = {
      //             ...adminUser,
      //             user_id: action.user.user,
      //          };
      //          return updateObject(adminUser, user);
      //          // return updateObject(adminUser, action.user.user);

      //       },
      //    );
      //    return newList;
      // }
      case userConstants.UPDATE_ADMIN_USER_SUCCESS: {
         const newList = updateItemInArray(
            state,
            action.updatedUser.user._id,
            false,
            (adminUser) => {
               return updateObject(adminUser, action.updatedUser.user);
            },
         );

         return newList;
      }
      case userConstants.DELETE_ADMIN_USER_SUCCESS: {
         return state.filter((adminUser) => adminUser._id !== action._id);
      }
      default:
         return state;
   }
};

const IsSearchingAdminUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_SEARCH_ADMIN_USER:
         return true;
      case userConstants.SEARCH_ADMIN_USER_SUCCESS:
         return false;
      case userConstants.SEARCH_ADMIN_USER_ERROR:
         return false;
      case userConstants.SEARCH_ADMIN_USER_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case userConstants.REQUEST_ADMIN_USERS_SUCCESS: {
         const {totalDocs, limit, page, totalPages} = action.adminUsers.data;
         const result = {totalDocs, limit, page, totalPages};

         return updateObject(state, result);
      }
      case userConstants.SEARCH_ADMIN_USER_SUCCESS: {
         const {totalDocs, limit, page} = action.adminUser.data;
         const result = {totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case userConstants.UPDATE_ADMIN_USER_ERROR:
         return updateObject(state, error.errors);
      case userConstants.RESET_PASSWORD_FAILURE:
         return updateObject(state, error);
      case userConstants.REGISTER_ADMIN_USER_ERROR:
         return updateObject(state, error);
      case userConstants.REQUEST_REGISTER_ADMIN_USER:
         return null;
      case userConstants.ACCEPT_INVITATION_ERROR:
         return updateObject(state, error);
      // case userConstants.REQUEST_UPDATE_ADMIN_USER_ERROR:
      //    return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const createErrorMessage = (state = [], {type, error}) => {
   switch (type) {
      case userConstants.REGISTER_ADMIN_USER_ERROR:
         return updateObject(state, error);
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingUser,
   user,
   // newUserPassword,
   IsAuthenticated,
   adminUserAcceptedInvitationSuccessfully,
   exportedAdminUsers,
   // updatedChild,
   adminUsersList,
   IsSearchingAdminUser,
   IsRequestingAdminUsers,
   // IsResettingPassword,
   pagination,
   // passwordChangeSuccess,
   IsRegisteringAdminUser,
   adminUserRegistered,
   IsCreatingAdminUser,
   adminUserCreated,
   errorMessage,
   IsUpdatingAdminUser,
   IsDeletingAdminUser,
   adminUserUpdated,
   IsVerifyingUserBvn,
   userBvnVerified,
   createErrorMessage,
});

export default rootReducer;
