import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import './index.scss';
import './view.scss';
import classNames from 'classnames';
import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
import LetteredAvatar from 'lettered-avatar';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
// import Loader from '../../_helpers/loader';

// import {MDBRow} from 'mdbreact';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import {
   numberWithCommas,
   capitalizeFirstLetter,
   readableDateString,
} from '../../_helpers';
// import img from '../../_assets/icons/1custprofile.svg';
import {ReactComponent as Pdf} from '../../_assets/icons/1pdf-file.svg';
// import file from '../../_assets/icons/1pdf-file.svg';

import {ReactComponent as MasterCard} from '../../_assets/icons/master-card.svg';
import {ReactComponent as BadgeIcon} from '../../_assets/icons/verified.svg';
import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
// import customerImg from '../../_assets/images/profile-img.jpg';
import {branchActions, loanActions, userActions} from '../../_actions';
import {appConstants} from '../../_constants';
import {profileDetails} from './profileRoutes';

export default function LoanCustomerDetails({match}) {
   const dispatch = useDispatch();

   // const customerDetails = useSelector((s) => s.customer.customerDetails);
   const IsVerifyingLoan = useSelector((s) => s.loan.IsVerifyingLoan);
   const user = useSelector((s) => s.user.user);
   const loanDetails = useSelector((s) => s.loan.loanDetails);
   const branchesList = useSelector((s) => s.branch.branchesList);
   const branchMarketersList = useSelector((s) => s.branch.branchMarketersList);
   const {IsGrantingLoan, loanGranted, IsDecliningLoan, errorMessage} =
      useSelector((s) => s.loan);
   // const loanGranted = useSelector((s) => s.loan.loanGranted);
   // const IsDecliningLoan = useSelector((s) => s.loan.IsDecliningLoan);
   const IsVerifyingUserBvn = useSelector((s) => s.user.IsVerifyingUserBvn);
   const userBvnVerified = useSelector((s) => s.user.userBvnVerified);

   useEffect(() => {
      const {uuid} = match.params;
      dispatch(branchActions.getBranches());
      dispatch(loanActions.getLoanDetails(uuid));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const [shown, setShown] = useState(1);

   useEffect(() => {
      if (loanGranted) {
         setModalIsOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [loanGranted]);

   useEffect(() => {
      if (userBvnVerified === true) {
         setVerifyBvnModalIsOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userBvnVerified]);

   // const [tabshown, setTabshown] = useState(1);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   // const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [branch, setBranch] = useState('');
   const [marketer, setMarketer] = useState('');
   const [canSubmit, setCanSubmit] = useState(false);
   const [verifyBvnModalIsOpen, setVerifyBvnModalIsOpen] = useState(false);
   const [verifyError, setVerifyError] = useState('');

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         maxHeight: '90vh',
         height: 'fit-content',
         overflowY: 'scroll',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
      },
   };

   const handleChange = (e) => {
      setBranch(e.target.value);
      dispatch(branchActions.getBranchMarketers(e.target.value));
   };

   const setModalIsOpenToTrue = () => {
      setModalIsOpen(true);
   };

   // const setSecModalIsOpenToTrue = () => {
   //    setSecModalIsOpen(true);
   //    setModalIsOpen(false);
   // };
   const verifyLoan = () => {
      setVerifyError('');
      if (loanDetails?.user_details?.bvn_verified) {
         const {uuid} = match.params;
         dispatch(loanActions.verifyLoan(uuid));
      } else {
         setVerifyError('Please verify BVN first!');
      }
   };

   const declineLoan = () => {
      const {uuid} = match.params;
      dispatch(loanActions.declineLoan(uuid));
   };
   const handleSubmit = (data) => {
      const {uuid} = match.params;
      data['_id'] = uuid;
      data['user_id'] = loanDetails?.customer_detail?.user_id;
      data['marketer'] = marketer;
      dispatch(loanActions.grantLoan(data));
   };

   const handleDownload = (data) => {
      // const chunks = data.href.split('#');
      const token = user?.token;
      fetch(`${appConstants.BASE_URI}loan/download?key=${data}`, {
         method: 'GET',
         headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${token}`,
         },
      })
         .then((response) => response.blob())
         .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `FileName.pdf`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
         });
   };

   const verifyBVN = () => {
      const {user_details} = loanDetails;
      const fields = {
         _id: user_details?._id,
         // bvn: user_details.bvn,
         status: true,
      };
      dispatch(userActions.verifyUserBvn(fields));
   };

   const history = useHistory();
   const [list, setList] = useState(1);

   console.log('errorMessage', errorMessage);
   const interestError = errorMessage?.errors?.filter(
      (error) => error.param === 'interest',
   );
   const processingError = errorMessage?.errors?.filter(
      (error) => error.param === 'processing_fee',
   );
   const marketerError = errorMessage?.errors?.filter(
      (error) => error.param === 'marketer',
   );
   console.log('interestError', interestError);
   const requestDetails = profileDetails?.slice(0, -1);
   console.log('vv', requestDetails);
   return (
      <div className="customerss py-5 w-100 mb-5">
         <div className="tab d-flex align-items-center justify-content-between">
            <ul className="crumb pl-3 mb-2 d-flex align-items-center">
               <li
                  onClick={() => {
                     history.goBack();
                  }}
                  className="text-capitalize">
                  Customer
               </li>
               <li className="mx-3"></li>
               <li className="text-capitalize">
                  {loanDetails?.loan_details?.firstname +
                     ' ' +
                     loanDetails?.loan_details?.lastname}
               </li>
               {loanDetails?.loan_details?.is_verified && (
                  <li className="ml-2 text-capitalize">
                     <BadgeIcon className="verified" />
                  </li>
               )}
               {loanDetails?.loan_details?.loan_details && (
                  <div className="price">
                     #
                     {numberWithCommas(
                        loanDetails?.loan_details?.loan_details?.limit,
                     )}
                  </div>
                  // <div className="price">#330,000</div>
               )}
            </ul>
            {/* ('processing' || 'applied')  */}
            {!loanDetails?.user_details?.bvn_verified && (
               <div className="btns ml-4">
                  <button
                     onClick={
                        !loanDetails?.user_details?.bvn_verified
                           ? () =>
                                setVerifyBvnModalIsOpen(
                                   true,
                                   setVerifyError(''),
                                )
                           : null
                     }
                     className="text-capitalize verify ">
                     Verify BVN
                     {/* {IsDecliningLoan ? 'declining...' : ''} */}
                  </button>
               </div>
            )}
            <p className="text-center verify-error">{verifyError}</p>
            {loanDetails?.loan_details?.loan_status === 'applied' ? (
               <div className="btns mr-3">
                  <button
                     onClick={declineLoan}
                     className="text-capitalize decline mr-3">
                     {IsDecliningLoan ? 'declining...' : 'decline'}
                  </button>
                  <button
                     className="text-capitalize verify"
                     onClick={verifyLoan}
                     // onClick={
                     //    loanDetails?.loan_details?.is_verified
                     //       ? setModalIsOpenToTrue
                     //       : verifyLoan
                     // }
                  >
                     {IsVerifyingLoan ? (
                        <>
                           <i class="fa fa-spinner fa-spin"></i> Verifying
                        </>
                     ) : (
                        <>verify</>
                     )}
                  </button>
               </div>
            ) : loanDetails?.loan_details?.loan_status === 'processing' ? (
               <div className="btns mr-3">
                  <button
                     onClick={declineLoan}
                     className="text-capitalize decline mr-3">
                     {IsDecliningLoan ? 'declining...' : 'decline'}
                  </button>
                  <button
                     className="text-capitalize verify"
                     onClick={setModalIsOpenToTrue}>
                     {IsVerifyingLoan ? (
                        <>
                           <i class="fa fa-spinner fa-spin"></i> Verifying
                        </>
                     ) : (
                        <>Grant Loan</>
                     )}
                  </button>
               </div>
            ) : (
               <div className="btns mr-3">
                  <button className="text-capitalize verify">
                     loan Granted
                  </button>
               </div>
            )}
         </div>

         <div className="row m-0 detail">
            <div className="col-md-6 col-sm-12 p-3">
               <div className="inner px-5 py-4">
                  <div className="mb-3 d-flex align-items-center">
                     <div className="img mr-3">
                        <LetteredAvatar
                           name={
                              loanDetails?.loan_details?.firstname +
                              ' ' +
                              loanDetails?.loan_details?.lastname
                           }
                           options={{
                              size: 40,
                              twoLetter: true,
                              //shape: 'square',
                              bgColor: '',
                              imgClass: 'image-responsive user-image',
                              imgWidth: '3rem',
                              imgHeight: '3rem',
                           }}
                        />
                        {/* <img src={customerImg} alt="" className="h-100 w-100" /> */}
                     </div>
                     <div className="name-info">
                        <h6 className="text-capitalize">
                           {loanDetails?.loan_details?.firstname +
                              ' ' +
                              loanDetails?.loan_details?.lastname}
                        </h6>
                        <p className="">{loanDetails?.loan_details?.email}</p>
                     </div>
                  </div>
                  <ul className="lists py-2">
                     {(loanDetails?.loan_details?.loan_status === 'processing'
                        ? requestDetails
                        : profileDetails
                     ).map((props, i) => (
                        <li
                           key={i}
                           onClick={() => {
                              setList(props.activeCode);
                           }}
                           className={classNames(
                              'mb-3 px-2 py-1 d-flex align-items-center',
                              {
                                 active: list === props.activeCode,
                              },
                           )}>
                           <div className="img mr-2 d-flex align-items-center justify-content-center">
                              <div className="icon p-2 d-flex align-items-center justify-content-center">
                                 {props.icon}
                              </div>
                           </div>
                           <div className="name-info">
                              <h6 className="text-capitalize">
                                 {props.infoName}
                              </h6>
                              <p className="">{props.options}</p>
                           </div>
                        </li>
                     ))}
                  </ul>
               </div>
            </div>

            <div className="col-md-6 col-sm-12 p-3">
               <div className="p-4 content">
                  {list === 1 && (
                     <div className="basic-info">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="text-capitalize">Profile</h5>
                           <p className="pb-3 pt-2">
                              Name, Email, Phone Number, Date Joined
                           </p>
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="full_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Emeka Chuks"
                              labelText="Full Name"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.loan_details?.firstname +
                                 ' ' +
                                 loanDetails?.loan_details?.lastname
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="email"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="emekachuks@gmail.com"
                              labelText="Email Address"
                              labelClassName="label mb-1"
                              disabled
                              value={loanDetails?.loan_details?.email}
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="phone_no"
                              type="text"
                              className="input my-2 border-0 w-100"
                              // placeholder="08038291819"
                              labelText="Phone Number"
                              labelClassName="label mb-1"
                              value={loanDetails?.user_details?.phone_number?.replace(
                                 /^.{3}/g,
                                 '0',
                              )}
                              disabled
                           />
                           {/* <TextInput
                              mainWrapperClassName="py-1"
                              name="date"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="12th July, 2021"
                              labelText="Date Joined"
                              labelClassName="label mb-1"
                              disabled
                              // value={readableDateString(
                              //    loanDetails?.user_details?.created_at,
                              // )}
                           /> */}
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="res_address"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Emeka Chuks"
                              labelText="Residential Address"
                              labelClassName="label mb-1"
                              value={loanDetails?.customer_detail?.address}
                              disabled
                           />
                           {/* <button className="mt-4">
                              <p className="text-capitalize">update profile</p>
                           </button> */}
                        </Formsy>
                     </div>
                  )}
                  {/* {list === 2 && (
                     <div className="address">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="text-capitalize">address</h5>
                           <p className="pb-3 pt-2">
                              Residential Address, Work Address
                           </p>
                           
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="perm_address"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="emekachuks@gmail.com"
                              labelText="Permanent Address"
                              labelClassName="label mb-1"
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="work_address"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="08038291819"
                              labelText="Work Address"
                              labelClassName="label mb-1"
                           />
                           <button className="mt-4">
                              <p className="text-capitalize">update</p>
                           </button>
                        </Formsy>
                     </div>
                  )} */}
                  {list === 3 && (
                     <div className="kin">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="">Next of Kin Information</h5>
                           <p className="pb-3 pt-2">
                              Account Name, Bank, Relationship, Address
                           </p>
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="full_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Emeka Chuks"
                              labelText="Full Name"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.next_of_kin?.firstname +
                                 ' ' +
                                 loanDetails?.next_of_kin?.lastname
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="email"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="emekachuks@gmail.com"
                              labelText="Email Address"
                              labelClassName="label mb-1"
                              disabled
                              value={loanDetails?.next_of_kin?.email}
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="phone_no"
                              type="text"
                              className="input my-2 border-0 w-100"
                              // placeholder="08038291819"
                              labelText="Phone Number"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.next_of_kin?.phone_number ||
                                 'null'
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="spouse"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Wife"
                              labelText="Relationship"
                              labelClassName="label mb-1"
                              value={loanDetails?.next_of_kin?.relationship}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="gender"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Male"
                              labelText="gender"
                              labelClassName="label mb-1"
                              value={loanDetails?.next_of_kin?.gender}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="address"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="1, Lagos Estate"
                              labelText="Address"
                              labelClassName="label mb-1"
                              value={loanDetails?.next_of_kin?.house_address}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="state"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="1527293920"
                              labelText="State of Residence"
                              labelClassName="label mb-1"
                              value={loanDetails?.next_of_kin?.state?.name}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="city"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="1527293920"
                              labelText="City of Residence"
                              labelClassName="label mb-1"
                              value={loanDetails?.next_of_kin?.city?.name}
                              disabled
                           />
                           {/* <TextInput
                              mainWrapperClassName="py-1"
                              name="spouse_address"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="4, Lade Street, Gbagada"
                              labelText="Address"
                              labelClassName="label mb-1"
                              disabled
                           /> */}
                           {/* <button className="mt-4">
                              <p className="text-capitalize">update</p>
                           </button> */}
                        </Formsy>
                     </div>
                  )}
                  {list === 4 && (
                     <div className="work-info">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="">Work Information</h5>
                           <p className="pb-3 pt-2">
                              Occupation, Salary, Salary Date, Staff Number
                           </p>
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="company_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Company"
                              labelText="Company Name"
                              labelClassName="label mb-1"
                              value={loanDetails?.employment_info?.company_name}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="company_address"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Company"
                              labelText="Company Address"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.company_address
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="job_description"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Role"
                              labelText="Occupation"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.job_description
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="level"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Level 5"
                              labelText="Level"
                              labelClassName="label mb-1"
                              value={loanDetails?.employment_info?.level}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="salary"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="#140,000"
                              labelText="Salary"
                              labelClassName="label mb-1"
                              value={numberWithCommas(
                                 loanDetails?.employment_info?.monthly_salary,
                              )}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="salary_date"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="27th July, 2021"
                              labelText="Salary Date"
                              labelClassName="label mb-1"
                              value={`${loanDetails?.employment_info?.salary_date}th of every month`}
                              disabled
                           />
                           {/* <TextInput
                              mainWrapperClassName="py-1"
                              name="staff_no"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="78/292/2"
                              labelText="Staff Number"
                              labelClassName="label mb-1"
                           /> */}
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="bvn"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Access"
                              labelText="BVN"
                              labelClassName="label mb-1"
                              value={loanDetails?.employment_info?.bvn}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="salary_bank"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Access"
                              labelText="Salary Bank"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.staff_account
                                    ?.bank_name
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="account_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Kunle"
                              labelText="Salary Account Name"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.staff_account
                                    ?.account_name
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="account_number"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="199009900"
                              labelText="Salary Account Number"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.staff_account
                                    ?.account_number
                              }
                              disabled
                           />
                           {loanDetails?.employment_info?.documents?.length >
                              0 && <h5 className="">Bank Statements</h5>}
                           {loanDetails?.employment_info?.documents?.length >
                              0 &&
                              loanDetails?.employment_info?.documents.map(
                                 (row, index) => {
                                    return (
                                       <p>
                                          Statement {index + 1}:
                                          {/* <p
                                          
                                             className="btn"
                                            > */}
                                          <Pdf
                                             onClick={() => handleDownload(row)}
                                             style={{
                                                width: '1rem',
                                                marginLeft: '.5rem',
                                                cursor: 'pointer',
                                             }}
                                          />
                                          {/* </p> */}
                                       </p>
                                    );
                                 },
                              )}
                           {/* {loanDetails?.employment_info?.documents && (
                              <>
                                 <h5 className="">Bank Statements</h5>
                                 <p>
                                    Statement 1:
                                    <a download="">
                                       <Pdf
                                          style={{
                                             width: '1rem',
                                             marginLeft: '.5rem',
                                          }}
                                       />
                                    </a>
                                 </p>
                              </>
                           )} */}

                           {/* <button className="mt-4">
                              <p className="text-capitalize">update</p>
                           </button> */}
                        </Formsy>
                     </div>
                  )}
                  {list === 6 && (
                     <div className="work-info">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="">Loan Details</h5>
                           <p className="pb-3 pt-2">
                              Occupation, Salary, Salary Date, Staff Number
                           </p>
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="limit"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Limit"
                              labelText="Limit"
                              labelClassName="label mb-1"
                              value={numberWithCommas(
                                 loanDetails?.loan_details?.loan_details?.limit,
                              )}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="loan_type"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Company"
                              labelText="Loan Type"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.loan_details?.loan_details
                                    ?.loan_type
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="amountToBePaid"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Role"
                              labelText="Amount To Be Paid"
                              labelClassName="label mb-1"
                              value={numberWithCommas(
                                 loanDetails?.loan_details?.loan_details
                                    ?.amountToBePaid,
                              )}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="starting_balance"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Level 5"
                              labelText="Starting Balance"
                              labelClassName="label mb-1"
                              value={numberWithCommas(
                                 loanDetails?.loan_details?.loan_details
                                    ?.starting_balance,
                              )}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="interest"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="#140,000"
                              labelText="Interest(%)"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.loan_details?.loan_details
                                    ?.interest
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="date_granted"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="27th July, 2021"
                              labelText=" Date Granted"
                              labelClassName="label mb-1"
                              value={readableDateString(
                                 loanDetails?.loan_details?.loan_details
                                    ?.date_granted,
                              )}
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="payment_date"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Access"
                              labelText="Return Date"
                              labelClassName="label mb-1"
                              value={readableDateString(
                                 loanDetails?.loan_details?.loan_details
                                    ?.payment_date,
                              )}
                              disabled
                           />
                           {/* <TextInput
                              mainWrapperClassName="py-1"
                              name="salary_bank"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Access"
                              labelText="Salary Bank"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.staff_account
                                    ?.bank_name
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="account_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Kunle"
                              labelText="Salary Account Name"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.staff_account
                                    ?.account_name
                              }
                              disabled
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="account_number"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="199009900"
                              labelText="Salary Account Number"
                              labelClassName="label mb-1"
                              value={
                                 loanDetails?.employment_info?.staff_account
                                    ?.account_number
                              }
                              disabled
                           /> */}
                        </Formsy>
                     </div>
                  )}
                  {list === 5 && (
                     <div className="card-details">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="">Card Details</h5>
                           <p className="pb-3 pt-2">
                              Occupation, Salary, Salary Date, Staff Number
                           </p>
                           <TextInput
                              mainWrapperClassName="py-1 position-relative"
                              name="card_no"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="36389202820901"
                              labelText="Card Number"
                              labelClassName="label mb-1"
                              value={loanDetails?.employment_info?.level}
                              rightIcon={
                                 <div className="icon__master position-absolute d-flex align-items-center justify-content-center">
                                    <MasterCard height="1rem" width="1rem" />
                                 </div>
                              }
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="card_holder_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Emeka Chuks"
                              labelText="Card Holder Name"
                              labelClassName="label mb-1"
                           />
                           <div className="row m-0 d-flex align-items-center">
                              <div className="col-md-6 p-0 col-sm-12">
                                 <TextInput
                                    mainWrapperClassName="mr-2 py-1"
                                    name="expiry_date"
                                    type="text"
                                    className="input my-2 border-0 w-100"
                                    placeholder="08/25"
                                    labelText="Expiry Date"
                                    labelClassName="label mb-1"
                                 />
                              </div>
                              <div className="col-md-6 p-0 col-sm-12">
                                 <TextInput
                                    mainWrapperClassName="ml-2 py-1"
                                    name="cvv"
                                    type="text"
                                    className="input my-2 border-0 w-100"
                                    placeholder="575"
                                    labelText="CVV"
                                    labelClassName="label mb-1"
                                 />
                              </div>
                           </div>
                           {/* <button className="mt-4">
                              <p className="text-capitalize">update</p>
                           </button> */}
                        </Formsy>
                     </div>
                  )}
                  {/* {list === 6 && (
                     <div className="card-details">
                        <Formsy className="__form px-4 d-flex flex-column">
                           <h5 className="">Statements</h5>
                           <p className="pb-3 pt-2">
                              See statements activities
                           </p>
                           {/* <TextInput
                              mainWrapperClassName="py-1 position-relative"
                              name="card_no"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="36389202820901"
                              labelText="Card Number"
                              labelClassName="label mb-1"
                              rightIcon={
                                 <div className="icon__master position-absolute d-flex align-items-center justify-content-center">
                                    <MasterCard height="1rem" width="1rem" />
                                 </div>
                              }
                           />
                           <TextInput
                              mainWrapperClassName="py-1"
                              name="card_holder_name"
                              type="text"
                              className="input my-2 border-0 w-100"
                              placeholder="Emeka Chuks"
                              labelText="Card Holder Name"
                              labelClassName="label mb-1"
                           /> */}
                  {/* <button className="mt-5">
                              <p className="text-capitalize">update</p>
                           </button>
                        </Formsy>
                     </div>
                  )} */}
               </div>
            </div>
         </div>
         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={() => setModalIsOpen(false)}>
            <Formsy
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}
               className="__form d-flex flex-column">
               <h4 className="text-left mb-3">Grant Loan</h4>
               <p className="text-left loan mb-4">
                  Loans are transferred to customer's account
               </p>
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="name"
                  type="text"
                  labelClassName="label"
                  labelText="Customer Name"
                  className="input1 border-0 w-100"
                  placeholder="Lade jones"
                  value={
                     loanDetails?.loan_details?.firstname +
                     ' ' +
                     loanDetails?.loan_details?.lastname
                  }
                  disabled
               />
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-3 mb-s-4"
                  useFocusedError
                  name="limit"
                  type="text"
                  labelClassName="label"
                  labelText="Limit(#)"
                  className="input2 border-0 w-100"
                  placeholder="20,000"
               />
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-3 mb-s-4"
                  useFocusedError
                  name="serviceCharge"
                  type="text"
                  labelClassName="label"
                  labelText="Service Charge"
                  className="input2 border-0 w-100"
                  placeholder="2%"
               />
               {interestError?.length > 0 && (
                  <p style={{color: 'red'}}>
                     {capitalizeFirstLetter(interestError?.[0]?.msg)}
                  </p>
               )}
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-3 mb-s-4"
                  useFocusedError
                  name="processing_fee"
                  type="text"
                  labelClassName="label"
                  labelText="Processing Fee(%)"
                  className="input2 border-0 w-100"
                  placeholder="2%"
               />
               {processingError?.length > 0 && (
                  <p style={{color: 'red'}}>
                     {capitalizeFirstLetter(processingError?.[0]?.msg)}
                  </p>
               )}
               <FormControl
                  variant="outlined"
                  className="w-100 mb-3 mb-s-4"
                  // className={classes.formControl}
               >
                  <InputLabel id="demo-simple-select-outlined-label">
                     Branch
                  </InputLabel>
                  <Select
                     labelId="demo-simple-select-outlined-label"
                     id="demo-simple-select-outlined"
                     value={branch}
                     name="branch_id"
                     onChange={handleChange}
                     label="Role">
                     {branchesList.map((option) => (
                        <MenuItem value={option._id}>{option.name}</MenuItem>
                     ))}
                  </Select>
               </FormControl>
               {branchMarketersList?.length > 0 ? (
                  <FormControl
                     variant="outlined"
                     className="w-100 mb-3 mb-s-4"
                     // className={classes.formControl}
                  >
                     <InputLabel id="demo-simple-select-outlined-label">
                        Marketer
                     </InputLabel>
                     <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={marketer}
                        name="marketer_id"
                        onChange={(e) => setMarketer(e.target.value)}
                        label="Marketer">
                        {branchMarketersList.map((option) => (
                           <MenuItem value={option._id}>
                              {option.firstname + ' ' + option.lastname}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               ) : (
                  <td align="center" colSpan={5}>
                     No marketer under this branch
                  </td>
               )}
               {marketerError?.length > 0 && (
                  <p style={{color: 'red'}}>
                     Marketer {marketerError?.[0]?.msg}
                  </p>
               )}
               {/* <div className="input-wrapper">onClick={setSecModalIsOpenToTrue}
                  <label className="label d-flex">Due Date</label>
                  <input type="text" placeholder="20,000" className="input2" />
               </div> change */}
               <button type="submit">
                  {IsGrantingLoan ? (
                     <>
                        <i class="fa fa-spinner fa-spin"></i> Verifying
                     </>
                  ) : (
                     <div className="button">Submit</div>
                  )}
               </button>
            </Formsy>
         </Modal>
         {/* <Modal
            isOpen={secModalIsOpen}
            style={customStyles}
            onRequestClose={() => setSecModalIsOpen(false)}>
            <div className="content d-flex flex-column">
               <AcctCredited className="credited" style={{width: '6rem'}} />
               <p className="py-2">Customer's account has been credited</p>
               <button className="content-btn">
                  <Link to="/customer/loan">Home</Link>
               </button>
            </div>
         </Modal> */}
         <Modal
            isOpen={verifyBvnModalIsOpen}
            style={customStyles}
            onRequestClose={() => setVerifyBvnModalIsOpen(false)}>
            <div className="content d-flex flex-column">
               <AcctCredited className="credited" style={{width: '6rem'}} />
               <p className="py-2">Verify Customer's BVN</p>
               <p className="py-2">BVN: {loanDetails?.employment_info?.bvn}</p>
               {/* <p></p> */}
               <button onClick={verifyBVN}>
                  <Link>
                     {IsVerifyingUserBvn ? (
                        <div class="spinner-border text-white" role="status">
                           <span class="sr-only">Loading...</span>
                        </div>
                     ) : (
                        'Proceed'
                     )}
                  </Link>
               </button>
            </div>
         </Modal>
      </div>
   );
}
