import {appConstants} from './app.constant';

const transactions = 'account';
const transaction = 'transaction';
// get_transactions
export const transactionConstants = {
   GET_ALL_TRANSACTIONS: 'GET_ALL_TRANSACTIONS',
   SEARCH_TRANSACTION: 'SEARCH_TRANSACTION',

   TRANSACTIONS: 'transactions',

   REQUEST_ALL_TRANSACTIONS: 'REQUEST_ALL_TRANSACTIONS',
   REQUEST_ALL_TRANSACTIONS_ERROR: 'REQUEST_ALL_TRANSACTIONS_ERROR',
   REQUEST_ALL_TRANSACTIONS_SUCCESS: 'REQUEST_ALL_TRANSACTIONS_SUCCESS',

   REQUEST_SEARCH_TRANSACTION: 'REQUEST_SEARCH_TRANSACTION',
   SEARCH_TRANSACTION_SUCCESS: 'SEARCH_TRANSACTION_SUCCESS',
   SEARCH_TRANSACTION_ERROR: 'SEARCH_TRANSACTION_ERROR',
   SEARCH_TRANSACTION_SUCCESS_WITHOUT_DATA:
      'SEARCH_TRANSACTION_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA:
      'REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA',

   TRANSACTION_KEY: 'transaction',
   ALL_TRANSACTIONS_URI: `${appConstants.BASE_URI}${transactions}`,
   SEARCH_TRANSACTIONS_URI: `${appConstants.BASE_URI}${transaction}`,
};
