import {appConstants} from './app.constant';

const merchant = 'merchant';
const account = 'account';

export const merchantConstants = {
   GET_ALL_MERCHANTS: 'GET_ALL_MERCHANTS',
   GET_PREMIUM_MERCHANTS: 'GET_PREMIUM_MERCHANTS',
   VERIFY_MERCHANT: 'GET_VERIFY_MERCHANT',

   GET_MERCHANT_DETAILS: 'GET_MERCHANT_DETAILS',
   UPGRADE_MERCHANT: 'GET_UPGRADE_MERCHANT',
   SEARCH_MERCHANT: 'SEARCH_MERCHANT',
   GET_MERCHANT_TRANSACTION_HISTORY: 'GET_MERCHANT_TRANSACTION_HISTORY',
   GET_MERCHANT_SETTLEMENT_HISTORY: 'GET_MERCHANT_SETTLEMENT_HISTORY',

   REQUEST_ALL_MERCHANTS: 'REQUEST_ALL_MERCHANTS',
   REQUEST_ALL_MERCHANTS_ERROR: 'REQUEST_ALL_MERCHANTS_ERROR',
   REQUEST_ALL_MERCHANTS_SUCCESS: 'REQUEST_ALL_MERCHANTS_SUCCESS',

   REQUEST_PREMIUM_MERCHANTS: 'REQUEST_PREMIUM_MERCHANTS',
   REQUEST_PREMIUM_MERCHANTS_ERROR: 'REQUEST_PREMIUM_MERCHANTS_ERROR',
   REQUEST_PREMIUM_MERCHANTS_SUCCESS: 'REQUEST_PREMIUM_MERCHANTS_SUCCESS',

   REQUEST_VERIFY_MERCHANT: 'REQUEST_VERIFY_MERCHANT',
   REQUEST_VERIFY_MERCHANT_ERROR: 'REQUEST_VERIFY_MERCHANT_ERROR',
   REQUEST_VERIFY_MERCHANT_SUCCESS: 'REQUEST_VERIFY_MERCHANT_SUCCESS',

   REQUEST_MERCHANT_DETAILS: 'REQUEST_MERCHANT_DETAILS',
   REQUEST_MERCHANT_DETAILS_ERROR: 'REQUEST_MERCHANT_DETAILS_ERROR',
   REQUEST_MERCHANT_DETAILS_SUCCESS: 'REQUEST_MERCHANT_DETAILS_SUCCESS',

   REQUEST_UPGRADE_MERCHANT: 'REQUEST_UPGRADE_MERCHANT',
   REQUEST_UPGRADE_MERCHANT_ERROR: 'REQUEST_UPGRADE_MERCHANT_ERROR',
   REQUEST_UPGRADE_MERCHANT_SUCCESS: 'REQUEST_UPGRADE_MERCHANT_SUCCESS',

   REQUEST_MERCHANT_TRANSACTION_HISTORY: 'REQUEST_MERCHANT_TRANSACTION_HISTORY',
   REQUEST_MERCHANT_TRANSACTION_HISTORY_ERROR:
      'REQUEST_MERCHANT_TRANSACTION_HISTORY_ERROR',
   REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS:
      'REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS',
   REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA:
      'REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA',

   REQUEST_MERCHANT_SETTLEMENT_HISTORY: 'REQUEST_MERCHANT_SETTLEMENT_HISTORY',
   REQUEST_MERCHANT_SETTLEMENT_HISTORY_ERROR:
      'REQUEST_MERCHANT_SETTLEMENT_HISTORY_ERROR',
   REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS:
      'REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS',
   REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS_WITHOUT_DATA:
      'REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS_WITHOUT_DATA',

   REQUEST_SEARCH_MERCHANT: 'REQUEST_SEARCH_MERCHANT',
   SEARCH_PREMIUM_MERCHANT_SUCCESS: 'SEARCH_PREMIUM_MERCHANT_SUCCESS',
   SEARCH_BASIC_MERCHANT_SUCCESS: 'SEARCH_BASIC_MERCHANT_SUCCESS',

   SEARCH_MERCHANT_ERROR: 'SEARCH_MERCHANT_ERROR',
   SEARCH_BASIC_MERCHANT_SUCCESS_WITHOUT_DATA:
      'SEARCH_BASIC_MERCHANT_SUCCESS_WITHOUT_DATA',
   SEARCH_PREMIUM_MERCHANT_SUCCESS_WITHOUT_DATA:
      'SEARCH_PREMIUM_MERCHANT_SUCCESS_WITHOUT_DATA',

   // MERCHANT_ROLE_ID: '60d9a086ebe692002293dda2', //test server
   // MERCHANT_ROLE_ID: '6135fb4af931706b4e131e3f', //prod server
   MERCHANT_ROLE_ID: '66684e42d3bed7453c3db33b', //prod server

   ALL_MERCHANTS_URI: `${appConstants.BASE_URI}${merchant}`,
   GET_MERCHANTS_URI: `${appConstants.BASE_URI}${merchant}/`,
   MERCHANT_TRANSACTIONS_URI: `${appConstants.BASE_URI}${account}/transaction_history/`,
};
