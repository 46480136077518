import {appConstants} from './app.constant';

const user = 'user';
// const account = 'account';
const transaction = 'account/transaction_history';
const walletAccount = 'account/wallet/customer';
const loan = 'loan/customer';

export const customerConstants = {
   GET_LOAN_CUSTOMERS: 'GET_LOAN_CUSTOMERS',
   GET_WALLET_CUSTOMERS: 'GET_WALLET_CUSTOMERS',
   GET_ALL_CUSTOMERS: 'GET_ALL_CUSTOMERS',

   GET_CUSTOMER_DETAILS: 'GET_CUSTOMER_DETAILS',
   UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
   SEARCH_CUSTOMER: 'SEARCH_CUSTOMER',

   GET_CUSTOMER_TRANSACTION_HISTORY: 'GET_CUSTOMER_TRANSACTION_HISTORY',
   GET_CUSTOMER_LOAN_HISTORY: 'GET_CUSTOMER_LOAN_HISTORY',
   GET_CUSTOMER_WALLET_HISTORY: 'GET_CUSTOMER_WALLET_HISTORY',
   CHANGE_USER_STATUS: 'CHANGE_USER_STATUS',

   REQUEST_LOAN_CUSTOMERS: 'REQUEST_LOAN_CUSTOMERS',
   REQUEST_LOAN_CUSTOMERS_ERROR: 'REQUEST_LOAN_CUSTOMERS_ERROR',
   REQUEST_LOAN_CUSTOMERS_SUCCESS: 'REQUEST_LOAN_CUSTOMERS_SUCCESS',

   REQUEST_WALLET_CUSTOMERS: 'REQUEST_WALLET_CUSTOMERS',
   REQUEST_WALLET_CUSTOMERS_ERROR: 'REQUEST_WALLET_CUSTOMERS_ERROR',
   REQUEST_WALLET_CUSTOMERS_SUCCESS: 'REQUEST_WALLET_CUSTOMERS_SUCCESS',

   REQUEST_ALL_CUSTOMERS: 'REQUEST_ALL_CUSTOMERS',
   REQUEST_ALL_CUSTOMERS_ERROR: 'REQUEST_ALL_CUSTOMERS_ERROR',
   REQUEST_ALL_CUSTOMERS_SUCCESS: 'REQUEST_ALL_CUSTOMERS_SUCCESS',

   REQUEST_CUSTOMER_DETAILS: 'REQUEST_CUSTOMER_DETAILS',
   REQUEST_CUSTOMER_DETAILS_ERROR: 'REQUEST_CUSTOMER_DETAILS_ERROR',
   REQUEST_CUSTOMER_DETAILS_SUCCESS: 'REQUEST_CUSTOMER_DETAILS_SUCCESS',

   REQUEST_CUSTOMER_TRANSACTION_HISTORY: 'REQUEST_CUSTOMER_TRANSACTION_HISTORY',
   REQUEST_CUSTOMER_TRANSACTION_HISTORY_ERROR:
      'REQUEST_CUSTOMER_TRANSACTION_HISTORY_ERROR',
   REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS:
      'REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS',
   REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA:
      'REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA',

   REQUEST_CUSTOMER_LOAN_HISTORY: 'REQUEST_CUSTOMER_LOAN_HISTORY',
   REQUEST_CUSTOMER_LOAN_HISTORY_ERROR: 'REQUEST_CUSTOMER_LOAN_HISTORY_ERROR',
   REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS:
      'REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS',
   REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS_WITHOUT_DATA:
      'REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS_WITHOUT_DATA',

   REQUEST_CUSTOMER_WALLET_HISTORY: 'REQUEST_CUSTOMER_WALLET_HISTORY',
   REQUEST_CUSTOMER_WALLET_HISTORY_ERROR: 'REQUEST_CUSTOMER_WALLET_HISTORY_ERROR',
   REQUEST_CUSTOMER_WALLET_HISTORY_SUCCESS:
      'REQUEST_CUSTOMER_WALLET_HISTORY_SUCCESS',
   REQUEST_CUSTOMER_WALLET_HISTORY_SUCCESS_WITHOUT_DATA:
      'REQUEST_CUSTOMER_WALLET_HISTORY_SUCCESS_WITHOUT_DATA',

   REQUEST_UPDATE_CUSTOMER: 'REQUEST_UPDATE_CUSTOMER',
   UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
   UPDATE_CUSTOMER_ERROR: 'UPDATE_CUSTOMER_ERROR',

   REQUEST_CHANGE_USER_STATUS: 'REQUEST_CHANGE_USER_STATUS',
   CHANGE_USER_STATUS_SUCCESS: 'CHANGE_USER_STATUS_SUCCESS',
   CHANGE_USER_STATUS_ERROR: 'CHANGE_USER_STATUS_ERROR',

   REQUEST_SEARCH_CUSTOMER: 'REQUEST_SEARCH_CUSTOMER',
   SEARCH_REGISTERED_CUSTOMER_SUCCESS: 'SEARCH_REGISTERED_CUSTOMER_SUCCESS',
   SEARCH_LOAN_CUSTOMER_SUCCESS: 'SEARCH_LOAN_CUSTOMER_SUCCESS',
   SEARCH_WALLET_CUSTOMER_SUCCESS: 'SEARCH_WALLET_CUSTOMER_SUCCESS',
   SEARCH_CUSTOMER_ERROR: 'SEARCH_CUSTOMER_ERROR',
   SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA: 'SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA',

   // CUSTOMER_ROLE_ID: '60d9a02aebe692002293dda1', //test server
   // CUSTOMER_ROLE_ID: '6135fb4af931706b4e131e3e', //prod server
   CUSTOMER_ROLE_ID: '66684e42d3bed7453c3db33a', //prod server

   // LOAN_CUSTOMERS_URI: `${appConstants.BASE_URI}user/customers?type=loan`,
   ALL_CUSTOMERS_URI: `${appConstants.BASE_URI}customers`,
   CHANGE_USER_STATUS_URI: `${appConstants.BASE_URI}${user}/change_user_status`,
   CUSTOMER_TRANSACTIONS_URI: `${appConstants.BASE_URI}${transaction}/`,
   CUSTOMER_WALLET_URI: `${appConstants.BASE_URI}${walletAccount}/`,
   CUSTOMER_LOAN_URI: `${appConstants.BASE_URI}${loan}/`,
};
