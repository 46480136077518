import React, {useEffect, useState} from 'react';
// import {MDBRow} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
// import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import './index.scss';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
// import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';
// import {ReactComponent as ArrowDown} from '../../_assets/icons/1arrow-down.svg';
// import {isActive} from '../../_constants';
import {merchantConstants, customerConstants, isActive} from '../../_constants';
import {userActions, appActions} from '../../_actions';

export default function UpdateAdminUserForm({type, userData}) {
   const dispatch = useDispatch();

   useEffect(() => {
      setModalIsOpen(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [canSubmit, setCanSubmit] = useState(false);
   const [active, setIsActive] = useState(userData?.user_id?.isActive);
   const [role, setRole] = useState();

   const [modalIsOpen, setModalIsOpen] = useState(false);
   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);userData?.role._id
   // };

   const [secModalIsOpen, setSecModalIsOpen] = useState(false);

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   // const [isOpen, setIsOpen] = useState(false);
   // const toggleList = () => {
   //    setIsOpen((wasOpen) => !wasOpen);
   // };

   // const [selected, setSelected] = useState(0);

   // const options = [
   //    {label: 'Customer Relation', val: 0},
   //    {label: 'Admin', val: 1},
   //    {label: 'Super Admin', val: 2},
   // ];

   const rolesList = useSelector((s) => s.role.roleList);
   const user = useSelector((s) => s.user);

   useEffect(() => {
      if (user?.adminUserUpdated) {
         setModalIsOpen(false);
         dispatch(appActions.clearMessages());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user?.adminUserUpdated]);

   const roles = rolesList.filter(
      (role) => role._id !== merchantConstants.MERCHANT_ROLE_ID,
   );

   const adminRoles = roles.filter(
      (role) => role._id !== customerConstants.CUSTOMER_ROLE_ID,
   );

   const errorMessage = useSelector((s) => s.user.errorMessage);
   const emailError = errorMessage?.errors.filter(
      (error) => error.param === 'email',
   );
   const firstNameError = errorMessage?.errors.filter(
      (error) => error.param === 'firstname',
   );
   const lastNameError = errorMessage?.errors.filter(
      (error) => error.param === 'lastname',
   );

   const handleSubmit = (data) => {
      data['role'] = role;
      data['isActive'] = active;
      data['id'] = userData?.user_id?._id;
      delete data['email'];

      dispatch(userActions.updateAdminUser(data));
   };

   return (
      <div className="add-new">
         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={() => setModalIsOpen(false)}>
            <Formsy
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}
               className="__form d-flex flex-column">
               <h4 className="text-left mb-3">
                  {type === 'add'
                     ? 'Invite New User'
                     : "Update User's Information"}
               </h4>
               <p className="text-left loan mb-4">
                  {type === 'add'
                     ? "Fill in the new Admin User's details"
                     : "Fill in the new Admin User's details"}
               </p>
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="firstname"
                  type="text"
                  labelClassName="label"
                  labelText="First Name"
                  className="input11 border-0 w-100"
                  placeholder="Kobe"
                  required
                  value={userData?.firstname}
               />
               <p class="text-red">{firstNameError?.[0]?.msg}</p>
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="lastname"
                  type="text"
                  labelClassName="label"
                  labelText="Last Name"
                  className="input11 border-0 w-100"
                  placeholder="Brown"
                  required
                  value={userData?.lastname}
               />
               <p class="text-red">{lastNameError?.[0]?.msg}</p>
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="email"
                  type="email"
                  labelClassName="label"
                  labelText="Email"
                  className="input11 border-0 w-100"
                  placeholder="finance123"
                  validations="isEmail"
                  validationError="The email is invalid!"
                  required
                  disabled
                  value={userData?.user_id?.email}
               />
               <p>{emailError && emailError[0] && emailError[0].msg}</p>
               {/* <div className="form-group w-100">
                  <label className="label mb-0">Role</label>
                  <div
                     onClick={toggleList}
                     className="input222 d-flex position-relative align-items-center justify-content-between ">
                     <span className="d-block">{rolesList[selected].name}</span>
                     <ArrowDown style={{width: '1rem'}} />
                     {isOpen && (
                        <div
                           onRequestClose={() => setIsOpen(false)}
                           className="list position-absolute">
                           <ul className="list-items">
                              {rolesList
                                 // .filter((i) => i.val !== 0)
                                 .map((i, n) => (
                                    <li
                                       key={n}
                                       onClick={(e) => {
                                          e.stopPropagation();
                                          setIsOpen(false);
                                          setSelected(i._id);
                                       }}>
                                       {i.name}
                                    </li>
                                 ))}
                           </ul>
                        </div>
                     )}
                  </div>
               </div> */}
               <FormControl
                  variant="outlined"
                  margin="normal"
                  // className={classes.formControl}
               >
                  <InputLabel id="demo-simple-select-outlined-label">
                     Role
                  </InputLabel>
                  <Select
                     labelId="demo-simple-select-outlined-label"
                     id="demo-simple-select-outlined"
                     value={role}
                     name="role"
                     onChange={(e) => setRole(e.target.value)}
                     label="Role">
                     {adminRoles.map((option) => (
                        <MenuItem value={option._id}>{option.name}</MenuItem>
                     ))}
                  </Select>
               </FormControl>
               <FormControl
                  variant="outlined"
                  margin="normal"
                  // className={classes.formControl}
               >
                  <InputLabel id="demo-simple-select-outlined-label">
                     Set Active
                  </InputLabel>
                  <Select
                     labelId="demo-simple-select-outlined-label"
                     id="demo-simple-select-outlined"
                     value={active}
                     name="is_active"
                     onChange={(e) => setIsActive(e.target.value)}
                     label="Role">
                     {isActive.map((option) => (
                        <MenuItem value={option.value}>{option.title}</MenuItem>
                     ))}
                  </Select>
               </FormControl>
               <button disabled={!canSubmit} className="w-100 mt-3 mt-s-4">
                  {user.IsUpdatingAdminUser ? (
                     <div class="text-white" role="status">
                        <i class="fa fa-spinner fa-spin"></i> Updating
                     </div>
                  ) : (
                     <div className="submit-btn w-100">Update User</div>
                  )}
               </button>
            </Formsy>
         </Modal>

         <Modal
            isOpen={secModalIsOpen}
            style={customStyles}
            onRequestClose={() => setSecModalIsOpen(false)}>
            <div className="content d-flex flex-column">
               <AcctCredited className="credited" style={{width: '6rem'}} />
               <h3 className="m-0 d-flex justify-content-center py-2">
                  Invite Sent
               </h3>
               <p>Invite has been sent to the mail</p>
            </div>
         </Modal>
      </div>
   );
}
