import {settlementConstants} from '../_constants';

const getSuccessfulSettlements = (model) => ({
   type: settlementConstants.GET_SUCCESSFUL_SETTLEMENTS,
   data: model,
});

const getFailedSettlements = (model) => ({
   type: settlementConstants.GET_FAILED_SETTLEMENTS,
   data: model,
});

const searchSettlement = (model) => ({
   type: settlementConstants.SEARCH_SETTLEMENT,
   data: model,
});

const verifyBvn = (model) => ({
   type: settlementConstants.VERIFY_BVN,
   data: model,
});

export const settlementActions = {
   getSuccessfulSettlements,
   getFailedSettlements,
   searchSettlement,
   verifyBvn,
};
