export const isActive = [
   {title: 'Active', value: true},
   {title: 'Not Active', value: 'false'},
];

export const roles = [
   {title: 'Admin', value: 1},
   {title: 'Sub Admin', value: 2},
   {title: 'Cashier', value: 3},
];

export const marketerRoles = [
   {title: 'Head', value: 1},
   {title: 'Sales Officer', value: 2},
];
