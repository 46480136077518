import React from 'react';
import {MDBCol, MDBContainer, MDBRow} from 'mdbreact';
import {Route, Switch, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import {useLocationCode} from './_navigation/useLocationCode';
import classNames from 'classnames';
import './App.scss';

import Snackbar2 from './_components/Snackbar';
import './_components/Snackbar/index.scss';

import Header from './_navigation/Header';
import Sidebar from './_navigation/Sidebar';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import {CustomerProfile, Customer} from './Pages/Customer';
import {MerchantProfile, Merchant} from './Pages/Merchant';
import VerifyEmail from './Pages/VerifyEmail';
import SetPassword from './Pages/SetPassword';
import Loan from './Pages/Loan';
import LoanCustomerDetails from './Pages/Loan/view';
import Settlement from './Pages/Settlement';
import Transactions from './Pages/Transactions';
import {Teams, BranchMarketers, MarketerCustomers} from './Pages/Teams';
// import BranchMarketers from './Pages/Teams/branchMarketers';
import NewAdminSignUp from './Pages/Login/newUser';
import Settings from './Pages/Settings';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ForgotPassword/ResetPassword';
import {WalletCustomer} from './Pages/Customer/walletCustomer';
import {RegisteredCustomer} from './Pages/Customer/registeredCustomer';
import {PremiumMerchant} from './Pages/Merchant/premiumMerchant';
import {PrivateRoute} from './_components/PrivateRoute';
// import Snackbar from './_components/Snackbar/index.js';
import {appActions, userActions} from './_actions';

// export default function App() {
// const styles = () => ({
//    success: {
//       backgroundColor: '#ACE4B9',
//    },
//    error: {
//       backgroundColor: 'red',
//    },
// });
function App(props) {
   const code = useLocationCode();
   const dispatch = useDispatch();
   const user = useSelector((s) => s.user);
   const snackbar = useSelector((s) => s.snackbar);

   const logout = () => {
      props.history.push('/');
      dispatch(userActions.logout());
   };

   const clearSnackBar = () => {
      dispatch(appActions.clearSnackBar());
   };

   // const customStyles = {
   //    success: {
   //       backgroundColor: '#ACE4B9',
   //    },
   //    error: {
   //       backgroundColor: 'red',
   //    },
   // };

   return (
      <>
         <Sidebar logoutAdmin={logout} />
         <MDBContainer fluid className="main-container p-0">
            <MDBRow className="h-100 m-0">
               <Header history={props.history} logoutAdmin={logout} />
               <MDBCol
                  size="12"
                  className={classNames('body p-0 m-0 w-100', {
                     content: code !== 9,
                     full: code === 9,
                  })}>
                  <Switch>
                     <Route exact path="/" component={Login} />
                     <Route path="/login" component={Login} />
                     <Route
                        exact
                        path="/admin/:token"
                        component={NewAdminSignUp}
                     />
                     <Route
                        exact
                        path="/verify_email/:token/:email"
                        component={VerifyEmail}
                     />
                     <Route path="/set-password" component={SetPassword} />
                     <PrivateRoute
                        path="/dashboard"
                        user={user?.user}
                        component={Dashboard}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/customer/loan/:uuid"
                        component={CustomerProfile}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/customer/wallet/:uuid"
                        component={CustomerProfile}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/customer/registered/:uuid"
                        component={CustomerProfile}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/customer/loan"
                        component={Customer}
                     />
                     <PrivateRoute
                        exact
                        path="/customer/wallet"
                        user={user?.user}
                        component={WalletCustomer}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/customer/registered"
                        component={RegisteredCustomer}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/merchant/regular/:uuid"
                        component={MerchantProfile}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/merchant/premium/:uuid"
                        component={MerchantProfile}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/merchant/regular"
                        component={Merchant}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/merchant/premium"
                        component={PremiumMerchant}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/loan"
                        component={Loan}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/loan-customer/:uuid"
                        component={LoanCustomerDetails}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/settlement"
                        component={Settlement}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/transactions"
                        component={Transactions}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/teams"
                        component={Teams}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/teams/:uuid"
                        component={BranchMarketers}
                     />
                     <PrivateRoute
                        exact
                        user={user?.user}
                        path="/teams/marketers/:uuid/customers"
                        component={MarketerCustomers}
                     />
                     <PrivateRoute
                        path="/settings"
                        user={user?.user}
                        component={Settings}
                     />
                     <Route
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                     />
                     <Route
                        exact
                        path="/reset_password/:token"
                        component={ResetPassword}
                     />

                     <Route component={Login} />
                  </Switch>
               </MDBCol>
            </MDBRow>
            {snackbar?.message && (
               <Snackbar
                  message={snackbar?.message.text}
                  autoHideDuration={3000}
                  clearSnackBar={clearSnackBar}
               />
            )}
            {snackbar?.message && (
               <Snackbar2
                  onClose={clearSnackBar}
                  variant={snackbar.message.variant}
                  message={
                     <span id="message-id">{snackbar?.message.text}</span>
                  }
               />
            )}
         </MDBContainer>
      </>
   );
}

export default withRouter(App);
