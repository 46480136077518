import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import LetteredAvatar from 'lettered-avatar';
import MarketerForm from './marketerForm';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

// import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
// import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
// import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
import Loader from '../../_helpers/loader';
import {branchActions} from '../../_actions';
import {numberWithCommas} from '../../_helpers';
import {userConstants} from '../../_constants';

export function BranchMarketers({match}) {
   const dispatch = useDispatch();

   useEffect(() => {
      const {uuid} = match?.params;

      dispatch(branchActions.getBranchMarketers(uuid));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const IsRequestingBranchMarketers = useSelector(
      (s) => s.branch.IsRequestingBranchMarketers,
   );
   const IsSearchingTransaction = useSelector(
      (s) => s.branch.IsSearchingTransaction,
   );
   const branchMarketersList = useSelector((s) => s.branch.branchMarketersList);
   const userDetails = useSelector((s) => s.user.user);
   const userDetail = userDetails.user;

   // const {totalDocs, limit, page, totalPages} = useSelector(
   //    (s) => s.branch.pagination,
   // );
   // const total = totalDocs;
   // const pages = page - 1;

   // const handleChangePage = (event, pages) => {
   //    const model = {
   //       page: pages + 1,
   //    };
   //    const filterModel = {
   //       from: startDate,
   //       to: endDate,
   //       page: pages + 1,
   //    };
   //    dispatch(
   //       transactionActions.getAllTransactions(
   //          toDate === false ? model : filterModel,
   //       ),
   //    );
   // };

   // const [searchInput, setSearchInput] = useState('');
   // const searchBranchMarketer = () => {
   //    const model = {
   //       searchName: searchInput,
   //    };
   //    dispatch(branchActions.searchBranchMarketer(model));
   // };

   // useEffect(() => {
   //    if (searchInput === '') {
   //       const {uuid} = match?.params;
   //       dispatch(branchActions.getBranchMarketers(uuid));
   //    }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [searchInput]);

   // const clearText = () => {
   //    // setIsDateOpen(true);
   //    setSearchInput('');
   // };

   // const [select, setSelect] = useState(false);

   return (
      <MDBRow className="admin-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search filter">
               {/* <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     value={searchInput}
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon
                     className="icons i-x"
                     style={{width: '1rem'}}
                     onClick={clearText}
                  />
                  <span className="i-search" onClick={searchBranchMarketer}>
                     <SearchIcon style={{width: '1rem'}} />
                  </span>
               </div> */}
            </div>
            <div className="filter d-flex">
               {userDetail?.role.includes(userConstants.ADMIN_ROLE_ID) && (
                  <MarketerForm branchId={match?.params?.uuid} />
               )}
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               {/* <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div> */}
            </div>
         </div>
         {/* {select && (
            <MDBInput
               className="mr-2"
               style={{width: '.65rem', height: '.65rem'}}
               type="checkbox"
               id="checkbox"
            />
         )} */}

         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Full Name</th>
                     <th scope="col">Phone Number</th>
                     <th scope="col">Role</th>
                     <th scope="col">Loan budget(#)</th>
                     <th scope="col">Topup budget(#)</th>
                     <th scope="col">Achieved Loan(%)</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingBranchMarketers || IsSearchingTransaction ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {branchMarketersList?.length > 0 ? (
                        branchMarketersList.map((row) => {
                           return (
                              <Link
                                 // to={`/teams/marketers/${row._id}/customers`}
                                 to={{
                                    pathname: `/teams/marketers/${row._id}/customers`,
                                    state: row,
                                 }}
                                 className="d-table-row">
                                 <th scope="row d-flex align-items-center justify-content-center">
                                    {/* {select && (
                                       <MDBInput
                                          className="mr-2"
                                          style={{
                                             width: '.65rem',
                                             height: '.65rem',
                                          }}
                                          type="checkbox"
                                          id="checkbox"
                                       />
                                    )} */}
                                    <LetteredAvatar
                                       name={row.firstname + ' ' + row.lastname}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass:
                                             'image-responsive user-image mr-1',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>
                                       {row.firstname + ' ' + row.lastname}
                                    </span>
                                 </th>
                                 <td>{row.phone_number}</td>
                                 <td>
                                    {row.role === 1 ? 'Head' : 'SalesOfficer'}
                                 </td>
                                 <td>{numberWithCommas(row.loanBudget)}</td>
                                 <td>{numberWithCommas(row.topupBudget)}</td>
                                 <td>{row.achieved_loan}</td>
                                 {/* <td>{row.merchant_name}</td>
                                 <td>
                                    {capitalizeFirstLetter(
                                       row?.narration || 'None',
                                    )}
                                 </td>
                                 <td>{numberWithCommas(row.amount)}</td>
                                 <td>{readableDateString(row.created_at)}</td> */}
                              </Link>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           No member has been added to this branch
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {/* {!IsRequestingAllTransactions && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )} */}
      </MDBRow>
   );
}
