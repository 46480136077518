import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import LetteredAvatar from 'lettered-avatar';
import './index.scss';

// import img from '../../_assets/icons/1user-hash.svg';
import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {merchantActions} from '../../_actions';
import {CSVLink} from 'react-csv';

export function Merchant() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(merchantActions.getAllMerchant());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const IsRequestingAllMerchants = useSelector(
      (s) => s.merchant.IsRequestingAllMerchants,
   );
   const IsSearchingMerchant = useSelector(
      (s) => s.merchant.IsSearchingMerchant,
   );
   const allMerchantsList = useSelector((s) => s.merchant.allMerchantsList);
   const pagination = useSelector((s) => s.merchant.pagination);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const [isOpen, setIsOpen] = useState(false);
   const [searchInput, setSearchInput] = useState('');
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
   };

   const [isStatusOpen, setIsStatusOpen] = useState(false);
   const toggleStatusList = () => {
      setIsStatusOpen((wasOpen) => !wasOpen);
      setSelect((wasOpen) => !wasOpen);
   };
   const [select, setSelect] = useState(false);

   const [isDateOpen, setIsDateOpen] = useState(false);
   const toggleDate = () => {
      // setIsDateOpen(true);
      setIsDateOpen((wasOpen) => !wasOpen);
   };

   const [selected, setSelected] = useState(0);
   const options = [
      {label: 'Status', val: 0},
      {label: 'Active', val: 1},
      {label: 'Inactive', val: 2},
   ];

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(merchantActions.getAllMerchant(model));
   };

   const searchMerchant = () => {
      // const model = {
      //    searchName: searchInput,
      //    type: 'basic',
      // };
      const model = {
         query: searchInput,
         type: 'basic',
         limit: 10,
         sort: {
            merchant_name: 1,
         },
      };
      dispatch(merchantActions.searchMerchant(model));
   };

   const clearText = () => {
      // setIsDateOpen(true);
      setSearchInput('');
   };
   const filterStatus = (val) => {
      setSelected(val);
      const bool = val === 1 ? 'true' : 'false';
      const model = {
         searchName: bool,
         type: 'basic',
         limit: 10,
         sort: {
            merchant_name: 1,
         },
      };
      console.log('model', model);
      dispatch(merchantActions.searchMerchant(model));
   };

   useEffect(() => {
      if (searchInput === '') {
         dispatch(merchantActions.getAllMerchant());
      } else {
         // const model = {
         //    searchName: searchInput,
         // };
         const model = {
            searchName: searchInput,
            type: 'basic',
            limit: 10,
            sort: {
               merchant_name: 1,
            },
         };
         dispatch(merchantActions.searchMerchant(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchInput]);

   return (
      <MDBRow className="merchant-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search ">
               <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     value={searchInput}
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon
                     className="icons i-x"
                     style={{width: '1rem'}}
                     onClick={clearText}
                  />
                  <span className="i-search" onClick={searchMerchant}>
                     <SearchIcon style={{width: '1rem'}} />
                  </span>
                  {isOpen && (
                     <div className="collaps">
                        <div
                           onClick={toggleStatusList}
                           className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                           <span className="d-block">
                              {options[selected].label}
                           </span>
                           <ArrDown className="ml-4" />
                           {isStatusOpen && (
                              <div className="status-list position-absolute">
                                 <ul className="">
                                    {options
                                       .filter((i) => i.val !== 0)
                                       .map((i, n) => (
                                          <li
                                             key={n}
                                             onClick={(e) => {
                                                filterStatus(i.val);
                                             }}>
                                             <Link className="w-100 d-block">
                                                {i.label}
                                             </Link>
                                          </li>
                                       ))}
                                 </ul>
                              </div>
                           )}
                        </div>
                     </div>
                  )}
               </div>
            </div>

            <div className="filter d-flex">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               {allMerchantsList.length > 0 && (
                  <CSVLink data={allMerchantsList} className="csv">
                     Download CSV
                  </CSVLink>
               )}
               <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div>
               {/* {isOpen && (
                  <div className="collaps">
                     <div
                        onClick={toggleDate}
                        className="collaps-item mr-5 position-relation">
                        Date Range <ArrDown className="ml-4" />
                        {isDateOpen && (
                           <div className="date p-3 position-absolute">
                              <h5>Some date functions here...</h5>
                              <p>down here...</p>
                           </div>
                        )}
                     </div>
                     <div
                        onClick={toggleStatusList}
                        className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrDown className="ml-4" />
                        {isStatusOpen && (
                           <div className="status-list position-absolute">
                              <ul className="">
                                 {options
                                    .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             // e.stopPropagation();
                                             // setIsOpen(false);
                                             setSelected(i.val);
                                          }}>
                                          <Link className="w-100 d-block">
                                             {i.label}
                                          </Link>
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
               )} */}
            </div>
         </div>

         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     {/* <th scope="col">Name</th> */}
                     <th scope="col">Company Name</th>
                     <th scope="col">Email</th>
                     <th scope="col">Phone Number</th>
                     <th scope="col">Verification</th>
                     <th scope="col">Merchant ID</th>
                     {/* <th scope="col">Balance</th> */}
                  </tr>
               </MDBTableHead>
               {IsRequestingAllMerchants || IsSearchingMerchant ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {allMerchantsList?.length > 0 ? (
                        allMerchantsList.map((row) => {
                           return (
                              <Link
                                 to={{
                                    pathname: `/merchant/regular/${row?._id}`,
                                    state: {merchantId: row?._id},
                                 }}
                                 className="d-table-row align-items-center">
                                 <th scope="row">
                                    {/* // className="d-flex justify-content-center align-items-center"> */}
                                    {/* {select && (
                                       <MDBInput
                                          className="mr-2"
                                          style={{
                                             width: '.65rem',
                                             height: '.65rem',
                                          }}
                                          type="checkbox"
                                          id="checkbox"
                                       />
                                    )} */}
                                    <LetteredAvatar
                                       name={row.merchant_name}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass:
                                             'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>
                                       {row.merchant_name}
                                       {/* {row.user_id?.firstname +
                                          ' ' +
                                          row.user_id?.lastname} */}
                                    </span>
                                 </th>
                                 {/* <td>{row.merchant_name}</td> */}
                                 <td>{row.merchant_email}</td>
                                 <td>
                                    {row.merchant_phone?.replace(/^.{3}/g, '0')}
                                 </td>
                                 <td>
                                    {row.verified ? 'Verified' : 'Not Verified'}
                                 </td>
                                 <td>{row.merchant_id}</td>
                                 {/* <td>30, 000</td> */}
                              </Link>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {!IsRequestingAllMerchants && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </MDBRow>
   );
}

export * from './view';
