import React, {useEffect, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import Formsy from 'formsy-react';
import './index.scss';

import TextInput from '../../_components/TextInput';
import SharedBackground from '../../_components/SharedBackground';
// import {userConstants} from '../../_constants';
import {appActions, userActions} from '../../_actions';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function Login({history, location}) {
   const dispatch = useDispatch();
   const user = useSelector((s) => s.user);
   const auth = useSelector((s) => s.auth);

   const [oldPassword, setOldPassword] = useState(false);
   const [passError, setPasswordError] = useState('');

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [canSubmit, setCanSubmit] = useState(false);

   const validatePassword = useCallback((val) => {
      if (val.length === 0) {
         setPasswordError('');
         // setPasswordSet(false);
         return;
      }

      if (!/^[a-zA-Z0-9]+$/.test(val)) {
         setPasswordError('Contains invalid character(s)!');
         // setPasswordSet(false);
         return;
      }

      if (!/[A-Z]/.test(val)) {
         setPasswordError('Should contain a capital letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[a-z]/.test(val)) {
         setPasswordError('Should contain a small letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[0-9]/.test(val)) {
         setPasswordError('Should contain a number!');
         // setPasswordSet(false);
         return;
      }

      if (val.length < 8) {
         setPasswordError('Minimum of 8 characters required!');
         // setPasswordSet(false);
         return;
      }

      setPasswordError('');
      // setPasswordSet(true);
   }, []);

   const handlePasswordChange = useCallback((itemValue) => {
      validatePassword(itemValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSubmit = (data) => {
      // data['role'] = userConstants.ADMIN_ROLE_ID;

      dispatch(userActions.login(data));
   };

   const clearError = () => {
      dispatch(appActions.clearMessages());
   };

   if (user.IsRequestingUser) {
      return null;
   }

   if (auth.IsRequestingLogout) {
      return null;
   }

   if (user.user) {
      if (location.state !== undefined) {
         history.push(location.state.from.pathname);
      } else {
         history.push('/dashboard');
      }
   }

   const errorMessage = auth?.errorMessage;
   const passwordError = errorMessage?.errors?.filter(
      (error) => error.param === 'password',
   );

   return (
      <SharedBackground>
         <MDBRow className="h-100 w-100 m-0 log-page">
            <div className="right d-flex align-items-center justify-content-center h-100 w-100">
               <Formsy
                  onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="__form px-md-4 px-3 py-4 d-flex flex-column">
                  <p style={{color: 'red'}}>{errorMessage?.message}</p>
                  <TextInput
                     name="email"
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     placeholder="kalu@gmail.com"
                     useFocusedError
                     labelText="Email"
                     labelClassName="label"
                     className="input pl-4 border-0 w-100"
                     validations="isEmail"
                     validationError="This is not a valid email!"
                     required
                  />
                  <div className="d-flex align-items-center">
                     <TextInput
                        name="password"
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-3 mb-s-4"
                        type={oldPassword ? 'text' : 'password'}
                        placeholder="********"
                        useFocusedError
                        labelText="Password"
                        labelClassName="label"
                        className="input pl-4 border-0 w-100"
                        validationError="Please provide your password!"
                        required
                        clearError={clearError}
                        handleChange={handlePasswordChange}
                     />
                     <i
                        onClick={() => setOldPassword(!oldPassword)}
                        className="ml-n4 mt-3 pass-icon">
                        {oldPassword ? (
                           <Visibility style={{width: '1rem'}} />
                        ) : (
                           <VisibilityOff style={{width: '1rem'}} />
                        )}
                     </i>
                  </div>
                  <p style={{color: 'red'}}>{passError}</p>
                  <p style={{color: 'red'}}>
                     {passwordError && passwordError[0] && passwordError[0].msg}
                  </p>
                  <Link to="/forgot-password" className="__link mb-3">
                     Forgot password?
                  </Link>
                  <button disabled={!canSubmit} className="d-block my-3 py-3">
                     {auth.IsRequestingLogin ? (
                        <div class="spinner-border text-white" role="status">
                           <span class="sr-only">Loading...</span>
                        </div>
                     ) : (
                        'Log in'
                     )}
                  </button>
               </Formsy>
            </div>
         </MDBRow>
      </SharedBackground>
   );
}
