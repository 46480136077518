import {
   appConstants,
   userConstants,
   branchConstants,
   customerConstants,
   loanConstants,
   merchantConstants,
   // permissionConstants,
   // roleConstants,
   settlementConstants,
   transactionConstants,
} from '../../_constants';
import {combineReducers} from 'redux';
import {updateObject} from '../../_helpers';

const IsRequestingLogin = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGIN_REQUEST:
         return true;
      case userConstants.LOGIN_FAILURE:
         return false;
      case userConstants.LOGIN_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingLogout = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGOUT_REQUEST:
         return true;
      case userConstants.LOGOUT_FAILURE:
         return false;
      case userConstants.LOGOUT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSettingPassword = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_SET_PASSWORD:
         return true;
      case userConstants.SET_PASSWORD_SUCCESS:
         return false;
      case userConstants.SET_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const IsChangingPassword = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_CHANGE_PASSWORD:
         return true;
      case userConstants.CHANGE_PASSWORD_SUCCESS:
         return false;
      case userConstants.CHANGE_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const emailVerificationSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_EMAIL:
         return false;
      case userConstants.VERIFY_EMAIL_ERROR:
         return false;
      case userConstants.VERIFY_EMAIL_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const emailVerificationFailed = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_EMAIL:
         return false;
      case userConstants.VERIFY_EMAIL_ERROR:
         return true;
      case userConstants.VERIFY_EMAIL_SUCCESS:
         return false;
      case userConstants.RESEND_EMAIL_LINK_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const emailVerifiedId = (state = null, action) => {
   switch (action.type) {
      case userConstants.VERIFY_EMAIL_SUCCESS:
         return action.id;
      default:
         return state;
   }
};

const IsResendingEmailLinkSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_RESEND_EMAIL_LINK:
         return true;
      case userConstants.RESEND_EMAIL_LINK_ERROR:
         return false;
      case userConstants.RESEND_EMAIL_LINK_SUCCESS:
         return false;
      default:
         return state;
   }
};

const resendEmailLinkSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_RESEND_EMAIL_LINK:
         return false;
      case userConstants.RESEND_EMAIL_LINK_ERROR:
         return false;
      case userConstants.RESEND_EMAIL_LINK_SUCCESS:
         return true;
      default:
         return state;
   }
};

const passwordIsSetSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_SET_PASSWORD:
         return false;
      case userConstants.SET_PASSWORD_SUCCESS:
         return true;
      case userConstants.SET_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const passwordIsChangedSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_CHANGE_PASSWORD:
         return false;
      case userConstants.CHANGE_PASSWORD_SUCCESS:
         return true;
      case userConstants.CHANGE_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const error = (state = false, {type}) => {
   switch (type) {
      case userConstants.LOGIN_REQUEST:
         return false;
      case userConstants.LOGIN_FAILURE:
         return true;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case userConstants.LOGIN_FAILURE:
         return updateObject(state, error);
      case userConstants.LOGIN_REQUEST:
         return null;
      case userConstants.VERIFY_EMAIL_ERROR:
         return updateObject(state, error);
      case userConstants.RESEND_EMAIL_LINK_ERROR:
         return updateObject(state, error);
      case userConstants.SET_PASSWORD_ERROR:
         return updateObject(state, error);
      case userConstants.REQUEST_SET_PASSWORD:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const totalDocs = (state = null, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_ALL_BRANCHES_SUCCESS: {
         const totalDocs = action.branches.data.length;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS: {
         const totalDocs = action.branchMarketers.marketers.length;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS: {
         const totalDocs = action.marketerCustomers.customers.length;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case customerConstants.REQUEST_LOAN_CUSTOMERS_SUCCESS: {
         const {totalDocs} = action.loanCustomers.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case customerConstants.REQUEST_WALLET_CUSTOMERS_SUCCESS: {
         const {totalDocs} = action.walletCustomers.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case customerConstants.REQUEST_ALL_CUSTOMERS_SUCCESS: {
         const {totalDocs} = action.allCustomers.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case loanConstants.REQUEST_ALL_LOAN_REQUESTS_SUCCESS: {
         const {totalDocs} = action.loanRequests.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case loanConstants.REQUEST_ALL_LOAN_GRANTED_SUCCESS: {
         const {totalDocs} = action.loanGranted.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case merchantConstants.REQUEST_PREMIUM_MERCHANTS_SUCCESS: {
         const {totalDocs} = action.premiumMerchants.merchants;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case merchantConstants.REQUEST_ALL_MERCHANTS_SUCCESS: {
         const {totalDocs} = action.allMerchants.merchants;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      // case permissionConstants.REQUEST_ALL_PERMISSIONS_SUCCESS: {
      //    const {totalDocs} = action.permissions.data;
      //    const result = {totalDocs};

      //    return updateObject(state, result);
      // }
      // case roleConstants.REQUEST_ALL_ROLES_SUCCESS: {
      //    const {totalDocs} = action.roles.data;
      //    const result = {totalDocs};

      //    return updateObject(state, result);
      // }
      case settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS: {
         const {totalDocs} = action.successfulSettlements.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS: {
         const {totalDocs} = action.allTransactions.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }
      case userConstants.REQUEST_ADMIN_USERS_SUCCESS: {
         const {totalDocs} = action.adminUsers.data;
         const result = {totalDocs};

         return updateObject(state, result);
      }

      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingLogin,
   IsRequestingLogout,
   emailVerificationSuccessful,
   emailVerificationFailed,
   emailVerifiedId,
   IsSettingPassword,
   IsResendingEmailLinkSuccessful,
   resendEmailLinkSuccessful,
   passwordIsSetSuccessful,
   IsChangingPassword,
   passwordIsChangedSuccessful,
   error,
   errorMessage,
   totalDocs,
});

export default rootReducer;
