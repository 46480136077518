import {matchPath, useLocation} from 'react-router-dom';
import queryString from 'query-string';

export const useLocationCode = () => {
   const {pathname} = useLocation();

   const dashboard = matchPath(pathname, '/dashboard');
   const viewLoanCustomer = matchPath(pathname, '/customer/loan/:uuid');
   const viewWalletCustomer = matchPath(pathname, '/customer/wallet/:uuid');
   const viewRegisteredCustomer = matchPath(
      pathname,
      '/customer/registered/:uuid',
   );
   const loanCustomer = matchPath(pathname, '/customer/loan/');
   const walletCustomer = matchPath(pathname, '/customer/wallet/');
   const registeredCustomer = matchPath(pathname, '/customer/registered/');
   const viewRegularMerchant = matchPath(pathname, '/merchant/regular/:uuid');
   const viewPremiumMerchant = matchPath(pathname, '/merchant/premium/:uuid');
   const regularMerchant = matchPath(pathname, '/merchant/regular');
   const premiumMerchant = matchPath(pathname, '/merchant/premium');
   const loan = matchPath(pathname, '/loan');
   const viewLoanCustomerDetails = matchPath(pathname, '/loan-customer/:uuid');
   const settlement = matchPath(pathname, '/settlement');
   // const admin = matchPath(pathname, '/admin');
   const transaction = matchPath(pathname, '/transactions');
   const team = matchPath(pathname, '/teams');
   const viewBranchMarketers = matchPath(pathname, '/teams/:uuid');
   const viewMarketerCustomers = matchPath(
      pathname,
      '/teams/marketers/:uuid/customers',
   );
   const settings = matchPath(pathname, '/settings');

   if (dashboard?.isExact) {
      return 1;
   } else if (
      loanCustomer?.isExact ||
      viewLoanCustomer?.isExact ||
      walletCustomer?.isExact ||
      viewWalletCustomer?.isExact ||
      registeredCustomer?.isExact ||
      viewRegisteredCustomer?.isExact
   ) {
      return 2;
   } else if (
      regularMerchant?.isExact ||
      viewRegularMerchant?.isExact ||
      premiumMerchant?.isExact ||
      viewPremiumMerchant?.isExact
   ) {
      return 3;
   } else if (loan?.isExact || viewLoanCustomerDetails?.isExact) {
      return 4;
   } else if (settlement?.isExact) {
      return 5;
   } else if (transaction?.isExact) {
      return 6;
   } else if (
      team?.isExact ||
      viewBranchMarketers?.isExact ||
      viewMarketerCustomers?.isExact
   ) {
      return 7;
   } else if (settings?.isExact) {
      return 8;
   } else {
      return 9;
   }
};

export const useQueryString = () => {
   const {search} = useLocation();

   if (search) {
      return {...queryString.parse(search)};
   }

   return {};
};
