import React from 'react';
import {Route, Redirect} from 'react-router-dom';
// import NotFound from '../404';

export const PrivateRoute = ({
   component: Component,
   user,
   permission,
   ...rest
}) => (
   <Route
      {...rest}
      render={(props) =>
         user ? (
            <Component {...props} {...rest} user={user} />
         ) : (
            <Redirect to={{pathname: '/', state: {from: props.location}}} />
         )
      }
   />
   // <Route
   //    {...rest}
   //    render={(props) =>
   //       user ? (
   //          permission ? (
   //             user?.user?.permission.includes(1) ? (
   //                <Component {...props} {...rest} user={user} />
   //             ) : (
   //                <NotFound {...props} />
   //             )
   //          ) : (
   //             <Component {...props} {...rest} user={user} />
   //          )
   //       ) : (
   //          <Redirect to={{pathname: '/', state: {from: props.location}}} />
   //       )
   //    }
   // />
);
