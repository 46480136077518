import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
// import {Link} from 'react-router-dom';
import LetteredAvatar from 'lettered-avatar';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

// import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
// import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
// import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
import Loader from '../../_helpers/loader';
import {branchActions} from '../../_actions';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {capitalizeFirstLetter} from '../../_helpers';

export function MarketerCustomers({match}) {
   const dispatch = useDispatch();
   const location = useLocation();
   const data = location.state;
   useEffect(() => {
      const {uuid} = match?.params;

      dispatch(branchActions.getMarketerCustomers(uuid));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const IsRequestingMarketerCustomers = useSelector(
      (s) => s.branch.IsRequestingMarketerCustomers,
   );
   const IsSearchingTransaction = useSelector(
      (s) => s.branch.IsSearchingTransaction,
   );
   const marketerCustomersList = useSelector(
      (s) => s.branch.marketerCustomersList,
   );
   // const {totalDocs, limit, page, totalPages} = useSelector(
   //    (s) => s.branch.pagination,
   // );
   // const total = totalDocs;
   // const pages = page - 1;

   // const handleChangePage = (event, pages) => {
   //    const model = {
   //       page: pages + 1,
   //    };
   //    const filterModel = {
   //       from: startDate,
   //       to: endDate,
   //       page: pages + 1,
   //    };
   //    dispatch(
   //       transactionActions.getAllTransactions(
   //          toDate === false ? model : filterModel,
   //       ),
   //    );
   // };

   // const [searchInput, setSearchInput] = useState('');

   // useEffect(() => {
   //    if (searchInput === '') {
   //       const {uuid} = match?.params;
   //       dispatch(branchActions.getMarketerCustomers(uuid));
   //    }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [searchInput]);

   // const [isOpen, setIsOpen] = useState(false);
   // const toggleList = () => {
   //    setIsOpen((wasOpen) => !wasOpen);
   // };

   // const [select, setSelect] = useState(false);
   console.log('data', data);
   return (
      <MDBRow className="admin-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search filter">
               <h5 className='font-weight-bold mb-2'>
                  {capitalizeFirstLetter(data?.firstname) +
                     ' ' +
                     capitalizeFirstLetter(data?.lastname)}
               </h5>
               <div className="d-flex">
                  <p><span className='font-weight-bold'>Serial:</span> {data?.serial}</p>
                  <p><span className='mx-3 font-weight-bold'>Phone number:</span> {data?.phone_number}</p>
               </div>
               {/* <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     value={searchInput}
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon className="icons i-x" style={{width: '1rem'}} />
                  <span className="i-search" onClick={searchTransaction}>
                     <SearchIcon style={{width: '1rem'}} />
                  </span>
               </div> */}
            </div>
            <div className="filter d-flex">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               {/* <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div> */}
            </div>
         </div>
         {/* {select && (
            <MDBInput
               className="mr-2"
               style={{width: '.65rem', height: '.65rem'}}
               type="checkbox"
               id="checkbox"
            />
         )} */}

         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Full Name</th>
                     <th scope="col">Email</th>
                     <th scope="col">Phone Number</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingMarketerCustomers || IsSearchingTransaction ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {marketerCustomersList?.length > 0 ? (
                        marketerCustomersList.map((row) => {
                           return (
                              <div
                                 // to={`/teams/marketers/${row._id}`}
                                 className="d-table-row">
                                 <th scope="row d-flex align-items-center justify-content-center">
                                    {/* {select && (
                                       <MDBInput
                                          className="mr-2"
                                          style={{
                                             width: '.65rem',
                                             height: '.65rem',
                                          }}
                                          type="checkbox"
                                          id="checkbox"
                                       />
                                    )} */}
                                    <LetteredAvatar
                                       name={row.firstname + ' ' + row.lastname}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass:
                                             'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>
                                       {row.firstname + ' ' + row.lastname}
                                    </span>
                                 </th>
                                 <td>{row.email}</td>
                                 <td>{row?.user_id?.phone_number}</td>
                                 <td>{row.achieved_loan}</td>
                                 {/* <td>{row.merchant_name}</td>
                                 <td>
                                    {capitalizeFirstLetter(
                                       row?.narration || 'None',
                                    )}
                                 </td>
                                 <td>{numberWithCommas(row.amount)}</td>
                                 <td>{readableDateString(row.created_at)}</td> */}
                              </div>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           No customer has been assigned to this marketer
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {/* {!IsRequestingAllTransactions && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )} */}
      </MDBRow>
   );
}
