import {transactionConstants} from '../_constants';

const getAllTransactions = (model) => ({
   type: transactionConstants.GET_ALL_TRANSACTIONS,
   data: model,
});

const searchTransaction = (model) => ({
   type: transactionConstants.SEARCH_TRANSACTION,
   data: model,
});

export const transactionActions = {
   getAllTransactions,
   searchTransaction,
};
