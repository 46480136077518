import {customerConstants} from '../_constants';

const getLoanCustomers = (model) => ({
   type: customerConstants.GET_LOAN_CUSTOMERS,
   data: model,
});

const getWalletCustomers = (model) => ({
   type: customerConstants.GET_WALLET_CUSTOMERS,
   data: model,
});

const getAllCustomers = (model) => ({
   type: customerConstants.GET_ALL_CUSTOMERS,
   data: model,
});

const getCustomerDetails = (model) => ({
   type: customerConstants.GET_CUSTOMER_DETAILS,
   data: model,
});

const getCustomerTransactionHistory = (model) => ({
   type: customerConstants.GET_CUSTOMER_TRANSACTION_HISTORY,
   data: model,
});

const getCustomerLoanHistory = (model) => ({
   type: customerConstants.GET_CUSTOMER_LOAN_HISTORY,
   data: model,
});

const getCustomerWalletHistory = (model) => ({
   type: customerConstants.GET_CUSTOMER_WALLET_HISTORY,
   data: model,
});

const changeUserStatus = (model) => ({
   type: customerConstants.CHANGE_USER_STATUS,
   data: model,
});

const searchCustomer = (model) => ({
   type: customerConstants.SEARCH_CUSTOMER,
   data: model,
});

export const customerActions = {
   getLoanCustomers,
   getWalletCustomers,
   getAllCustomers,
   getCustomerDetails,
   getCustomerTransactionHistory,
   getCustomerLoanHistory,
   getCustomerWalletHistory,
   changeUserStatus,
   searchCustomer,
};
