import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import './index.scss';
import './view.scss';

// import img from '../../_assets/icons/1custprofile.svg';
import img2 from '../../_assets/icons/1user-hash.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {
   numberWithCommas,
   readableDateString,
   capitalizeFirstLetter,
} from '../../_helpers';
import {customerActions} from '../../_actions';

export default function TransactionHistory({match}) {
   const dispatch = useDispatch();
   const IsRequestingCustomerTransactionHistory = useSelector(
      (s) => s.customer.IsRequestingCustomerTransactionHistory,
   );
   const customerTransactionList = useSelector(
      (s) => s.customer.customerTransactionList,
   );
   const pagination = useSelector((s) => s.customer.transactionPagination);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      const {uuid} = match.params;

      const model = {
         page: pages,
         uuid: uuid,
      };

      dispatch(customerActions.getCustomerWalletHistory(model));
   };

   return (
      <>
         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Amount</th>
                     <th scope="col">Topup Date</th>
                     <th scope="col">Balance</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingCustomerTransactionHistory ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {customerTransactionList?.length > 0 ? (
                        customerTransactionList.map((row) => {
                           return (
                              <div className="d-table-row">
                                 <td>{`N${numberWithCommas(row.amount)}`}</td>
                                 <td>{readableDateString(row.created_at)}</td>
                                 <td>{(row.new_balance)}</td>
                              </div>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           This customer has not performed any transaction on
                           this platform
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {!IsRequestingCustomerTransactionHistory && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </>
   );
}
