import {forgotPasswordConstants} from '../_constants';

const getResetPasswordLink = (model) => ({
   type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK,
   data: model,
});

const resetPassword = (model) => ({
   type: forgotPasswordConstants.RESET_PASSWORD,
   data: model,
});

export const forgotPasswordActions = {
   getResetPasswordLink,
   resetPassword,
};
