import {dashboardConstants} from '../../_constants';
import {combineReducers} from 'redux';
import {updateObject} from '../../_helpers';

const dashboardMetrics = (state = null, {type, dashboard}) => {
   switch (type) {
      case dashboardConstants.REQUEST_DASHBOARD_METRICS:
         return null;
      case dashboardConstants.REQUEST_DASHBOARD_METRICS_SUCCESS:
         return updateObject(state, dashboard);
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   dashboardMetrics,
});

export default rootReducer;
