import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import Formsy from 'formsy-react';
import Modal from 'react-modal';
import './index.scss';

import TextInput from '../../_components/TextInput';
import SharedBackground from '../../_components/SharedBackground';
import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
import {appActions, forgotPasswordActions} from '../../_actions';

export default function ResetPassword({match, history}) {
   const dispatch = useDispatch();
   const forgotPassword = useSelector((s) => s.forgotPassword);
   const user = useSelector((s) => s.user);
   const auth = useSelector((s) => s.auth);

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const timer = setTimeout(() => {
         dispatch(appActions.clearMessages());
         if (forgotPassword?.successMessage) {
            history.push('/login');
         }
      }, 2000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [forgotPassword?.successMessage]);

   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordError /* setPasswordError */] = useState('');
   const [confirmPasswordError, setConfirmPasswordError] = useState('');

   const handleSubmit = (data) => {
      const {token} = match.params;
      data['reset_token'] = token;
      if (data.new_password !== data.confirm_password) {
         setConfirmPasswordError('Passwords do not match');
      } else {
         delete data['confirm_password'];
         dispatch(forgotPasswordActions.resetPassword(data));
      }
   };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   if (auth.IsRequestingLogout) {
      return null;
   }

   const errorMessage = forgotPassword?.errorMessage?.[0];
   const successMessage = forgotPassword?.successMessage;
   // console.log('successMessage', successMessage);

   return (
      <SharedBackground>
         <MDBRow className="h-100 w-100 m-0 forgot-password-page">
            <div className="right d-flex align-items-center justify-content-center h-100 w-100">
               <Modal isOpen={successMessage !== ''} style={customStyles}>
                  <div className="content d-flex flex-column">
                     <AcctCredited
                        className="credited"
                        style={{width: '6rem'}}
                     />
                     <h3 className="m-0 d-flex justify-content-center py-2">
                        Password reset successfully!
                     </h3>
                     <p>{successMessage?.message}</p>
                  </div>
               </Modal>
               <Formsy
                  onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="__form px-md-4 px-3 py-4 d-flex flex-column">
                  <p className="error mb-3">{errorMessage?.msg}</p>
                  <TextInput
                     name="new_password"
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     type="password"
                     placeholder="********"
                     useFocusedError
                     labelText="New Password"
                     labelClassName="label"
                     className="input border-0 w-100"
                     validationError="Please provide your password!"
                     required
                  />
                  <p className="error mb-3">{passwordError}</p>
                  <TextInput
                     name="confirm_password"
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     type="password"
                     placeholder="********"
                     useFocusedError
                     labelText="Confirm New Password"
                     labelClassName="label"
                     className="input border-0 w-100"
                     validationError="Please provide your password!"
                     required
                  />
                  <p className="error mb-3">{confirmPasswordError}</p>
                  <button disabled={!canSubmit} className="d-block my-3 py-3">
                     {forgotPassword.isResettingPassword ? (
                        <div class="spinner-border text-white" role="status">
                           <span class="sr-only">Loading...</span>
                        </div>
                     ) : (
                        'Reset Password'
                     )}
                  </button>
                  <Link to="/" className="__link mb-3">
                     Already have an account?
                  </Link>
               </Formsy>
            </div>
         </MDBRow>
      </SharedBackground>
   );
}
