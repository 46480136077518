import {call, put, takeLatest, all} from 'redux-saga/effects';
import {userConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {
   createRequestWithToken,
   clearObjectFromStorage,
   setObjectInStorage,
   checkStatus,
   getObjectFromStorage,
   createRequest,
   parseJSON,
} from '../../_helpers';

function* getUserSaga() {
   try {
      yield put({type: userConstants.GET_REQUEST});

      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      if (user) {
         yield put({type: userConstants.GET_SUCCESS, user});
         return;
      }
      yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: userConstants.GET_FAILURE});
   } catch (error) {
      yield put({type: userConstants.GET_FAILURE});
   }
}

// function* getAllUsersSaga() {
//    try {
//       yield put({type: userConstants.REQUEST_ALL_USERS});

//       const allUsers = yield call(
//          getObjectFromStorage,
//          userConstants.ALL_ADMIN_USER_KEY,
//       );

//       if (allUsers) {
//          yield put({type: userConstants.REQUEST_ALL_USERS_SUCCESS, allUsers});
//          return;
//       }
//       yield call(clearObjectFromStorage, userConstants.ALL_ADMIN_USER_KEY);

//       yield put({type: userConstants.REQUEST_ALL_USERS_FAILURE});
//    } catch (error) {
//       yield put({type: userConstants.REQUEST_ALL_USERS_FAILURE});
//    }
// }

function* getAllAdminUsers({data}) {
   yield put({type: userConstants.REQUEST_ALL_ADMIN_USERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let allUsersUri = `${userConstants.GET_ADMIN_USER_URI}`;

      const req = createRequestWithToken(allUsersUri, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield call(
         setObjectInStorage,
         userConstants.ALL_ADMIN_USER_KEY,
         jsonResponse,
      );

      yield put({
         type: userConstants.REQUEST_ALL_ADMIN_USERS_SUCCESS,
         allAdminUsers: jsonResponse,
      });
   } catch (error) {
      yield put({type: userConstants.REQUEST_ALL_ADMIN_USERS_ERROR});
   }
}

function* getAdminUsers({data}) {
   yield put({type: userConstants.REQUEST_ADMIN_USERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let allUsersUri = `${userConstants.GET_ADMIN_USER_URI}`;

      if (data?.page) {
         allUsersUri = `${allUsersUri}?page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allUsersUri, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.REQUEST_ADMIN_USERS_SUCCESS,
         adminUsers: jsonResponse,
      });
   } catch (error) {
      yield put({type: userConstants.REQUEST_ADMIN_USERS_ERROR});
   }
}

function* exportUsers({data}) {
   yield put({type: userConstants.REQUEST_EXPORT_ALL_USERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let usersUri = `${userConstants.GET_ADMIN_USER_URI}/export`;

      const req = createRequestWithToken(usersUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user && user.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: userConstants.REQUEST_EXPORT_ALL_USERS_SUCCESS,
         userData: jsonResponse,
      });
   } catch (error) {
      yield put({type: userConstants.REQUEST_EXPORT_ALL_USERS_ERROR});
   }
}

function* registerAdminUser({data}) {
   yield put({type: userConstants.REQUEST_REGISTER_ADMIN_USER});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${userConstants.REGISTER_ADMIN_URI}`;
      const curriedReq = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.REGISTER_ADMIN_USER_SUCCESS,
         user: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'User successfully registered',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.REGISTER_ADMIN_USER_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* acceptInvitation({data}) {
   yield put({type: userConstants.REQUEST_ACCEPT_INVITATION});
   try {
      const url = `${userConstants.ADD_USER_TO_ADMIN_URI}/${data}`;

      const curriedReq = createRequestWithToken(url, {
         method: 'GET',
         // bo/dy: JSON.stringify(data),
      })(data);

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: userConstants.ACCEPT_INVITATION_SUCCESS,
         id: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.ACCEPT_INVITATION_ERROR,
         error: errorMessage,
      });
      // if (error.response) {
      //    const res = yield call(parseJSON, error.response);

      //    // if (res.errors) {
      //    //    let errors = {};
      //    //    for (let i = 0; i < res.errors.length; i++) {
      //    //       errors[res.errors[i].param] = res.errors[i].msg;
      //    //    }

      //    //    yield put({
      //    //       type: userConstants.ACCEPT_INVITATION_ERROR,
      //    //       error: errors,
      //    //    });
      //    //    return;
      //    // }

      //    yield put({
      //       type: userConstants.ACCEPT_INVITATION_ERROR,
      //       error: res.message,
      //    });

      //    return;
      // }

      // yield put({
      //    type: userConstants.ACCEPT_INVITATION_ERROR,
      //    error: error,
      // });
   }
}

function* createAdminUser({data}) {
   yield put({type: userConstants.REQUEST_CREATE_ADMIN_USER});

   try {
      // data['role'] = userConstants.MERCHANT_ROLE_ID;
      const url = `${userConstants.CREATE_ADMIN_USER_URI}/${data.token}`;
      const curriedReq = yield call(createRequest, url, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: userConstants.CREATE_ADMIN_USER_SUCCESS,
         id: jsonResponse.user_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: userConstants.CREATE_ADMIN_USER_ERROR,
               error: errors,
            });
            return;
         }

         yield put({
            type: userConstants.CREATE_ADMIN_USER_ERROR,
            error: res.message,
         });

         return;
      }

      yield put({
         type: userConstants.CREATE_ADMIN_USER_ERROR,
         error: error.message,
      });
   }
}

function* verifyUserBvn({data}) {
   yield put({type: userConstants.REQUEST_VERIFY_USER_BVN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${userConstants.GET_USER_URI}/${data._id}/bvn`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: data?.merchant
            ? userConstants.VERIFY_USER_BVN_SUCCESS
            : userConstants.VERIFY_CUSTOMER_USER_BVN_SUCCESS,
         userBvn: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'User BVN successfully verified',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.VERIFY_USER_BVN_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updateAdminUser({data}) {
   yield put({type: userConstants.REQUEST_UPDATE_ADMIN_USER});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${userConstants.GET_USER_URI}/update_admin/${data.id}`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.UPDATE_ADMIN_USER_SUCCESS,
         updatedUser: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'User successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.UPDATE_ADMIN_USER_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteAdminUser({_id}) {
   try {
      yield put({type: userConstants.REQUEST_DELETE_ADMIN_USER});
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${userConstants.GET_ADMIN_USER_URI}/${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(user && user.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      // const jsonResponse = yield call(response.json.bind(response))

      yield put({type: userConstants.DELETE_ADMIN_USER_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: 'Admin user successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.DELETE_ADMIN_USER_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchAdminUser({data}) {
   yield put({type: userConstants.REQUEST_SEARCH_ADMIN_USER});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let adminUri = `${userConstants.GET_ADMIN_USER_URI}/advanced_search`;

      const adminReq = createRequestWithToken(adminUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user && user.token);
      const response = yield call(fetch, adminReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: userConstants.SEARCH_ADMIN_USER_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.SEARCH_ADMIN_USER_SUCCESS,
         adminUser: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: userConstants.SEARCH_ADMIN_USER_ERROR,
         error: errorMessage,
      });
   }
}

function* getUserSagaWatcher() {
   yield takeLatest(userConstants.GET_USER, getUserSaga);
}

// function* getAllUsersSagaWatcher() {
//    yield takeLatest(userConstants.FETCH_ALL_USERS, getAllUsersSaga);
// }

function* exportUsersSagaWatcher() {
   yield takeLatest(userConstants.EXPORT_ADMIN_USERS, exportUsers);
}

function* getAllAdminUsersSagaWatcher() {
   yield takeLatest(userConstants.GET_ALL_ADMIN_USERS, getAllAdminUsers);
}

function* getAdminUsersSagaWatcher() {
   yield takeLatest(userConstants.GET_ADMIN_USERS, getAdminUsers);
}

function* registerAdminUserSagaWatcher() {
   yield takeLatest(userConstants.REGISTER_ADMIN_USER, registerAdminUser);
}

function* acceptInvitationSagaWatcher() {
   yield takeLatest(userConstants.ACCEPT_INVITATION, acceptInvitation);
}

function* createAdminUserSagaWatcher() {
   yield takeLatest(userConstants.CREATE_ADMIN_USER, createAdminUser);
}

function* verifyUserBvnSagaWatcher() {
   yield takeLatest(userConstants.VERIFY_USER_BVN, verifyUserBvn);
}

function* updateAdminUserSagaWatcher() {
   yield takeLatest(userConstants.UPDATE_ADMIN_USER, updateAdminUser);
}

function* deleteAdminUserSagaWatcher() {
   yield takeLatest(userConstants.DELETE_ADMIN_USER, deleteAdminUser);
}

function* searchAdminUserWatcher() {
   yield takeLatest(userConstants.SEARCH_ADMIN_USER, searchAdminUser);
}

export default function* rootSaga() {
   yield all([
      getUserSagaWatcher(),
      // getAllUsersSagaWatcher(),
      exportUsersSagaWatcher(),
      getAllAdminUsersSagaWatcher(),
      getAdminUsersSagaWatcher(),
      registerAdminUserSagaWatcher(),
      acceptInvitationSagaWatcher(),
      createAdminUserSagaWatcher(),
      verifyUserBvnSagaWatcher(),
      updateAdminUserSagaWatcher(),
      deleteAdminUserSagaWatcher(),
      searchAdminUserWatcher(),
   ]);
}
