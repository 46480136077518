import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {permissionConstants, userConstants} from '../../_constants';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
} from '../../_helpers';

function* getPermissions({data}) {
   yield put({type: permissionConstants.REQUEST_ALL_PERMISSIONS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let permissionsUri = `${permissionConstants.PERMISSION_URI}?type=${data.pagination}`;

      if (data?.page) {
         permissionsUri = `${permissionsUri}&page=${data.page + 1}`;
      }

      const permissionsReq = createRequestWithToken(permissionsUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, permissionsReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type:
            data.pagination === 'no-paginate'
               ? permissionConstants.REQUEST_ALL_PERMISSIONS_WITHOUT_PAGINATION_SUCCESS
               : permissionConstants.REQUEST_ALL_PERMISSIONS_SUCCESS,
         permissions: jsonResponse,
      });
   } catch (error) {
      yield put({type: permissionConstants.REQUEST_ALL_PERMISSIONS_ERROR});
   }
}

function* createPermissionSaga({data}) {
   try {
      yield put({type: permissionConstants.REQUEST_CREATE_PERMISSION});
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${permissionConstants.PERMISSION_URI}`;
      const req = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: permissionConstants.CREATE_PERMISSION_SUCCESS,
         permission: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'permission successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: permissionConstants.CREATE_PERMISSION_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updatePermissionSaga({data}) {
   try {
      yield put({type: permissionConstants.REQUEST_UPDATE_PERMISSION});
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${permissionConstants.PERMISSION_URI}${data.id}`;
      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: permissionConstants.UPDATE_PERMISSION_SUCCESS,
         permission: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'permission successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: permissionConstants.UPDATE_PERMISSION_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deletePermissionSaga({_id}) {
   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      yield put({type: permissionConstants.REQUEST_DELETE_PERMISSION});

      const url = `${permissionConstants.PERMISSION_URI}${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: permissionConstants.DELETE_PERMISSION_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'permission successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: permissionConstants.DELETE_PERMISSION_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getPermissionsWatcher() {
   yield takeLatest(permissionConstants.GET_ALL_PERMISSIONS, getPermissions);
}

function* createPermissionWatcher() {
   yield takeLatest(
      permissionConstants.CREATE_PERMISSION,
      createPermissionSaga,
   );
}

function* updatePermissionWatcher() {
   yield takeLatest(
      permissionConstants.UPDATE_PERMISSION,
      updatePermissionSaga,
   );
}

function* deletePermissionWatcher() {
   yield takeLatest(
      permissionConstants.DELETE_PERMISSION,
      deletePermissionSaga,
   );
}

export default function* rootSaga() {
   yield all([
      getPermissionsWatcher(),
      createPermissionWatcher(),
      updatePermissionWatcher(),
      deletePermissionWatcher(),
   ]);
}
