// const base_uri = 'https://buyandgo-api.waystream.io/api/v1/'; //test
const base_uri = 'https://api.buyandgo.ng/api/v1/'; //prod

export const appConstants = {
   BASE_URI: base_uri,

   BANK_KEY: 'bank',

   CLEAR_SNACKBAR: 'CLEAR_SNACKBAR',
   SET_SNACKBAR: 'SET_SNACKBAR',
   CLEAR_MESSAGES: 'CLEAR_MESSAGES',

   BANK_URL: `${base_uri}bank/AllAccounts`,
};
