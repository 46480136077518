import {combineReducers} from 'redux';
import {permissionConstants, appConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingPermissions = (state = false, action) => {
   switch (action.type) {
      case permissionConstants.REQUEST_ALL_PERMISSIONS:
         return true;
      case permissionConstants.REQUEST_ALL_PERMISSIONS_ERROR:
         return false;
      case permissionConstants.REQUEST_ALL_PERMISSIONS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingPermission = (state = false, action) => {
   switch (action.type) {
      case permissionConstants.REQUEST_CREATE_PERMISSION:
         return true;
      case permissionConstants.CREATE_PERMISSION_SUCCESS:
         return false;
      case permissionConstants.CREATE_PERMISSION_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingPermission = (state = false, action) => {
   switch (action.type) {
      case permissionConstants.REQUEST_UPDATE_PERMISSION:
         return true;
      case permissionConstants.UPDATE_PERMISSION_SUCCESS:
         return false;
      case permissionConstants.UPDATE_PERMISSION_ERROR:
         return false;
      default:
         return state;
   }
};

const permissionCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case permissionConstants.REQUEST_CREATE_PERMISSION:
         return false;
      case permissionConstants.CREATE_PERMISSION_SUCCESS:
         return true;
      case permissionConstants.CREATE_PERMISSION_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const permissionUpdatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case permissionConstants.REQUEST_UPDATE_PERMISSION:
         return false;
      case permissionConstants.UPDATE_PERMISSION_SUCCESS:
         return true;
      case permissionConstants.UPDATE_PERMISSION_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const permissionList = (state = [], action) => {
   switch (action.type) {
      case permissionConstants.REQUEST_ALL_PERMISSIONS_SUCCESS: {
         return action.permissions.data;
      }
      case permissionConstants.REQUEST_ALL_PERMISSIONS_WITHOUT_PAGINATION_SUCCESS: {
         return action.permissions.data;
      }
      case permissionConstants.CREATE_PERMISSION_SUCCESS: {
         const newList = [...state, action.permission.data];

         return newList;
      }
      case permissionConstants.UPDATE_PERMISSION_SUCCESS: {
         const newList = updateItemInArray(
            state,
            action.permission.data._id,
            true,
            (permission) => {
               return updateObject(permission, action.permission.data);
            },
         );

         return newList;
      }
      case permissionConstants.DELETE_PERMISSION_SUCCESS: {
         return state.filter((permission) => permission._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case permissionConstants.CREATE_PERMISSION_ERROR:
         return updateObject(state, error);
      case permissionConstants.REQUEST_CREATE_PERMISSION:
         return null;
      case permissionConstants.UPDATE_PERMISSION_ERROR:
         return updateObject(state, error);
      case permissionConstants.REQUEST_UPDATE_PERMISSION:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      // case permissionConstants.REQUEST_ALL_PERMISSIONS_SUCCESS: {
      //    const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
      //       action.permissions.permissions;
      //    const result = {
      //       hasNextPage,
      //       nextPage,
      //       totalDocs,
      //       totalPages,
      //       limit,
      //       page,
      //    };

      //    return updateObject(state, result);
      // }
      case permissionConstants.CREATE_PERMISSION_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = state;
         const TotalDocs = totalDocs + 1;
         const result = {hasNextPage, nextPage, TotalDocs, limit, page};

         return updateObject(state, result);
      }
      case permissionConstants.DELETE_PERMISSION_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = state;
         const TotalDocs = totalDocs - 1;
         const result = {hasNextPage, nextPage, TotalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   permissionList,
   IsRequestingPermissions,
   IsCreatingPermission,
   IsUpdatingPermission,
   permissionCreatedSuccessfully,
   permissionUpdatedSuccessfully,
   errorMessage,
   pagination,
});

export default rootReducer;
