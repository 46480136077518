import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
// import {Link} from 'react-router-dom';
// import classNames from 'classnames';
// import LetteredAvatar from 'lettered-avatar';
import DatePicker from 'react-datepicker';
import './index.scss';

// import img from '../../_assets/icons/1user-hash.svg';
// import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
// import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
// import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {
   numberWithCommas,
   readableDateString,
   capitalizeFirstLetter,
} from '../../_helpers';
import {settlementActions} from '../../_actions';
import { CSVLink } from 'react-csv';

export default function Settlement() {
   const dispatch = useDispatch();

   useEffect(() => {
      const model = {
         type: 'settlement',
      };
      dispatch(settlementActions.getSuccessfulSettlements(model));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const IsRequestingSuccessfulSettlements = useSelector(
      (s) => s.settlement.IsRequestingSuccessfulSettlements,
   );
   const IsSearchingSettlement = useSelector(
      (s) => s.settlement.IsSearchingSettlement,
   );
   const successfulSettlementsList = useSelector(
      (s) => s.settlement.successfulSettlementsList,
   );
   const {totalDocs, limit, page, totalPages} = useSelector(
      (s) => s.settlement.pagination,
   );
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      if (toDate) {
         const model = {
            from: startDate,
            to: endDate,
            page: pages,
            type: 'settlement',
         };
         dispatch(settlementActions.getSuccessfulSettlements(model));
      } else {
         const model = {
            page: pages,
            type: 'settlement',
         };
         dispatch(settlementActions.getSuccessfulSettlements(model));
      }
   };
   const [shown /* å */] = useState(1);
   const [searchInput, setSearchInput] = useState('');
   const [toDate, setToDate] = useState(false);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());

   // const searchSettlement = () => {
   //    const model = {
   //       searchName: searchInput,
   //       type: 2,
   //    };
   //    dispatch(settlementActions.searchSettlement(model));
   // };

   // const clearText = () => {
   //    // setIsDateOpen(true);
   //    setSearchInput('');
   // };

   useEffect(() => {
      if (searchInput === '') {
         const model = {
            type: 'settlement',
         };
         dispatch(settlementActions.getSuccessfulSettlements(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchInput]);

   useEffect(() => {
      if (!toDate) {
         const model = {
            type: 'settlement',
         };
         dispatch(settlementActions.getSuccessfulSettlements(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [toDate]);

   useEffect(() => {
      if (toDate) {
         const model = {
            from: startDate,
            to: endDate,
            type: 'settlement',
         };
         dispatch(settlementActions.getSuccessfulSettlements(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [endDate]);

   // const handleFilter = () => {
   //    const model = {
   //       from: startDate,
   //       to: endDate,
   //       type: 'settlement',
   //    };
   //    dispatch(settlementActions.getSuccessfulSettlements(model));
   // };

   const [isOpen, setIsOpen] = useState(false);
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
   };

   // const [isStatusOpen, setIsStatusOpen] = useState(false);
   // const toggleStatusList = () => {
   //    setIsStatusOpen((wasOpen) => !wasOpen);
   //    setSelect((wasOpen) => !wasOpen);
   // };
   // const [select, setSelect] = useState(false);

   // const [isDateOpen, setIsDateOpen] = useState(false);
   // const toggleDate = () => {
   //    // setIsDateOpen(true);
   //    setIsDateOpen((wasOpen) => !wasOpen);
   // };

   // const [selected, setSelected] = useState(0);
   // const options = [
   //    {label: 'Status', val: 0},
   //    {label: 'Paid', val: 1},
   //    {label: 'Owing', val: 2},
   // ];

   return (
      <MDBRow className="settlement-page m-0 w-100 mb-5">
         {/* <div className="history d-flex">
            <ul className="d-flex">
               <li>
                  <div
                     onClick={() => setShown(1)}
                     className={classNames('w-100 d-block', {
                        active: shown === 1,
                     })}>
                     Successful Settlement
                  </div>
               </li>
               <li>
                  <div
                     onClick={() => setShown(2)}
                     className={classNames('w-100 d-block', {
                        active: shown === 2,
                     })}>
                     Failed Settlement
                  </div>
               </li>
            </ul>
         </div> */}
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search filter">
               {
                  isOpen ? (
                     <div className="collaps">
                        <div
                           // onClick={toggleDate}
                           className="collaps-item mr-5 position-relation">
                           From Date Range:
                           {/* <ArrDown className="ml-4" /> */}
                           <DatePicker
                              selected={startDate}
                              onSelect={() => setToDate(true)}
                              onChange={(date) => setStartDate(date)}
                           />
                        </div>
                        {toDate && (
                           <div
                              // onClick={toggleDate}
                              className="collaps-item mr-5 position-relation">
                              To Date Range:
                              <DatePicker
                                 selected={endDate}
                                 // onSelect={handleFilter}
                                 onChange={(date) => setEndDate(date)}
                              />
                           </div>
                        )}
                     </div>
                  ) : null
                  // <div className="input-group d-flex align-items-center">
                  //    <input
                  //       type="text"
                  //       className="for"
                  //       placeholder="Search"
                  //       aria-label="Search"
                  //       value={searchInput}
                  //       onChange={(e) => setSearchInput(e.target.value)}
                  //    />
                  //    <XIcon
                  //       className="icons i-x"
                  //       style={{width: '1rem'}}
                  //       onClick={clearText}
                  //    />
                  //    <span className="i-search" onClick={searchSettlement}>
                  //       <SearchIcon style={{width: '1rem'}} />
                  //    </span>
                  // </div>
               }
            </div>
            <div className="filter d-flex">
            <CSVLink data={successfulSettlementsList} className="csv">
                  Download CSV
               </CSVLink>
               <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div>
            </div>
         </div>

         {/* {select && (
            <MDBInput
               className="mr-2"
               style={{width: '.65rem', height: '.65rem'}}
               type="checkbox"
               id="checkbox"
            />
         )} */}

         {shown === 1 && (
            <div className="customer-list mt-3 w-100">
               <MDBTable
                  className="profile"
                  responsive
                  borderless
                  hover
                  align="middle">
                  <MDBTableHead>
                     <tr>
                        {/* <th scope="col">Merchant Name</th> */}
                        {/* <th scope="col">Email</th> */}
                        <th scope="col">Amount</th>
                        <th scope="col">Narration</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                     </tr>
                  </MDBTableHead>
                  {IsRequestingSuccessfulSettlements ||
                  IsSearchingSettlement ? (
                     <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                        <div />
                     </Loader>
                  ) : (
                     <MDBTableBody>
                        {successfulSettlementsList?.length > 0 ? (
                           successfulSettlementsList.map((row) => {
                              return (
                                 <div className="d-table-row">
                                    {/* <th scope="row d-flex align-items-center justify-content-center"> */}
                                    {/* {select && (
                                          <MDBInput
                                             className="mr-2"
                                             style={{
                                                width: '.65rem',
                                                height: '.65rem',
                                             }}
                                             type="checkbox"
                                             id="checkbox"
                                          />
                                       )} */}
                                    {/* <LetteredAvatar
                                          name={row.merchant_name}
                                          options={{
                                             size: 50,
                                             twoLetter: true,
                                             //shape: 'square',
                                             bgColor: '',
                                             imgClass:
                                                'image-responsive user-image',
                                             imgWidth: '3rem',
                                             imgHeight: '3rem',
                                          }}
                                       />
                                       <span>{row.sender_full_name}</span>
                                    </th> */}
                                    {/* <td>
                                       {row.sender_phone?.replace(
                                          /^.{3}/g,
                                          '0',
                                       )}
                                    </td> */}
                                    <td>
                                       ₦{numberWithCommas(row.data[0].amount)}
                                    </td>
                                    <td>
                                       {capitalizeFirstLetter(
                                          row.data[0].narration,
                                       )}
                                    </td>
                                    <td>
                                       {capitalizeFirstLetter(
                                          row.data[0].status,
                                       )}
                                    </td>
                                    <td>
                                       {readableDateString(
                                          row.data[0].created_at,
                                       )}
                                    </td>
                                 </div>
                              );
                           })
                        ) : (
                           <td align="center" colSpan={5}>
                              No merchant has made settlement on this platform
                           </td>
                        )}
                     </MDBTableBody>
                  )}
                  {/* <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>150,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>50,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>100.00</td>
                        <td>12/12/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>150,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>50,000</td>
                        <td>12/12/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>20,000</td>
                        <td>12/12/2021</td>
                     </div> */}
               </MDBTable>
            </div>
         )}

         {/* {shown === 2 && (
            <div className="customer-list mt-3 w-100">
               <MDBTable
                  className="profile"
                  responsive
                  borderless
                  hover
                  align="middle">
                  <MDBTableHead>
                     <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Date</th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>40,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>150,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>50,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>100.00</td>
                        <td>12/12/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>150,000</td>
                        <td>04/06/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>50,000</td>
                        <td>12/12/2021</td>
                     </div>
                     <div className="d-table-row">
                        <th scope="row d-flex align-items-center justify-content-center">
                           {select && (
                              <MDBInput
                                 className="mr-2"
                                 style={{width: '.65rem', height: '.65rem'}}
                                 type="checkbox"
                                 id="checkbox"
                              />
                           )}
                           <img
                              src={img}
                              alt="profile-img"
                              className="rounded-circle img-fluid"
                              style={{width: '3rem', height: '3rem'}}
                           />
                           <span>Emeka Chuks</span>
                        </th>
                        <td>emeka@gmail.com</td>
                        <td>07036334745</td>
                        <td>20,000</td>
                        <td>12/12/2021</td>
                     </div>
                  </MDBTableBody>
               </MDBTable>
            </div>
         )} */}

         {!IsRequestingSuccessfulSettlements && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </MDBRow>
   );
}
