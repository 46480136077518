import {appConstants} from './app.constant';

const permission = 'permission';

export const permissionConstants = {
   REQUEST_CREATE_PERMISSION: 'REQUEST_CREATE_PERMISSION',
   CREATE_PERMISSION_SUCCESS: 'CREATE_PERMISSION_SUCCESS',
   CREATE_PERMISSION_ERROR: 'CREATE_PERMISSION_ERROR',

   REQUEST_ALL_PERMISSIONS: 'REQUEST_ALL_PERMISSIONS',
   REQUEST_ALL_PERMISSIONS_SUCCESS: 'REQUEST_ALL_PERMISSIONS_SUCCESS',
   REQUEST_ALL_PERMISSIONS_WITHOUT_PAGINATION_SUCCESS:
      'REQUEST_ALL_PERMISSIONS_WITHOUT_PAGINATION_SUCCESS',
   REQUEST_ALL_PERMISSIONS_ERROR: 'REQUEST_ALL_PERMISSIONS_ERROR',

   REQUEST_DELETE_PERMISSION: 'REQUEST_DELETE_PERMISSION',
   DELETE_PERMISSION_SUCCESS: 'DELETE_PERMISSION_SUCCESS',
   DELETE_PERMISSION_ERROR: 'DELETE_PERMISSION_ERROR',

   REQUEST_UPDATE_PERMISSION: 'REQUEST_UPDATE_PERMISSION',
   UPDATE_PERMISSION_SUCCESS: 'UPDATE_PERMISSION_SUCCESS',
   UPDATE_PERMISSION_ERROR: 'UPDATE_PERMISSION_ERROR',

   GET_ALL_PERMISSIONS: 'GET_ALL_PERMISSIONS',
   CREATE_PERMISSION: 'CREATE_PERMISSION',
   DELETE_PERMISSION: 'DELETE_PERMISSION',
   UPDATE_PERMISSION: 'UPDATE_PERMISSION',

   PERMISSION_URI: `${appConstants.BASE_URI}${permission}/`,
};
