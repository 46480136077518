import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import Formsy from 'formsy-react';
import './index.scss';

import TextInput from '../../_components/TextInput';
import SharedBackground from '../../_components/SharedBackground';
import {appActions, userActions} from '../../_actions';

export default function SetPassword({history, location}) {
   const dispatch = useDispatch();
   const auth = useSelector((s) => s.auth);

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [canSubmit, setCanSubmit] = useState(false);
   const [passError, setPassError] = useState('');

   const handleSubmit = (data) => {
      data['id'] = auth.emailVerifiedId;

      if (data.confirm_new_password === data.new_password) {
         delete data['confirm_new_password'];
         dispatch(userActions.setPassword(data));
      } else {
         setPassError('Passwords do not match');
      }
   };

   const clearError = () => {
      dispatch(appActions.clearMessages());
   };

   if (auth.passwordIsSetSuccessful) {
      history.push('/login');
   }

   const errorMessage = auth?.errorMessage;
   const passwordError = errorMessage?.errors?.filter(
      (error) => error.param === 'password',
   );

   return (
      <SharedBackground>
         <MDBRow className="h-100 w-100 m-0 log-page">
            <div className="right d-flex align-items-center justify-content-center h-100 w-100">
               <Formsy
                  onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="__form px-md-4 px-3 py-4 d-flex flex-column">
                  <h4 className="text-center">SET PASSWORD</h4>
                  <p className="error mb-3">{errorMessage?.message}</p>
                  <TextInput
                     name="old_password"
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     type="password"
                     placeholder="********"
                     useFocusedError
                     labelText="Temporary Password"
                     labelClassName="label"
                     className="input border-0 w-100"
                     validationError="Please provide your password!"
                     required
                     clearError={clearError}
                  />
                  <TextInput
                     name="new_password"
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     type="password"
                     placeholder="********"
                     useFocusedError
                     labelText="New Password"
                     labelClassName="label"
                     className="input border-0 w-100"
                     validationError="Please provide your password!"
                     required
                     clearError={clearError}
                  />
                  <TextInput
                     name="confirm_new_password"
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     type="password"
                     placeholder="********"
                     useFocusedError
                     labelText="Confirm New Password"
                     labelClassName="label"
                     className="input border-0 w-100"
                     validationError="Please provide your password!"
                     required
                     clearError={clearError}
                  />
                  <p style={{color: 'red'}}>{passError}</p>
                  <p className="error mb-3">
                     {passwordError && passwordError[0] && passwordError[0].msg}
                  </p>
                  {/* <Link to="/forgot-password" className="__link mb-3">
                     Forgot password?
                  </Link> */}
                  <button disabled={!canSubmit} className="d-block my-3 py-3">
                     {auth.IsSettingPassword ? (
                        <div class="spinner-border text-white" role="status">
                           <span class="sr-only">Loading...</span>
                        </div>
                     ) : (
                        'Set Password'
                     )}
                  </button>
               </Formsy>
            </div>
         </MDBRow>
      </SharedBackground>
   );
}
