import React from 'react';
import PropTypes from 'prop-types';
import './loader.css';

const Loader = (props) => {
   return (
      <div className="d-flex justify-content-center w-100">
         <div className={props.className} style={props.style}>
            {props.children}
         </div>
      </div>
   );
};
Loader.propTypes = {
   className: PropTypes.string,
   style: PropTypes.object,
};

export default Loader;
