import {combineReducers} from 'redux';
import {branchConstants, appConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingBranches = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_ALL_BRANCHES:
         return true;
      case branchConstants.REQUEST_ALL_BRANCHES_ERROR:
         return false;
      case branchConstants.REQUEST_ALL_BRANCHES_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingBranch = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_CREATE_BRANCH:
         return true;
      case branchConstants.CREATE_BRANCH_SUCCESS:
         return false;
      case branchConstants.CREATE_BRANCH_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingBranch = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_UPDATE_BRANCH:
         return true;
      case branchConstants.UPDATE_BRANCH_SUCCESS:
         return false;
      case branchConstants.UPDATE_BRANCH_ERROR:
         return false;
      default:
         return state;
   }
};

const IsSearchingBranch = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_SEARCH_BRANCH:
         return true;
      case branchConstants.SEARCH_BRANCH_SUCCESS:
         return false;
      case branchConstants.SEARCH_BRANCH_ERROR:
         return false;
      case branchConstants.SEARCH_BRANCH_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingBranchMarketers = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_BRANCH_MARKETERS:
         return true;
      case branchConstants.REQUEST_BRANCH_MARKETERS_ERROR:
         return false;
      case branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS:
         return false;
      case branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsCreatingMarketer = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_CREATE_MARKETER:
         return true;
      case branchConstants.CREATE_MARKETER_SUCCESS:
         return false;
      case branchConstants.CREATE_MARKETER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsRequestingMarketerCustomers = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_MARKETER_CUSTOMERS:
         return true;
      case branchConstants.REQUEST_MARKETER_CUSTOMERS_ERROR:
         return false;
      case branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS:
         return false;
      case branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const branchCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_CREATE_BRANCH:
         return false;
      case branchConstants.CREATE_BRANCH_SUCCESS:
         return true;
      case branchConstants.CREATE_BRANCH_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const branchUpdatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_UPDATE_BRANCH:
         return false;
      case branchConstants.UPDATE_BRANCH_SUCCESS:
         return true;
      case branchConstants.UPDATE_BRANCH_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};
const marketerCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case branchConstants.REQUEST_CREATE_MARKETER:
         return false;
      case branchConstants.CREATE_MARKETER_SUCCESS:
         return true;
      case branchConstants.CREATE_MARKETER_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const branchesList = (state = [], action) => {
   switch (action.type) {
      case branchConstants.REQUEST_ALL_BRANCHES_SUCCESS: {
         return action.branches.data;
      }
      case branchConstants.CREATE_BRANCH_SUCCESS: {
         const newList = [...state, action.branch.data];

         return newList;
      }
      case branchConstants.SEARCH_BRANCH_SUCCESS: {
         return action.branch.data.docs;
      }
      case branchConstants.SEARCH_BRANCH_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case branchConstants.UPDATE_BRANCH_SUCCESS: {
         const newList = updateItemInArray(
            state,
            action.branch.data._id,
            true,
            (branch) => {
               return updateObject(branch, action.branch.data);
            },
         );

         return newList;
      }
      case branchConstants.DELETE_BRANCH_SUCCESS: {
         return state.filter((branch) => branch._id !== action._id);
      }
      default:
         return state;
   }
};

const branchMarketersList = (state = [], action) => {
   switch (action.type) {
      case branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS: {
         return action.branchMarketers.marketers;
      }
      case branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case branchConstants.CREATE_MARKETER_SUCCESS: {
         const newList = [...state, action.marketers.marketer];

         return newList;
      }
      case branchConstants.SEARCH_BRANCH_MARKETER_SUCCESS: {
         return action.branch;
      }
      case branchConstants.SEARCH_BRANCH_MARKETER_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const marketerCustomersList = (state = [], action) => {
   switch (action.type) {
      case branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS: {
         return action.marketerCustomers.customers;
      }
      case branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      // case branchConstants.SEARCH_BRANCH_MARKETER_SUCCESS: {
      //    return action.branch;
      // }
      // case branchConstants.SEARCH_BRANCH_MARKETER_SUCCESS_WITHOUT_DATA:
      //    return updateObject(state, emptyState);
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case branchConstants.CREATE_BRANCH_ERROR:
         return updateObject(state, error);
      case branchConstants.REQUEST_CREATE_BRANCH:
         return null;
      case branchConstants.CREATE_MARKETER_ERROR:
         return updateObject(state, error);
      case branchConstants.REQUEST_CREATE_MARKETER:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

// const pagination = (state = {}, action) => {
//    switch (action.type) {
//       case branchConstants.REQUEST_ALL_BRANCHES_SUCCESS: {
//          const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
//             action.branchs.branchs;
//          const result = {
//             hasNextPage,
//             nextPage,
//             totalDocs,
//             totalPages,
//             limit,
//             page,
//          };

//          return updateObject(state, result);
//       }
//       case branchConstants.CREATE_BRANCH_SUCCESS: {
//          const {hasNextPage, nextPage, totalDocs, limit, page} = state;
//          const TotalDocs = totalDocs + 1;
//          const result = {hasNextPage, nextPage, TotalDocs, limit, page};

//          return updateObject(state, result);
//       }
//       default:
//          return state;
//    }
// };

const rootReducer = combineReducers({
   branchesList,
   IsRequestingBranches,
   IsRequestingBranchMarketers,
   IsRequestingMarketerCustomers,
   branchMarketersList,
   marketerCustomersList,
   IsSearchingBranch,
   IsCreatingBranch,
   IsUpdatingBranch,
   IsCreatingMarketer,
   branchCreatedSuccessfully,
   branchUpdatedSuccessfully,
   marketerCreatedSuccessfully,
   errorMessage,
   // pagination,
});

export default rootReducer;
