import {call, put, takeLatest, all} from 'redux-saga/effects';
import {
   appConstants,
   customerConstants,
   loanConstants,
   userConstants,
} from '../../_constants';
import {appActions} from '../../_actions';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
} from '../../_helpers';

function* getAllLoanRequests({data}) {
   yield put({type: loanConstants.REQUEST_ALL_LOAN_REQUESTS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let loansUri = `${loanConstants.LOAN_URI}requested`;

      if (data?.page) {
         loansUri = `${loansUri}?page=${data.page + 1}`;
      }

      const loansReq = createRequestWithToken(loansUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, loansReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: loanConstants.REQUEST_ALL_LOAN_REQUESTS_SUCCESS,
         loanRequests: jsonResponse,
      });
   } catch (error) {
      yield put({type: loanConstants.REQUEST_ALL_LOAN_REQUESTS_ERROR});
   }
}
function* getAllLoans({data}) {
   yield put({type: loanConstants.REQUEST_ALL_LOAN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let loansUri = `${loanConstants.LOAN_SUMMARY_URI}`;

      if (data?.page) {
         loansUri = `${loansUri}?page=${data.page + 1}`;
      }

      const loansReq = createRequestWithToken(loansUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, loansReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: loanConstants.REQUEST_ALL_LOAN_SUCCESS,
         loanSummary: jsonResponse,
      });
   } catch (error) {
      yield put({type: loanConstants.REQUEST_ALL_LOAN_ERROR});
   }
}

function* getLoanDetails({data}) {
   yield put({type: loanConstants.REQUEST_LOAN_DETAILS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const detailsUrl = `${appConstants.BASE_URI}loan/${data}`;

      const req = createRequestWithToken(detailsUrl, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response, loanConstants.LOAN_KEY);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: loanConstants.REQUEST_LOAN_DETAILS_SUCCESS,
         loanDetails: jsonResponse,
      });
   } catch (error) {
      yield put({type: loanConstants.REQUEST_LOAN_DETAILS_ERROR});
   }
}

function* verifyLoan({data}) {
   yield put({type: loanConstants.REQUEST_VERIFY_LOAN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${appConstants.BASE_URI}loan/${data}/verify`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         // body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: loanConstants.REQUEST_VERIFY_LOAN_SUCCESS,
         verifiedLoan: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'Loan successfully verified',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: loanConstants.REQUEST_VERIFY_LOAN_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* settleLoan({data}) {
   yield put({type: loanConstants.REQUEST_SETTLE_LOAN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${appConstants.BASE_URI}loan/direct_settle/${data.id}`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: loanConstants.REQUEST_SETTLE_LOAN_SUCCESS,
         settledLoan: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'Loan successfully settled',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: loanConstants.REQUEST_SETTLE_LOAN_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* grantLoan({data}) {
   yield put({type: loanConstants.REQUEST_GRANT_LOAN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      // const url = `${appConstants.BASE_URI}loan/update_loan_details/${data.user_id}`;
      const url = `${appConstants.BASE_URI}loan/${data._id}/details`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: loanConstants.REQUEST_GRANT_LOAN_SUCCESS,
         loan: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'Loan successfully granted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: loanConstants.REQUEST_GRANT_LOAN_ERROR,
         error: errorMessage,
      });
      // yield put(
      //    appActions.setSnackBar({
      //       message: errorMessage.message
      //          ? errorMessage.message
      //          : 'Something went wrong',
      //       variant: 'error',
      //    }),
      // );
   }
}

function* declineLoan({data}) {
   yield put({type: loanConstants.REQUEST_DECLINE_LOAN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      // const url = `${appConstants.BASE_URI}loan/update_loan_details/${data.user_id}`;
      const url = `${appConstants.BASE_URI}loan/${data}/reject`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         // body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: loanConstants.REQUEST_DECLINE_LOAN_SUCCESS,
         loan: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'Loan successfully declined',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: loanConstants.REQUEST_DECLINE_LOAN_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getAllLoanGranted({data}) {
   yield put({type: loanConstants.REQUEST_ALL_LOAN_GRANTED});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let loansUri = `${loanConstants.LOAN_URI}granted`;

      if (data?.page) {
         loansUri = `${loansUri}?page=${data.page + 1}`;
      }

      const loansReq = createRequestWithToken(loansUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, loansReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: loanConstants.REQUEST_ALL_LOAN_GRANTED_SUCCESS,
         loanGranted: jsonResponse,
      });
   } catch (error) {
      yield put({type: loanConstants.REQUEST_ALL_LOAN_GRANTED_ERROR});
   }
}

function* searchLoan({data}) {
   yield put({type: loanConstants.REQUEST_SEARCH_LOAN});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let loansUri = `${customerConstants.ALL_CUSTOMERS_URI}/search/loan`;
      const loansReq = createRequestWithToken(loansUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);
      // if (data?.page) {  /${data.searchName}?type=${data.type}
      //    loansUri = `${loansUri}?page=${data.page + 1}`;
      // }

      // const loansReq = createRequestWithToken(loansUri, {
      //    method: 'GET',
      // })(user?.token);

      const response = yield call(fetch, loansReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: loanConstants.SEARCH_LOAN_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type:
            data.type === 'requested'
               ? loanConstants.SEARCH_LOAN_REQUEST_SUCCESS
               : loanConstants.SEARCH_LOAN_GRANTED_SUCCESS,
         loans: jsonResponse,
      });
   } catch (error) {
      yield put({type: loanConstants.SEARCH_LOAN_ERROR});
   }
}

function* getAllLoanRequestsWatcher() {
   yield takeLatest(loanConstants.GET_ALL_LOAN_REQUEST, getAllLoanRequests);
}
function* getAllLoansWatcher() {
   yield takeLatest(loanConstants.GET_ALL_LOAN, getAllLoans);
}

function* getLoanDetailsWatcher() {
   yield takeLatest(loanConstants.GET_LOAN_DETAILS, getLoanDetails);
}

function* getAllLoanGrantedWatcher() {
   yield takeLatest(loanConstants.GET_ALL_LOAN_GRANTED, getAllLoanGranted);
}

function* verifyLoanWatcher() {
   yield takeLatest(loanConstants.VERIFY_LOAN, verifyLoan);
}
function* settleLoanWatcher() {
   yield takeLatest(loanConstants.SETTLE_LOAN, settleLoan);
}

function* grantLoanWatcher() {
   yield takeLatest(loanConstants.GRANT_LOAN, grantLoan);
}

function* declineLoanWatcher() {
   yield takeLatest(loanConstants.DECLINE_LOAN, declineLoan);
}
function* searchLoanWatcher() {
   yield takeLatest(loanConstants.SEARCH_LOAN, searchLoan);
}

export default function* rootSaga() {
   yield all([
      getAllLoanRequestsWatcher(),
      getAllLoansWatcher(),
      getLoanDetailsWatcher(),
      getAllLoanGrantedWatcher(),
      verifyLoanWatcher(),
      settleLoanWatcher(),
      grantLoanWatcher(),
      declineLoanWatcher(),
      searchLoanWatcher(),
   ]);
}
