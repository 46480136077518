import React, {useEffect, useState} from 'react';
// import {MDBRow} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
// import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import './index.scss';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
// import Select from '@material-ui/core/Select';
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';

// import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
// import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';
// import {ReactComponent as ArrowDown} from '../../_assets/icons/1arrow-down.svg';
// import {isActive} from '../../_constants';
// import {merchantConstants, customerConstants, isActive} from '../../_constants';
import {appActions, loanActions} from '../../_actions';

export default function SettleDefaultForm({loanData}) {
   const dispatch = useDispatch();

   useEffect(() => {
      setModalIsOpen(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [canSubmit, setCanSubmit] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);loanData?.role._id
   // };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   const IsSettlingLoan = useSelector((s) => s.loan.IsSettlingLoan);
   const loanSettled = useSelector((s) => s.loan.loanSettled);

   useEffect(() => {
      if (loanSettled) {
         setModalIsOpen(false);
         dispatch(appActions.clearMessages());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [loanSettled]);

   const errorMessage = useSelector((s) => s.loan.errorMessage);

   const amountError = errorMessage?.errors?.filter(
      (error) => error.param === 'amount',
   );

   const handleSubmit = (data) => {
      data['id'] = loanData?._id;

      dispatch(loanActions.settleLoan(data));
   };

   return (
      <div className="add-new">
         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={() => setModalIsOpen(false)}>
            <Formsy
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}
               className="__form d-flex flex-column">
               <h4 className="text-left mb-3">Settle User's Default Loan</h4>
               <p className="text-left loan mb-4">
                  Fill in the new Admin User's details
               </p>
               <TextInput
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-2 mb-s-4"
                  useFocusedError
                  name="amount"
                  type="text"
                  labelClassName="label"
                  labelText="Amount"
                  className="input11 border-0 w-100"
                  placeholder="100,000"
                  required
               />
               <p class="text-red" style={{color: '#E2574C'}}>
                  {amountError?.[0]?.msg}
               </p>
               <button disabled={!canSubmit} className="w-100 mt-3 mt-s-4">
                  {IsSettlingLoan ? (
                     <div class="text-white" role="status">
                        <i class="fa fa-spinner fa-spin"></i> Settling
                     </div>
                  ) : (
                     <div className="submit-btn w-100">Settle Loan</div>
                  )}
               </button>
            </Formsy>
         </Modal>
      </div>
   );
}
