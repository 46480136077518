import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {branchConstants, userConstants} from '../../_constants';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
} from '../../_helpers';

function* getBranches({data}) {
   yield put({type: branchConstants.REQUEST_ALL_BRANCHES});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let branchesUri = `${branchConstants.BRANCH_URI}`;

      if (data?.page) {
         branchesUri = `${branchesUri}&page=${data.page + 1}`;
      }

      const branchesReq = createRequestWithToken(branchesUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, branchesReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: branchConstants.REQUEST_ALL_BRANCHES_SUCCESS,
         branches: jsonResponse,
      });
   } catch (error) {
      yield put({type: branchConstants.REQUEST_ALL_BRANCHES_ERROR});
   }
}

function* createBranchSaga({data}) {
   yield put({type: branchConstants.REQUEST_CREATE_BRANCH});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${branchConstants.BRANCH_URI}`;
      const req = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: branchConstants.CREATE_BRANCH_SUCCESS,
         branch: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'Branch successfully created',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: branchConstants.CREATE_BRANCH_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchBranchSaga({data}) {
   yield put({type: branchConstants.REQUEST_SEARCH_BRANCH});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let searchBranchUri = `${branchConstants.BRANCH_URI}/search`;
      // /${data.searchName}
      // const req = createRequestWithToken(searchBranchUri, {method: 'GET'})(
      //    user?.token,
      // );
      const req = createRequestWithToken(searchBranchUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: branchConstants.SEARCH_BRANCH_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: branchConstants.SEARCH_BRANCH_SUCCESS,
         branch: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: branchConstants.SEARCH_BRANCH_ERROR,
         error: errorMessage,
      });
   }
}

function* updateBranchSaga({data}) {
   try {
      yield put({type: branchConstants.REQUEST_UPDATE_BRANCH});
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${branchConstants.BRANCH_URI}/${data.id}`;
      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: branchConstants.UPDATE_BRANCH_SUCCESS,
         branch: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'Branch successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: branchConstants.UPDATE_BRANCH_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteBranchSaga({_id}) {
   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      yield put({type: branchConstants.REQUEST_DELETE_BRANCH});

      const url = `${branchConstants.BRANCH_URI}/${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: branchConstants.DELETE_BRANCH_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'Branch successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: branchConstants.DELETE_BRANCH_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* createMarketerSaga({data}) {
   yield put({type: branchConstants.REQUEST_CREATE_MARKETER});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${branchConstants.MARKETER_URI}`;
      const req = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: branchConstants.CREATE_MARKETER_SUCCESS,
         marketers: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'Marketer successfully added',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: branchConstants.CREATE_MARKETER_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getMarketerCustomers({data}) {
   yield put({type: branchConstants.REQUEST_MARKETER_CUSTOMERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      const branchesUri = `${branchConstants.MARKETER_URI}/${data}/customers`;

      const branchesReq = createRequestWithToken(branchesUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, branchesReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: branchConstants.REQUEST_MARKETER_CUSTOMERS_SUCCESS,
         marketerCustomers: jsonResponse,
      });
   } catch (error) {
      yield put({type: branchConstants.REQUEST_MARKETER_CUSTOMERS_ERROR});
   }
}

function* getBranchMarketers({data}) {
   yield put({type: branchConstants.REQUEST_BRANCH_MARKETERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      const branchesUri = `${branchConstants.BRANCH_URI}/${data}/marketers`;

      const branchesReq = createRequestWithToken(branchesUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, branchesReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: branchConstants.REQUEST_BRANCH_MARKETERS_SUCCESS,
         branchMarketers: jsonResponse,
      });
   } catch (error) {
      yield put({type: branchConstants.REQUEST_BRANCH_MARKETERS_ERROR});
   }
}

function* getBranchesWatcher() {
   yield takeLatest(branchConstants.GET_ALL_BRANCHES, getBranches);
}

function* createBranchWatcher() {
   yield takeLatest(branchConstants.CREATE_BRANCH, createBranchSaga);
}

function* searchBranchWatcher() {
   yield takeLatest(branchConstants.SEARCH_BRANCH, searchBranchSaga);
}

function* updateBranchWatcher() {
   yield takeLatest(branchConstants.UPDATE_BRANCH, updateBranchSaga);
}

function* deleteBranchWatcher() {
   yield takeLatest(branchConstants.DELETE_BRANCH, deleteBranchSaga);
}
function* createMarketerWatcher() {
   yield takeLatest(branchConstants.CREATE_MARKETER, createMarketerSaga);
}

function* getBranchMarketersWatcher() {
   yield takeLatest(branchConstants.GET_BRANCH_MARKETERS, getBranchMarketers);
}

function* getMarketerCustomersWatcher() {
   yield takeLatest(
      branchConstants.GET_MARKETER_CUSTOMERS,
      getMarketerCustomers,
   );
}

export default function* rootSaga() {
   yield all([
      getBranchesWatcher(),
      createBranchWatcher(),
      searchBranchWatcher(),
      updateBranchWatcher(),
      deleteBranchWatcher(),
      createMarketerWatcher(),
      getBranchMarketersWatcher(),
      getMarketerCustomersWatcher(),
   ]);
}
