import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBBadge, MDBTable, MDBTableHead, MDBTableBody} from 'mdbreact';
import {Link} from 'react-router-dom';
// import {Bar} from 'react-chartjs-2';
import './index.scss';
import {readableDateString, numberWithCommas} from '../../_helpers';

import {ReactComponent as Customer} from '../../_assets/icons/1customer.svg';
import {ReactComponent as Merchant} from '../../_assets/icons/1merchant.svg';
import {ReactComponent as Premium} from '../../_assets/icons/1premium.svg';
import {ReactComponent as Loan} from '../../_assets/icons/1naira.svg';
// import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';
import {ReactComponent as NoData} from '../../_assets/icons/1noData.svg';
import {dashboardActions} from '../../_actions';

// const state = {
//    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
//    datasets: [
//       {
//          label: 'Customer',
//          backgroundColor: '#3377EF',
//          borderRadius: '7',
//          data: [58, 89, 80, 81, 56, 40],
//       },
//       {
//          label: 'Merchant',
//          backgroundColor: 'rgba(51, 119, 239, 0.6)',
//          borderRadius: '7',
//          data: [88, 80, 50, 81, 56, 70],
//       },
//       {
//          label: 'Premium Merchant',
//          backgroundColor: 'rgba(51, 119, 239, 0.3)',
//          borderRadius: '7',
//          data: [95, 79, 30, 60, 96, 50],
//       },
//    ],
// };

// const chartOptions = {
//    legend: {
//       display: false,
//       // position: 'right',
//    },
//    tooltips: {
//       callbacks: {
//          label: function (tooltipItem) {
//             return tooltipItem.yLabel;
//          },
//       },
//    },
//    scales: {
//       xAxes: [
//          {
//             stacked: true,
//             barPercentage: 0.2,
//          },
//       ],
//       yAxes: [
//          {
//             stacked: true,
//             barPercentage: 0.2,
//          },
//       ],
//    },
//    maintainAspectRatio: true,
//    title: {
//       display: true,
//       text: 'Average Rainfall per month',
//       fontSize: 20,
//    },
// };

export default function Dashboard() {
   const dispatch = useDispatch();
   const dashboardMetrics = useSelector((s) => s.dashboard.dashboardMetrics);

   // const [isStatusOpen, setIsStatusOpen] = useState(false);
   // const toggleStatusList = () => {
   //    setIsStatusOpen((wasOpen) => !wasOpen);
   // };
   // const [selected, setSelected] = useState(0);
   // const options = [
   //    {label: 'Jan - Jun 2021', val: 0},
   //    {label: 'Jul - Dec 2021', val: 1},
   // ];

   useEffect(() => {
      dispatch(dashboardActions.getDashboardMetrics());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [data, isData] = useState(true);
   const [noData, setNoData] = useState(false);
   const toggleOpen = () => {
      isData(!data);
      setNoData(!noData);
   };

   const [data2, isData2] = useState(true);
   const [noData2, setNoData2] = useState(false);
   const toggleOpen2 = () => {
      isData2(!data2);
      setNoData2(!noData2);
   };

   // const [data3, isData3] = useState(true);
   // const [noData3, setNoData3] = useState(false);
   // const toggleOpen3 = () => {
   //    isData3(!data3);
   //    setNoData3(!noData3);
   // };

   // const {premium, merchant, customer, approvedLoan, withdrawals} = dashboardMetrics;

   return (
      <MDBRow className="dashboard-page m-0 w-100 mb-5">
         <div className="cards d-flex justify-content-center w-100">
            <div className="card-item">
               <Link to="/customer/loan">
                  <div className="card-inner d-flex justify-content-between">
                     <div className="text">
                        <h6>Customer</h6>
                        <p>{dashboardMetrics?.customer}</p>
                     </div>
                     <Customer style={{width: '3.1rem'}} />
                  </div>
               </Link>
            </div>
            <div className="card-item">
               <Link to="/merchant/regular">
                  <div className="card-inner d-flex justify-content-between">
                     <div className="text">
                        <h6>Merchant</h6>
                        <p>{dashboardMetrics?.merchant}</p>
                     </div>
                     <Merchant style={{width: '3.1rem'}} />
                  </div>
               </Link>
            </div>
            <div className="card-item">
               <Link to="/merchant/premium">
                  <div className="card-inner d-flex justify-content-between">
                     <div className="text">
                        <h6>Premium</h6>
                        <p>{dashboardMetrics?.premium}</p>
                     </div>
                     <Premium style={{width: '3.1rem'}} />
                  </div>
               </Link>
            </div>
            <div className="card-item">
               <Link to="/loan">
                  <div className="card-inner d-flex justify-content-between">
                     <div className="text">
                        <h6>Loan</h6>
                        <p>
                           #
                           {numberWithCommas(
                              dashboardMetrics?.loan_amount?.totalAmount,
                           )}
                        </p>
                     </div>
                     <Loan style={{width: '3.1rem'}} />
                  </div>
               </Link>
            </div>
         </div>

         <div className="row m-0 w-100">
            <section className="chart-withdrawal p-0 pr-4">
               {/* <div className="chart position-relative">
                  <div className="collaps">
                     <div
                        onClick={toggleStatusList}
                        className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrDown className="ml-4" />
                        {isStatusOpen && (
                           <div className="status-list position-absolute">
                              <ul className="">
                                 {options
                                    // .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             // e.stopPropagation();
                                             // setIsOpen(false);
                                             setSelected(i.val);
                                          }}>
                                          <Link className="w-100 d-block">
                                             {i.label}
                                          </Link>
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="bar-chart" style={{height: '430px'}}>
                     <Bar data={state} height={200} options={chartOptions} />
                  </div>
               </div> */}
               <div className="loan">
                  <div className="withdraw d-flex mb-4 align-items-center justify-content-between">
                     <p>
                        Approved Loan{' '}
                        <MDBBadge onClick={toggleOpen} className="ml-2">
                           {dashboardMetrics?.approvedLoanCount}
                        </MDBBadge>
                        <span className="sr-only">unread messages</span>
                     </p>
                     <Link to="/loan">See All</Link>
                  </div>
                  {/* {
                     rolesList?.length > 0 ? (
                        rolesList.map((row) => {
                           return (
                  } */}
                  {dashboardMetrics?.approvedLoan.totalDocs > 0 ? (
                     <div className="withdrawal-inner px-3 d-block">
                        <MDBTable
                           className="profile"
                           responsive
                           borderless
                           hover
                           align="middle">
                           <MDBTableHead>
                              <tr>
                                 <th>Customer Name</th>
                                 <th>Amount</th>
                                 <th>Time</th>
                              </tr>
                           </MDBTableHead>
                           <MDBTableBody>
                              {dashboardMetrics?.approvedLoan.docs?.length >
                              0 ? (
                                 dashboardMetrics?.approvedLoan.docs.map(
                                    (row) => {
                                       return (
                                          <div className="d-table-row">
                                             <th scope="row">
                                                {row.firstname +
                                                   ' ' +
                                                   row.lastname}
                                             </th>
                                             <td>
                                                #
                                                {numberWithCommas(
                                                   row.loan_details?.limit,
                                                )}
                                             </td>
                                             <td>
                                                {readableDateString(
                                                   row.created_at,
                                                )}
                                             </td>
                                             {/* <td>09:00AM</td> */}
                                          </div>
                                       );
                                    },
                                 )
                              ) : (
                                 <th scope="row">
                                    <td align="center" colSpan={5}>
                                       No result to display
                                    </td>
                                 </th>
                              )}
                           </MDBTableBody>
                        </MDBTable>
                     </div>
                  ) : (
                     <div className="withdrawal-inner px-3 d-block">
                        <div className="data d-flex mx-5 justify-content-center align-items-center">
                           <NoData style={{width: '12rem'}} />
                        </div>
                        <p className="d-flex data justify-content-center align-items-center">
                           You do not have any recent transaction
                        </p>
                     </div>
                  )}
               </div>
               <div className="earning">
                  <div className="withdraw approve-loan d-flex mb-4 align-items-center justify-content-between">
                     <p>
                        Top Earning Merchant{' '}
                        {/* <MDBBadge onClick={toggleOpen3} className="ml-2">
                           22
                        </MDBBadge> */}
                        <span className="sr-only">unread messages</span>
                     </p>
                     {/* <Link to="/merchant/premium">Top Six</Link> */}
                  </div>
                  {/* {data3 && ( */}
                  {dashboardMetrics?.topMerchants.length > 0 ? (
                     <div className="withdrawal-inner px-3">
                        <MDBTable
                           className="profile"
                           responsive
                           borderless
                           hover
                           align="middle">
                           <MDBTableHead>
                              <tr>
                                 <th>Merchant Name</th>
                                 <th>Amount</th>
                              </tr>
                           </MDBTableHead>
                           <MDBTableBody>
                              {dashboardMetrics?.topMerchants.map((row) => {
                                 return (
                                    <div className="d-table-row">
                                       <th scope="row">Adedoyin Yusuff</th>
                                       <td>{row.totalAmount}</td>
                                    </div>
                                 );
                              })}
                              {/* <div className="d-table-row">
                                 <th scope="row">Kamil Azeez</th>
                                 <td># 50, 000</td>
                              </div>
                              <div className="d-table-row">
                                 <th scope="row">Alex Kelvin</th>
                                 <td># 10, 000</td>
                              </div>
                              <div className="d-table-row">
                                 <th scope="row">Wale Ojo</th>
                                 <td># 20, 000</td>
                              </div>
                              <div className="d-table-row">
                                 <th scope="row">Ade Oye</th>
                                 <td># 100, 000</td>
                              </div>
                              <div className="d-table-row">
                                 <th scope="row">Cynthia Iyke</th>
                                 <td># 5, 000</td>
                              </div> */}
                           </MDBTableBody>
                        </MDBTable>
                     </div>
                  ) : (
                     <div className="withdrawal-inner px-3 d-block">
                        <div className="data2 d-flex mx-5 justify-content-center align-items-center">
                           <NoData style={{width: '12rem'}} />
                        </div>
                        <p className="d-flex data justify-content-center align-items-center">
                           No merchant has received payment
                        </p>
                     </div>
                  )}
               </div>
            </section>

            <section className="withdrawal p-0">
               {/* <div className="profit">
                  <div className="circle d-flex justify-content-center align-items-center">
                     33.3%
                  </div>
                  <p className="d-flex justify-content-center">Profit</p>
               </div> */}
               <div className="settlement">
                  <div className="withdraw d-flex mb-4 align-items-center justify-content-between">
                     <p>
                        Settlement{' '}
                        <MDBBadge onClick={toggleOpen2} className="ml-2">
                           {dashboardMetrics?.withdrawalsCount}
                        </MDBBadge>
                        <span className="sr-only">unread messages</span>
                     </p>
                     <Link to="/settlement">See All</Link>
                  </div>

                  {dashboardMetrics?.withdrawals.totalDocs > 0 ? (
                     <div className="withdrawal-inner px-3 d-block">
                        <MDBTable
                           className="profile"
                           responsive
                           borderless
                           hover
                           align="middle">
                           <MDBTableHead>
                              <tr>
                                 <th>Merchant Name</th>
                                 <th>Amount</th>
                              </tr>
                           </MDBTableHead>
                           <MDBTableBody>
                              {dashboardMetrics?.withdrawals.docs?.length >
                              0 ? (
                                 dashboardMetrics?.withdrawals.docs.map(
                                    (row) => {
                                       return (
                                          <div className="d-table-row">
                                             <th scope="row">
                                                {row.sender_name}
                                             </th>
                                             <td>
                                                #{numberWithCommas(row.amount)}
                                             </td>
                                          </div>
                                       );
                                    },
                                 )
                              ) : (
                                 <th scope="row">
                                    <td align="center" colSpan={5}>
                                       No result to display
                                    </td>
                                 </th>
                              )}
                              {/* <div className="d-table-row">
                                 <th scope="row">Kamil Azeez</th>
                                 <td># 50, 000</td>
                              </div>
                              <div className="d-table-row">
                                 <th scope="row">Alex Kelvin</th>
                                 <td># 10, 000</td>
                              </div>
                              <div className="d-table-row">
                                 <th scope="row">Wale Ojo</th>
                                 <td># 20, 000</td>
                              </div> */}
                           </MDBTableBody>
                        </MDBTable>
                     </div>
                  ) : (
                     <div className="withdrawal-inner px-3 d-block">
                        <div className="data2 d-flex mx-5 justify-content-center align-items-center">
                           <NoData style={{width: '9rem'}} />
                        </div>
                        <p className="d-flex data justify-content-center align-items-center">
                           You do not have any recent transaction
                        </p>
                     </div>
                  )}
                  {/* {noData2 && (
                     <div className="withdrawal-inner px-3 d-block">
                        <div className="data2 d-flex justify-content-center align-items-center">
                           <NoData style={{width: '9rem'}} />
                        </div>
                        <p className="d-flex data2 justify-content-center align-items-center">
                           You do not have any recent transaction
                        </p>
                     </div>
                  )} */}
               </div>
            </section>
         </div>
      </MDBRow>
   );
}
