import {appConstants} from '../../_constants';
import {combineReducers} from 'redux';

const message = (state = null, data) => {
   switch (data.type) {
      case appConstants.CLEAR_SNACKBAR:
         return null;
      case appConstants.SET_SNACKBAR: {
         const result = {
            text: data.message,
            variant: data.variant,
         };

         return result;
      }

      default:
         return state;
   }
};
const rootReducer = combineReducers({
   message,
});

export default rootReducer;
