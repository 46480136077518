import React, {useEffect} from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import LetteredAvatar from 'lettered-avatar';
import './index.scss';
import TablePaginationActions from '../../_components/TablePaginationAction';
import {
   // numberWithCommas,
   readableDateString,
   capitalizeFirstLetter,
   numberWithCommas,
} from '../../_helpers';
import Loader from '../../_helpers/loader';

import {loanActions} from '../../_actions';
import SettleDefaultForm from './settleDefaultForm';

export default function Summary() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(loanActions.getAllLoan());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(loanActions.getAllLoan(model));
   };

   const loan = useSelector((s) => s.loan);
   const allLoanList = useSelector((s) => s.loan.allLoanList);
   const {totalDocs, limit, page, totalPages} = loan?.pagination;
   const total = totalDocs;
   const pages = page - 1;

   const [settle, setSettle] = React.useState(false);
   const [loanData, setLoanData] = React.useState(null);

   const toggleSettle = (row) => {
      setSettle((settle) => !settle);
      // console.log('Settle', row);
      setLoanData(row);
   };

   return (
      <div className="customer-list mt-3 w-100">
         <MDBTable
            className="profile mb-5"
            responsive
            borderless
            hover
            align="middle">
            <MDBTableHead>
               <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Settlement Date</th>
                  <th scope="col">Utilized Amount</th>
                  <th scope="col">Amount Settled</th>
                  <th scope="col">Status</th>
                  {/* <th scope="col">Request Date</th>
                  <th scope="col">Verification</th> */}
                  {/* <th scope="col">Approve</th> */}
               </tr>
            </MDBTableHead>
            {loan.IsRequestingAllLoan || loan.IsSearchingLoan ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTableBody>
                  {allLoanList?.length > 0 ? (
                     allLoanList.map((row) => {
                        return (
                           <Link
                              to={`/loan-customer/${row?.loan_details?._id}`}
                              key={row.id}
                              className="d-table-row align-items-center">
                              {/* <div className="d-table-row" key={row._id}> */}
                              <th scope="row">
                                 <LetteredAvatar
                                    name={row.firstname + ' ' + row.lastname}
                                    options={{
                                       size: 50,
                                       twoLetter: true,
                                       //shape: 'square',
                                       bgColor: '',
                                       imgClass: 'image-responsive user-image',
                                       imgWidth: '3rem',
                                       imgHeight: '3rem',
                                    }}
                                 />
                                 <span>
                                    {row.firstname + ' ' + row.lastname}
                                 </span>
                              </th>
                              <td>{row?.email}</td>
                              <td>
                                 {row?.user_id?.phone_number?.replace(
                                    /^.{3}/g,
                                    '0',
                                 )}
                              </td>
                              <td>
                                 {numberWithCommas(row?.loan_details?.limit)}
                              </td>
                              <td>
                                 {row?.loan_details?.payment_date &&
                                    readableDateString(
                                       row?.loan_details?.payment_date,
                                    )}
                              </td>
                              <td>
                                 {row?.loan_details?.utilized_amount &&
                                    numberWithCommas(
                                       row?.loan_details?.utilized_amount,
                                    )}
                              </td>
                              <td>
                                 {row?.loan_details?.amount_settled &&
                                    numberWithCommas(
                                       row?.loan_details?.amount_settled,
                                    )}
                              </td>
                              {/* <td>{row?.loan_details?.loan_status}</td> */}
                              <td>
                                 {row?.loan_status &&
                                    capitalizeFirstLetter(row?.loan_status)}
                              </td>
                              {/* <td>
                                 {row?.loan_details?.is_verified
                                    ? 'Verified'
                                    : 'Not Verified'}
                              </td> */}
                              {/* </div> */}
                              {row?.loan_status === 'default' && (
                                 <td>
                                    <Link
                                       onClick={() => toggleSettle(row)}
                                       className="more w-100 d-block text-center">
                                       Settle
                                    </Link>
                                 </td>
                              )}
                           </Link>
                        );
                     })
                  ) : (
                     <th scope="row">
                        <td align="center" colSpan={5}>
                           No result to display
                        </td>
                     </th>
                  )}
               </MDBTableBody>
            )}
         </MDBTable>
         {!loan.IsRequestingAllLoan && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
         {settle && (
            <SettleDefaultForm loanData={loanData} openModal={settle} />
         )}
      </div>
   );
}
