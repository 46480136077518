import {combineReducers} from 'redux';
import {loanConstants, userConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingAllLoanRequest = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN_REQUESTS:
         return true;
      case loanConstants.REQUEST_ALL_LOAN_REQUESTS_ERROR:
         return false;
      case loanConstants.REQUEST_ALL_LOAN_REQUESTS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAllLoan = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN:
         return true;
      case loanConstants.REQUEST_ALL_LOAN_ERROR:
         return false;
      case loanConstants.REQUEST_ALL_LOAN_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAllLoanGranted = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN_GRANTED:
         return true;
      case loanConstants.REQUEST_ALL_LOAN_GRANTED_ERROR:
         return false;
      case loanConstants.REQUEST_ALL_LOAN_GRANTED_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingLoanDetails = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_LOAN_DETAILS:
         return true;
      case loanConstants.REQUEST_LOAN_DETAILS_ERROR:
         return false;
      case loanConstants.REQUEST_LOAN_DETAILS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsVerifyingLoan = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_VERIFY_LOAN:
         return true;
      case loanConstants.REQUEST_VERIFY_LOAN_SUCCESS:
         return false;
      case loanConstants.REQUEST_VERIFY_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};
const IsSettlingLoan = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_SETTLE_LOAN:
         return true;
      case loanConstants.REQUEST_SETTLE_LOAN_SUCCESS:
         return false;
      case loanConstants.REQUEST_SETTLE_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};

const loanSettled = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_SETTLE_LOAN:
         return false;
      case loanConstants.REQUEST_SETTLE_LOAN_SUCCESS:
         return true;
      case loanConstants.REQUEST_SETTLE_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};

const IsGrantingLoan = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_GRANT_LOAN:
         return true;
      case loanConstants.REQUEST_GRANT_LOAN_SUCCESS:
         return false;
      case loanConstants.REQUEST_GRANT_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};

const loanGranted = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_GRANT_LOAN:
         return false;
      case loanConstants.REQUEST_GRANT_LOAN_SUCCESS:
         return true;
      case loanConstants.REQUEST_GRANT_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};

const IsDecliningLoan = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_DECLINE_LOAN:
         return true;
      case loanConstants.REQUEST_DECLINE_LOAN_SUCCESS:
         return false;
      case loanConstants.REQUEST_DECLINE_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};

const IsSearchingLoan = (state = false, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_SEARCH_LOAN:
         return true;
      case loanConstants.SEARCH_LOAN_REQUEST_SUCCESS:
         return false;
      case loanConstants.SEARCH_LOAN_GRANTED_SUCCESS:
         return false;
      case loanConstants.SEARCH_LOAN_SUCCESS_WITHOUT_DATA:
         return false;
      case loanConstants.SEARCH_LOAN_ERROR:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   company_name: '',
   product: '',
   amount: '',
   created_at: '',
};

const loanDetails = (state = null, {type, loanDetails, verifiedLoan}) => {
   switch (type) {
      case loanConstants.REQUEST_LOAN_DETAILS_SUCCESS:
         return updateObject(state, loanDetails);
      // case loanConstants.CHANGE_USER_STATUS_SUCCESS:
      //    return updateObject(state, userDetails);
      case loanConstants.REQUEST_VERIFY_LOAN_SUCCESS: {
         const loanVerified = {
            ...state,
            loan_details: verifiedLoan.loan_data,
            // loan_details: {
            //    ...state.loan_details,
            //    is_verified: verifiedLoan.verified,
            // },
         };
         return updateObject(state, loanVerified);
      }
      case loanConstants.REQUEST_GRANT_LOAN_SUCCESS: {
         const loanGranted = {
            ...state,
            loan_details: {
               ...state.loan_details,
               loan_status: 'approved',
            },
         };
         return updateObject(state, loanGranted);
      }
      case userConstants.VERIFY_CUSTOMER_USER_BVN_SUCCESS: {
         const user = {
            ...state,
            user_details: {
               ...state.user_details,
               bvn_verified: true,
            },
         };
         return updateObject(state, user);
      }
      default:
         return state;
   }
};

const allLoanRequestList = (state = [], action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN_REQUESTS_SUCCESS: {
         return action.loanRequests.data.docs;
      }
      case loanConstants.SEARCH_LOAN_REQUEST_SUCCESS: {
         return action.loans.data.docs;
      }
      case loanConstants.SEARCH_LOAN_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const allLoanList = (state = [], action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN_SUCCESS: {
         return action.loanSummary.data.docs;
      }
      // case loanConstants.SEARCH_LOAN_REQUEST_SUCCESS: {
      //    return action.loans.data.docs;
      // }
      // case loanConstants.SEARCH_LOAN_SUCCESS_WITHOUT_DATA:
      //    return updateObject(state, emptyState);
      default:
         return state;
   }
};

const allLoanGrantedList = (state = [], action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN_GRANTED_SUCCESS: {
         return action.loanGranted.data.docs;
      }
      case loanConstants.SEARCH_LOAN_GRANTED_SUCCESS: {
         return action.loans.data.docs;
      }
      case loanConstants.SEARCH_LOAN_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case loanConstants.REQUEST_ALL_LOAN_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.loanSummary.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      case loanConstants.REQUEST_ALL_LOAN_REQUESTS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} =
            action.loanRequests.data;
         const result = {hasNextPage, nextPage, totalDocs, limit, page};

         return updateObject(state, result);
      }
      case loanConstants.REQUEST_ALL_LOAN_GRANTED_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} =
            action.loanGranted.data;
         const result = {hasNextPage, nextPage, totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case loanConstants.REQUEST_GRANT_LOAN_ERROR:
         return updateObject(state, error);
      case loanConstants.REQUEST_GRANT_LOAN:
         return null;
      case loanConstants.REQUEST_SETTLE_LOAN_ERROR:
         return updateObject(state, error);
      case loanConstants.REQUEST_SETTLE_LOAN:
         return null;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllLoanRequest,
   IsRequestingAllLoan,
   IsRequestingAllLoanGranted,
   IsRequestingLoanDetails,
   IsVerifyingLoan,
   IsSettlingLoan,
   loanSettled,
   IsGrantingLoan,
   loanGranted,
   IsDecliningLoan,
   IsSearchingLoan,
   loanDetails,
   allLoanList,
   allLoanRequestList,
   allLoanGrantedList,
   pagination,
   errorMessage,
});

export default rootReducer;
