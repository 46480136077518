import {userConstants} from '../_constants';

const login = (model) => ({
   type: userConstants.LOGIN,
   data: model,
});

const logout = () => ({
   type: userConstants.LOGOUT,
});

const acceptInvitation = (model) => ({
   type: userConstants.ACCEPT_INVITATION,
   data: model,
});

const createAdminUser = (model) => ({
   type: userConstants.CREATE_ADMIN_USER,
   data: model,
});

const verifyEmail = (model) => ({
   type: userConstants.VERIFY_EMAIL,
   data: model,
});

const resendEmailLink = (model) => ({
   type: userConstants.RESEND_EMAIL_LINK,
   data: model,
});

const setPassword = (model) => ({
   type: userConstants.SET_PASSWORD,
   data: model,
});

const changePassword = (model) => ({
   type: userConstants.CHANGE_PASSWORD,
   data: model,
});

const verifyUserBvn = (model) => ({
   type: userConstants.VERIFY_USER_BVN,
   data: model,
});

const getUser = (model) => ({
   type: userConstants.GET_USER,
   data: model,
});

const getAllAdminUsers = (model) => ({
   type: userConstants.GET_ALL_ADMIN_USERS,
   data: model,
});

const exportUsers = (model) => ({
   type: userConstants.EXPORT_ADMIN_USERS,
   data: model,
});

const fetchAllUsers = (model) => ({
   type: userConstants.FETCH_ALL_USERS,
   data: model,
});

const getAdminUsers = (model) => ({
   type: userConstants.GET_ADMIN_USERS,
   data: model,
});

const registerAdminUser = (model) => ({
   type: userConstants.REGISTER_ADMIN_USER,
   data: model,
});

const updateAdminUser = (model) => ({
   type: userConstants.UPDATE_ADMIN_USER,
   data: model,
});

const deleteAdminUser = (_id) => ({
   type: userConstants.DELETE_ADMIN_USER,
   _id,
});

const searchAdminUser = (model) => ({
   type: userConstants.SEARCH_ADMIN_USER,
   data: model,
});

export const userActions = {
   login,
   logout,
   acceptInvitation,
   verifyEmail,
   resendEmailLink,
   setPassword,
   changePassword,
   verifyUserBvn,
   getUser,
   getAllAdminUsers,
   exportUsers,
   fetchAllUsers,
   getAdminUsers,
   createAdminUser,
   registerAdminUser,
   updateAdminUser,
   deleteAdminUser,
   searchAdminUser,
};
