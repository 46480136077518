import {call, put, takeLatest, all} from 'redux-saga/effects';
import {dashboardConstants, userConstants} from '../../_constants';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
} from '../../_helpers';

function* getDashboard() {
   yield put({type: dashboardConstants.REQUEST_DASHBOARD_METRICS});
   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const dashboardUri = `${dashboardConstants.GET_DASHBOARD_METRICS_URI}`;
      const req = createRequestWithToken(dashboardUri, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: dashboardConstants.REQUEST_DASHBOARD_METRICS_SUCCESS,
         dashboard: jsonResponse,
      });
   } catch (error) {
      yield put({type: dashboardConstants.REQUEST_DASHBOARD_METRICS_ERROR});
   }
}

function* getDashboardWatcher() {
   yield takeLatest(dashboardConstants.GET_DASHBOARD_METRICS, getDashboard);
}

export default function* rootSaga() {
   yield all([getDashboardWatcher()]);
}
