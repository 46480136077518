import {appConstants} from './app.constant';

const branch = 'branches';
const marketer = 'marketers';

export const branchConstants = {
   REQUEST_CREATE_BRANCH: 'REQUEST_CREATE_BRANCH',
   CREATE_BRANCH_SUCCESS: 'CREATE_BRANCH_SUCCESS',
   CREATE_BRANCH_ERROR: 'CREATE_BRANCH_ERROR',

   REQUEST_ALL_BRANCHES: 'REQUEST_ALL_BRANCHES',
   REQUEST_ALL_BRANCHES_SUCCESS: 'REQUEST_ALL_BRANCHES_SUCCESS',
   REQUEST_ALL_BRANCHES_WITHOUT_PAGINATION_SUCCESS:
      'REQUEST_ALL_BRANCHES_WITHOUT_PAGINATION_SUCCESS',
   REQUEST_ALL_BRANCHES_ERROR: 'REQUEST_ALL_BRANCHES_ERROR',

   REQUEST_SEARCH_BRANCH: 'REQUEST_SEARCH_BRANCH',
   SEARCH_BRANCH_SUCCESS: 'SEARCH_BRANCH_SUCCESS',
   SEARCH_BRANCH_ERROR: 'SEARCH_BRANCH_ERROR',
   SEARCH_BRANCH_SUCCESS_WITHOUT_DATA: 'SEARCH_BRANCH_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_BRANCH: 'REQUEST_DELETE_BRANCH',
   DELETE_BRANCH_SUCCESS: 'DELETE_BRANCH_SUCCESS',
   DELETE_BRANCH_ERROR: 'DELETE_BRANCH_ERROR',

   REQUEST_UPDATE_BRANCH: 'REQUEST_UPDATE_BRANCH',
   UPDATE_BRANCH_SUCCESS: 'UPDATE_BRANCH_SUCCESS',
   UPDATE_BRANCH_ERROR: 'UPDATE_BRANCH_ERROR',

   REQUEST_CREATE_MARKETER: 'REQUEST_CREATE_MARKETER',
   CREATE_MARKETER_SUCCESS: 'CREATE_MARKETER_SUCCESS',
   CREATE_MARKETER_ERROR: 'CREATE_MARKETER_ERROR',

   REQUEST_BRANCH_MARKETERS: 'REQUEST_BRANCH_MARKETERS',
   REQUEST_BRANCH_MARKETERS_SUCCESS: 'REQUEST_BRANCH_MARKETERS_SUCCESS',
   REQUEST_BRANCH_MARKETERS_ERROR: 'REQUEST_BRANCH_MARKETERS_ERROR',
   REQUEST_BRANCH_MARKETERS_SUCCESS_WITHOUT_DATA:
      'REQUEST_BRANCH_MARKETERS_SUCCESS_WITHOUT_DATA',

   REQUEST_SEARCH_BRANCH_MARKETER: 'REQUEST_SEARCH_BRANCH_MARKETER',
   SEARCH_BRANCH_MARKETER_SUCCESS: 'SEARCH_BRANCH_MARKETER_SUCCESS',
   SEARCH_BRANCH_MARKETER_ERROR: 'SEARCH_BRANCH_MARKETER_ERROR',
   SEARCH_BRANCH_MARKETER_SUCCESS_WITHOUT_DATA:
      'SEARCH_BRANCH_MARKETER_SUCCESS_WITHOUT_DATA',

   REQUEST_MARKETER_CUSTOMERS: 'REQUEST_MARKETER_CUSTOMERS',
   REQUEST_MARKETER_CUSTOMERS_SUCCESS: 'REQUEST_MARKETER_CUSTOMERS_SUCCESS',
   REQUEST_MARKETER_CUSTOMERS_ERROR: 'REQUEST_MARKETER_CUSTOMERS_ERROR',
   REQUEST_MARKETER_CUSTOMERS_SUCCESS_WITHOUT_DATA:
      'REQUEST_MARKETER_CUSTOMERS_SUCCESS_WITHOUT_DATA',

   GET_ALL_BRANCHES: 'GET_ALL_BRANCHES',
   CREATE_BRANCH: 'CREATE_BRANCH',
   SEARCH_BRANCH: 'SEARCH_BRANCH',

   DELETE_BRANCH: 'DELETE_BRANCH',
   UPDATE_BRANCH: 'UPDATE_BRANCH',
   CREATE_MARKETER: 'CREATE_MARKETER',

   GET_BRANCH_MARKETERS: 'GET_BRANCH_MARKETERS',
   SEARCH_BRANCH_MARKETER: 'SEARCH_BRANCH_MARKETER',
   GET_MARKETER_CUSTOMERS: 'GET_MARKETER_CUSTOMERS',

   BRANCH_URI: `${appConstants.BASE_URI}${branch}`,
   MARKETER_URI: `${appConstants.BASE_URI}${marketer}`,
};
