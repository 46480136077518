import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import './index.scss';
import BranchForm from './form';

// import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
import {ReactComponent as DotsIcon} from '../../_assets/icons/dots.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Remove} from '../../_assets/icons/1remove.svg';
// import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';
import {ReactComponent as Delete} from '../../_assets/icons/DeleteIcon.svg';
// import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';

import img from '../../_assets/icons/1admin.svg';
import {branchActions} from '../../_actions';
// import AddPermission from '../Settings/addPermission';
import useOnClickOutside from '../../_components/OnClickOutside';

export function Teams() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(branchActions.getBranches());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const IsRequestingBranches = useSelector(
      (s) => s.branch.IsRequestingBranches,
   );
   const IsSearchingBranch = useSelector((s) => s.branch.IsSearchingBranch);
   const branchesList = useSelector((s) => s.branch.branchesList);

   const [searchInput, setSearchInput] = useState('');
   const [open, setOpen] = useState(false);
   const [teamId, setTeamId] = useState('');
   const [deleteTeam, setDeleteTeam] = useState(false);
   const [teamData, setTeamData] = useState({});
   const [modalIsOpen, setModalIsOpen] = useState(false);

   const searchBranch = () => {
      const model = {
         // query: {
         //    field: searchInput,
         // },
         query: searchInput,
         limit: 10,
         sort: {
            name: 1,
         },
      };
      dispatch(branchActions.searchBranch(model));
   };

   useEffect(() => {
      if (searchInput === '') {
         // const model = {
         //    query: {},
         // };
         // dispatch(customerActions.getAllCustomers(model));
         dispatch(branchActions.getBranches());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchInput]);

   // const toggleUpdate = () => {
   //    setOpen(false);
   // };
   const [deletePermission, setDeletePermission] = useState(false);
   const toggleDelete = (row) => {
      setTeamData(row);
      setDeleteTeam(!deleteTeam);
   };

   const handleDelete = () => {
      const _id = teamData?._id;

      if (_id) {
         dispatch(branchActions.deleteBranch(_id));
      }
      setDeleteTeam(false);
   };

   const clearText = () => {
      // setIsDateOpen(true);
      setSearchInput('');
   };

   const toggleOpen = (id) => {
      setTeamId(id);
      setOpen((open) => !open);
   };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };
   const userDropdownRef = useRef();
   useOnClickOutside(userDropdownRef, () => {
      if (modalIsOpen === false) {
         if (open) setOpen(false);
      }
   });

   useEffect(() => {
      if (modalIsOpen === false) {
         if (open) setOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [modalIsOpen]);

   // const [dot, setDot] = useState(false);

   return (
      <MDBRow className="admin-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search filter">
               <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     value={searchInput}
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon
                     className="icons i-x"
                     style={{width: '1rem'}}
                     onClick={clearText}
                  />
                  <span className="i-search" onClick={searchBranch}>
                     <SearchIcon style={{width: '1rem'}} />
                  </span>
               </div>
            </div>
            <div className="filter d-flex">
               <BranchForm
                  type="add"
                  setModalIsOpen={setModalIsOpen}
                  modalIsOpen={modalIsOpen}
               />
            </div>
         </div>
         <div className="admins container row m-0">
            {IsRequestingBranches || IsSearchingBranch ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : branchesList?.length > 0 ? (
               branchesList.map((row) => {
                  return (
                     // <div className="admin col-md-3 mr-3 mb-4">
                     <div className="admin col-md-3 mb-4">
                        <div className="admin-inner p-3 position-relative">
                           <div className="dot position-absolute d-flex align-items-center justify-content end">
                              <DotsIcon
                                 onClick={() => toggleOpen(row._id)}
                                 className="icon position-relative"
                              />
                              {open && teamId === row._id && (
                                 <ul
                                    ref={userDropdownRef}
                                    className="drop position-absolute">
                                    <li>
                                       <BranchForm
                                          branchData={row}
                                          type="edit"
                                          setModalIsOpen={setModalIsOpen}
                                          modalIsOpen={modalIsOpen}
                                          // toggleUpdate={toggleUpdate}
                                       />
                                       {/* <Link
                                          onClick={() => toggleUpdate(row)}
                                          className="w-100 px-2 d-block text-center">
                                          <Edit
                                             className="mr-2"
                                             style={{
                                                width: '.8rem',
                                             }}
                                          />
                                          Edit
                                       </Link> */}
                                    </li>
                                    <li>
                                       <Link
                                          className="w-100 d-block text-center"
                                          onClick={() => toggleDelete(row)}>
                                          <Remove
                                             className="mr-2"
                                             style={{
                                                width: '.55rem',
                                             }}
                                          />
                                          Remove
                                       </Link>
                                    </li>
                                 </ul>
                              )}
                           </div>
                           <Link
                              to={`/teams/${row._id}`}
                              className="img mb-2 mt-4 d-flex justify-content-center">
                              <img
                                 src={img}
                                 alt="profile-img"
                                 className="rounded-circle img-fluid d-flex justify-content-center"
                                 style={{width: '3.5rem'}}
                              />
                           </Link>
                           <Link to={`/teams/${row._id}`} className="name mb-4">
                              <h4 className="d-flex pt-3 justify-content-center">
                                 {row.name}
                              </h4>
                              <p className="d-flex justify-content-center pt-1">
                                 {row.location}
                              </p>
                           </Link>
                        </div>
                     </div>
                  );
               })
            ) : (
               <td align="center" colSpan={5}>
                  No branch has been added on this platform
               </td>
            )}
         </div>
         {/* {open && ( */}
         <Modal
            isOpen={deletePermission}
            style={customStyles}
            onRequestClose={() => setDeletePermission(false)}>
            <div className="content role d-flex flex-column">
               <Delete className="deleted" style={{width: '6rem'}} />
               <h3 className="m-0 role d-flex justify-content-center py-2">
                  Delete Permission
               </h3>
               <p className="text-center role">
                  Are you sure you want to remove permission from <br /> this
                  application?
               </p>
               <div className="action-btn w-100 d-flex align-items-center justify-content-between">
                  <button
                     className="d-flex align-items-center justify-content-center role-btn cancel"
                     // onClick={() => setDeletePermission(false)}
                  >
                     Cancel
                  </button>
                  <button
                     className="d-flex align-items-center justify-content-center role-btn delete"
                     // onClick={() => handleDelete()}
                  >
                     Delete
                  </button>
               </div>
            </div>
         </Modal>
         {/* )} */}
         {/* {deleteTeam && ( */}
         <Modal
            isOpen={deleteTeam}
            style={customStyles}
            onRequestClose={() => setDeleteTeam(false)}>
            <div className="content role w-100 d-flex align-items-center flex-column">
               <Delete className="deleted" style={{width: '6rem'}} />
               <h3 className="m-0 d-flex role justify-content-center py-2">
                  Delete Team
               </h3>
               <p className="text-center role">
                  Are you sure you want to remove team from <br /> this
                  application?
               </p>
               <div className="action-btn w-100 d-flex align-items-center justify-content-between">
                  <button
                     className="role-btn d-flex align-items-center justify-content-center cancel"
                     onClick={() => setDeleteTeam(false)}>
                     Cancel
                  </button>
                  <button
                     className="role-btn d-flex align-items-center justify-content-center delete"
                     onClick={() => handleDelete()}>
                     Delete
                  </button>
               </div>
            </div>
         </Modal>
         {/* )} */}
      </MDBRow>
   );
}

// export default Teams;
export * from './branchMarketers';
export * from './marketerCustomers';
