import React, {useState} from 'react';
import {MDBRow} from 'mdbreact';
import Modal from 'react-modal';
import classNames from 'classnames';
import './index.scss';
import Admin from './Admin';
import Permissions from './Permission';
import Roles from './Roles';
import Security from './Security';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';

import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
import {ReactComponent as ArrowDown} from '../../_assets/icons/1arrow-down.svg';

export default function Settings() {
   const [shown, setShown] = useState(1);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);
   // };

   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      setModalIsOpen(false);
   };

   const [roleModalIsOpen, setRoleModalIsOpen] = useState(false);
   // const setRoleModalIsOpenToTrue = () => {
   //    setRoleModalIsOpen(true);
   // };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   const [isOpen, setIsOpen] = useState(false);
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
   };

   const [selected, setSelected] = useState(0);

   const options = [
      {label: 'Customer Relation', val: 0},
      {label: 'Admin', val: 1},
      {label: 'Super Admin', val: 2},
   ];

   return (
      <MDBRow className="settings-page m-0 w-100 mb-5">
         <div className="history d-flex w-100">
            <ul className="d-flex pl-3">
               <li>
                  <div
                     onClick={() => setShown(1)}
                     className={classNames('w-100 d-block', {
                        active: shown === 1,
                     })}>
                     Admin User Management
                  </div>
               </li>
               {/* <li>
                  <div
                     onClick={() => setShown(2)}
                     className={classNames('w-100 d-block', {
                        active: shown === 2,
                     })}>
                     Roles
                  </div>
               </li> */}
               {/* <li>
                  <div
                     onClick={() => setShown(3)}
                     className={classNames('w-100 d-block', {
                        active: shown === 3,
                     })}>
                     Permissions
                  </div>
               </li> */}
               <li>
                  <div
                     onClick={() => setShown(4)}
                     className={classNames('w-100 d-block', {
                        active: shown === 4,
                     })}>
                     Security
                  </div>
               </li>
            </ul>
         </div>

         {shown === 1 && <Admin />}
         {/* {shown === 2 && <Roles />} */}
         {/* {shown === 3 && <Permissions />} */}
         {shown === 4 && <Security />}
         {/* (
            <div className="security mt-3 w-100">
               <h3 className="py-5 bg-white">Some security stuff here...</h3>
            </div>
         )} */}

         <div className="add-new">
            <Modal
               isOpen={modalIsOpen}
               style={customStyles}
               onRequestClose={() => setModalIsOpen(false)}>
               <Formsy className="__form d-flex flex-column">
                  <h4 className="text-left mb-3">Invite New User</h4>
                  <p className="text-left loan mb-4">
                     Loans are transferred to customer's account
                  </p>
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-2 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="User Name"
                     className="input11 border-0 w-100"
                     placeholder="Finance Accountant"
                     validations="isEmail"
                     validationError="This is not a valid email!"
                     required
                  />
                  <div className="form-group w-100">
                     <label className="label mb-0">Role</label>
                     <div
                        onClick={toggleList}
                        className="input222 d-flex position-relative align-items-center justify-content-between ">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrowDown style={{width: '1rem'}} />
                        {isOpen && (
                           <div
                              onRequestClose={() => setIsOpen(false)}
                              className="list position-absolute">
                              <ul className="list-items">
                                 {options
                                    // .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             setIsOpen(false);
                                             setSelected(i.val);
                                          }}>
                                          {i.label}
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-2 mb-s-4"
                     useFocusedError
                     name="email"
                     type="email"
                     labelClassName="label"
                     labelText="Password"
                     className="input11 border-0 w-100"
                     placeholder="finance123"
                     validations="isEmail"
                     validationError="The email is invalid!"
                     required
                  />
                  <button className="w-100">
                     <div
                        onClick={setSecModalIsOpenToTrue}
                        className="submit-btn w-100">
                        Submit
                     </div>
                  </button>
               </Formsy>
            </Modal>

            <Modal
               isOpen={secModalIsOpen}
               style={customStyles}
               onRequestClose={() => setSecModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <h3 className="m-0 d-flex justify-content-center py-2">
                     Invite Sent
                  </h3>
                  <p>Invite has been sent to the mail</p>
               </div>
            </Modal>
         </div>

         <div className="add-role">
            <Modal
               isOpen={roleModalIsOpen}
               style={customStyles}
               onRequestClose={() => setRoleModalIsOpen(false)}>
               <Formsy className="__form form d-flex flex-column">
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="Role Name"
                     className="input11 border-0 w-100"
                     placeholder="Customer Relation"
                  />
                  <div className="form-group mb-0">
                     <label className="label">Role Description</label>
                     <textarea
                        className="input11 form-control"
                        id="textarea"
                        rows="7"
                        placeholder="Write role description"
                     />
                  </div>
                  <div class="form-group checkbox mb-0">
                     <input
                        type="checkbox"
                        class="form-control-input"
                        id="checkbox"
                     />
                  </div>
                  <button className="w-100">
                     <div to="/settings" className="submit-btn w-100">
                        Add Role
                     </div>
                  </button>
               </Formsy>
            </Modal>
         </div>
      </MDBRow>
   );
}
