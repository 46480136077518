import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {roleConstants, userConstants} from '../../_constants';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
} from '../../_helpers';

function* getRoles({data}) {
   yield put({type: roleConstants.REQUEST_ALL_ROLES});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      let rolesUri = `${roleConstants.ROLE_URI}?type=paginate`;

      if (data?.page) {
         rolesUri = `${rolesUri}&page=${data.page + 1}`;
      }
      if (data?.pagination) {
         rolesUri = `${roleConstants.ROLE_URI}`;
      }

      const rolesReq = createRequestWithToken(rolesUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, rolesReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: data?.pagination
            ? roleConstants.REQUEST_ALL_ROLES_WITHOUT_PAGINATION_SUCCESS
            : roleConstants.REQUEST_ALL_ROLES_SUCCESS,
         roles: jsonResponse,
      });
   } catch (error) {
      yield put({type: roleConstants.REQUEST_ALL_ROLES_ERROR});
   }
}

function* createRoleSaga({data}) {
   try {
      yield put({type: roleConstants.REQUEST_CREATE_ROLE});
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${roleConstants.ROLE_URI}`;
      const req = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: roleConstants.CREATE_ROLE_SUCCESS,
         role: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'role successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: roleConstants.CREATE_ROLE_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updateRoleSaga({data}) {
   try {
      yield put({type: roleConstants.REQUEST_UPDATE_ROLE});
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${roleConstants.ROLE_URI}${data.id}`;
      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: roleConstants.UPDATE_ROLE_SUCCESS,
         role: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'role successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: roleConstants.UPDATE_ROLE_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteRoleSaga({_id}) {
   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      yield put({type: roleConstants.REQUEST_DELETE_ROLE});

      const url = `${roleConstants.ROLE_URI}${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: roleConstants.DELETE_ROLE_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message
               ? jsonResponse.message
               : 'role successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: roleConstants.DELETE_ROLE_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getRolesWatcher() {
   yield takeLatest(roleConstants.GET_ALL_ROLES, getRoles);
}

function* createRoleWatcher() {
   yield takeLatest(roleConstants.CREATE_ROLE, createRoleSaga);
}

function* updateRoleWatcher() {
   yield takeLatest(roleConstants.UPDATE_ROLE, updateRoleSaga);
}

function* deleteRoleWatcher() {
   yield takeLatest(roleConstants.DELETE_ROLE, deleteRoleSaga);
}

export default function* rootSaga() {
   yield all([
      getRolesWatcher(),
      createRoleWatcher(),
      updateRoleWatcher(),
      deleteRoleWatcher(),
   ]);
}
