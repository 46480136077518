import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBInput} from 'mdbreact';
import {Link} from 'react-router-dom';
import './index.scss';

import LetteredAvatar from 'lettered-avatar';
import {CSVLink} from 'react-csv';

import {ReactComponent as XIcon} from '../../_assets/icons/1x-icon.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icons/1ssearch.svg';
import {ReactComponent as Filter} from '../../_assets/icons/1filter.svg';
// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as ArrDown} from '../../_assets/icons/1arrow-down.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {customerActions} from '../../_actions';

export function Customer() {
   const dispatch = useDispatch();

   // useEffect(() => {
   //    dispatch(customerActions.getLoanCustomers());
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, []);
   useEffect(
      (
         model = {
            query: {},
         },
      ) => {
         dispatch(customerActions.getLoanCustomers(model));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   const IsRequestingLoanCustomers = useSelector(
      (s) => s.customer.IsRequestingLoanCustomers,
   );
   const IsSearchingCustomer = useSelector(
      (s) => s.customer.IsSearchingCustomer,
   );
   const loanCustomersList = useSelector((s) => s.customer.loanCustomersList);
   const pagination = useSelector((s) => s.customer.loanPagination);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(customerActions.getLoanCustomers(model));
   };

   // const [isOpen] = useState(false);
   const [searchInput, setSearchInput] = useState('');

   const searchCustomer = () => {
      // const model = {
      //    searchName: searchInput,
      //    type: 'loan',
      // };
      const model = {
         query: searchInput,
         // field: 'firstname',
         type: 'loan',
         limit: 10,
         sort: {
            firstname: 1,
         },
      };
      dispatch(customerActions.searchCustomer(model));
   };

   useEffect(() => {
      if (searchInput === '') {
         dispatch(customerActions.getLoanCustomers());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchInput]);

   const [isStatusOpen, setIsStatusOpen] = useState(false);
   const toggleStatusList = () => {
      setIsStatusOpen((wasOpen) => !wasOpen);
      setSelect((wasOpen) => !wasOpen);
   };
   const [select, setSelect] = useState(false);
   const [isOpen, setIsOpen] = useState(false);
   const [toDate, setToDate] = useState(false);
   const [isDateOpen, setIsDateOpen] = useState(false);
   const toggleDate = () => {
      // setIsDateOpen(true);
      setIsDateOpen((wasOpen) => !wasOpen);
   };
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
      setToDate(false);
   };

   const clearText = () => {
      // setIsDateOpen(true);
      setSearchInput('');
   };
   const handleFilter = (e, val) => {
      e.stopPropagation();
      setIsStatusOpen(false);
      console.log('val', val);
      const filterModel = {
         status: val === 1 ? true : false,
         filter: true,
      };

      dispatch(customerActions.getLoanCustomers(filterModel));
   };

   const [selected, setSelected] = useState(0);
   const options = [
      {label: 'Status', val: 0},
      {label: 'Active', val: 1},
      {label: 'Inactive', val: 2},
   ];

   return (
      <MDBRow className="customer-page m-0 w-100 mb-5">
         <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search ">
               <div className="input-group d-flex align-items-center">
                  <input
                     type="text"
                     className="for"
                     placeholder="Search"
                     aria-label="Search"
                     value={searchInput}
                     onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <XIcon
                     className="i-x"
                     style={{width: '1rem'}}
                     onClick={clearText}
                  />
                  <span className="i-search" onClick={searchCustomer}>
                     <SearchIcon style={{width: '1rem'}} />
                  </span>
                  {isOpen && (
                     <div className="collaps">
                        <div
                           onClick={toggleStatusList}
                           className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                           <span className="d-block">
                              {options[selected].label}
                           </span>
                           <ArrDown className="ml-4" />
                           {isStatusOpen && (
                              <div className="status-list position-absolute">
                                 <ul className="">
                                    {options
                                       .filter((i) => i.val !== 0)
                                       .map((i, n) => (
                                          <li
                                             key={n}
                                             onClick={(e) => {
                                                handleFilter(e, i.val);
                                                // e.stopPropagation();
                                                // setIsOpen(false);
                                                // setSelected(i.val);
                                             }}>
                                             <Link className="w-100 d-block">
                                                {i.label}
                                             </Link>
                                          </li>
                                       ))}
                                 </ul>
                              </div>
                           )}
                        </div>
                     </div>
                  )}
               </div>
            </div>

            <div className="filter d-flex">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               {loanCustomersList.length > 0 && (
                  <CSVLink data={loanCustomersList} className="csv">
                     Download CSV
                  </CSVLink>
               )}
               <div
                  onClick={toggleList}
                  className="d-flex menu menu2 align-items-center">
                  <Filter className="mr-2" style={{width: '1rem'}} />
                  Filter
               </div>
               {/* {isOpen && (
                  <div className="collaps">
                     <div
                        onClick={toggleDate}
                        className="collaps-item mr-5 position-relation">
                        Date Range <ArrDown className="ml-4" />
                        {isDateOpen && (
                           <div className="date p-3 position-absolute">
                              <MDBDatePicker getValue={this.getPickerValue} />
                              <h5>Some date functions here...</h5>
                              <p>down here...</p>
                           </div>
                        )}
                     </div>
                     <div
                        onClick={toggleStatusList}
                        className="collaps-item d-flex position-relative align-items-center justify-content-between dropdwn">
                        <span className="d-block">
                           {options[selected].label}
                        </span>
                        <ArrDown className="ml-4" />
                        {isStatusOpen && (
                           <div className="status-list position-absolute">
                              <ul className="">
                                 {options
                                    .filter((i) => i.val !== 0)
                                    .map((i, n) => (
                                       <li
                                          key={n}
                                          onClick={(e) => {
                                             setSelected(i.val);
                                          }}>
                                          <Link className="w-100 d-block">
                                             {i.label}
                                          </Link>
                                       </li>
                                    ))}
                              </ul>
                           </div>
                        )}
                     </div>
                  </div>
               )} */}
            </div>
         </div>

         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile w-100"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr className="d-table-row">
                     <th scope="col">Name</th>
                     <th scope="col">Email</th>
                     <th scope="col">Phone Number</th>
                     <th scope="col">Status</th>
                     {/* <th scope="col">Limit</th>
                     <th scope="col">Interest</th>
                     <th scope="col">Refund</th>
                     <th scope="col">Balance</th>
                     <th scope="col">Status</th>
                     <th scope="col">Due Date</th> */}
                  </tr>
               </MDBTableHead>

               <MDBTableBody>
                  {IsRequestingLoanCustomers || IsSearchingCustomer ? (
                     <div className="loader d-flex justify-content-center w-100 py-5">
                        <Loader className="ball-clip-rotate d-flex justify-content-center w-100 py-5 align-items-center">
                           <div></div>
                        </Loader>
                     </div>
                  ) : loanCustomersList?.length > 0 ? (
                     loanCustomersList.map((row) => {
                        return (
                           <Link
                              //to="/customer/loan/:uuid"
                              // to={`/customer/loan/${row.user_id}`}
                              to={{
                                 pathname: `/customer/loan/${row?.user_id}`,
                                 state: {customerId: row?._id},
                              }}
                              key={row?._id}
                              className="d-table-row align-items-center justify-content-center">
                              <th scope="row">
                                 {select && (
                                    <MDBInput
                                       className="mr-2"
                                       style={{
                                          width: '.65rem',
                                          height: '.65rem',
                                       }}
                                       type="checkbox"
                                       id="checkbox"
                                    />
                                 )}
                                 <LetteredAvatar
                                    name={row.firstname + ' ' + row.lastname}
                                    options={{
                                       size: 50,
                                       twoLetter: true,
                                       //shape: 'square',
                                       bgColor: '',
                                       imgClass: 'image-responsive user-image',
                                       imgWidth: '3rem',
                                       imgHeight: '3rem',
                                    }}
                                 />
                                 <span>
                                    {row.firstname + ' ' + row.lastname}
                                 </span>
                              </th>
                              <td>{row.users_details?.email}</td>
                              <td>
                                 {row.users_details?.phone_number?.replace(
                                    /^.{3}/g,
                                    '0',
                                 )}
                              </td>
                              {/* <td>
                                 {row.phone_number?.replace(/^.{3}/g, '0')}
                              </td> */}
                              <td>
                                 {row?.is_active ? 'Active' : 'Not Active'}
                              </td>
                              {/* <td>
                                 {row.loan_details
                                    ? `N${numberWithCommas(
                                         row.loan_details?.limit,
                                      )}`
                                    : 'none'}
                              </td>
                              <td>
                                 {row.loan_details
                                    ? `${numberWithCommas(
                                         row.loan_details?.interest,
                                      )}%`
                                    : 'none'}
                              </td> */}
                              {/* <td>55,000</td>
                              <td>
                                 {row.loan_details
                                    ? `N${numberWithCommas(
                                         row.loan_details?.limit,
                                      )}`
                                    : 'none'}
                              </td>
                              <td>{capitalizeFirstLetter(row.loan_status)}</td>
                              <td>12/12/2021</td> */}
                           </Link>
                        );
                     })
                  ) : (
                     <th scope="row">
                        <td align="center" colSpan={5}>
                           No result to display
                        </td>
                     </th>
                  )}
               </MDBTableBody>
            </MDBTable>
         </div>

         {!IsRequestingLoanCustomers && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}

         {/* <div className="pagination d-flex align-items-center mt-3">
            <Link
               to="/"
               className="arrow d-flex align-items-center justify-content-center">
               <Prev style={{width: '1.3rem'}} />
            </Link>
            <ul className="d-flex justify-content-center mx-4">
               <li className="active">
                  <Link to="/" className="active">
                     1
                  </Link>
               </li>
               <li>
                  <Link to="/">2</Link>
               </li>
               <li>
                  <Link to="/">3</Link>
               </li>
               <li>
                  <Link to="/">4</Link>
               </li>
            </ul>
            <Link
               to="/"
               className="arrow d-flex align-items-center justify-content-center">
               <Next style={{width: '1.3rem'}} />
            </Link>
         </div> */}
         {/* <div className="sum-table d-flex justify-content-center align-items-center w-100 mt-3">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableBody>
                  <div className="d-table-row align-items-center justify-content-center">
                     <th scope="row">
                        <span>Sum for Page 1</span>
                     </th>
                     <td style={{visibility: 'hidden'}}>emeka@gmail.com</td>
                     <td style={{visibility: 'hidden'}}>07036334</td>
                     <td>50,000</td>
                     <td style={{visibility: 'hidden'}}>50,000</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td style={{visibility: 'hidden'}}>12/12/2021</td>
                  </div>
               </MDBTableBody>
            </MDBTable>
         </div> */}
         {/* <Link
                     to="/customer/loan/:uuid"
                     className="d-table-row align-items-center">
                     <th
                        scope="row"
                        className="d-flex justify-content-center align-items-center">
                        {select && (
                           <MDBInput
                              className="mr-2"
                              style={{width: '.65rem', height: '.65rem'}}
                              type="checkbox"
                              id="checkbox"
                           />
                        )}
                        <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid"
                           style={{width: '3rem', height: '3rem'}}
                        />
                        <span>Emeka Chuks</span>
                     </th>
                     <td>emeka@gmail.com</td>
                     <td>07036334745</td>
                     <td>50,000</td>
                     <td>10%</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td>12/12/2021</td>
                  </Link>
                  <Link
                     to="/customer/loan/:uuid"
                     className="d-table-row align-items-center">
                     <th
                        scope="row"
                        className="d-flex justify-content-center align-items-center">
                        {select && (
                           <MDBInput
                              className="mr-2"
                              style={{width: '.65rem', height: '.65rem'}}
                              type="checkbox"
                              id="checkbox"
                           />
                        )}
                        <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid"
                           style={{width: '3rem', height: '3rem'}}
                        />
                        <span>Emeka Chuks</span>
                     </th>
                     <td>emeka@gmail.com</td>
                     <td>07036334745</td>
                     <td>50,000</td>
                     <td>10%</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td>12/12/2021</td>
                  </Link>
                  <Link
                     to="/customer/loan/:uuid"
                     className="d-table-row align-items-center">
                     <th
                        scope="row"
                        className="d-flex justify-content-center align-items-center">
                        {select && (
                           <MDBInput
                              className="mr-2"
                              style={{width: '.65rem', height: '.65rem'}}
                              type="checkbox"
                              id="checkbox"
                           />
                        )}
                        <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid"
                           style={{width: '3rem', height: '3rem'}}
                        />
                        <span>Emeka Chuks</span>
                     </th>
                     <td>emeka@gmail.com</td>
                     <td>07036334745</td>
                     <td>50,000</td>
                     <td>10%</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td>12/12/2021</td>
                  </Link>
                  <Link
                     to="/customer/loan/:uuid"
                     className="d-table-row align-items-center">
                     <th
                        scope="row"
                        className="d-flex justify-content-center align-items-center">
                        {select && (
                           <MDBInput
                              className="mr-2"
                              style={{width: '.65rem', height: '.65rem'}}
                              type="checkbox"
                              id="checkbox"
                           />
                        )}
                        <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid"
                           style={{width: '3rem', height: '3rem'}}
                        />
                        <span>Emeka Chuks</span>
                     </th>
                     <td>emeka@gmail.com</td>
                     <td>07036334745</td>
                     <td>50,000</td>
                     <td>10%</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td>12/12/2021</td>
                  </Link>
                  <Link
                     to="/customer/loan/:uuid"
                     className="d-table-row align-items-center">
                     <th
                        scope="row"
                        className="d-flex justify-content-center align-items-center">
                        {select && (
                           <MDBInput
                              className="mr-2"
                              style={{width: '.65rem', height: '.65rem'}}
                              type="checkbox"
                              id="checkbox"
                           />
                        )}
                        <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid"
                           style={{width: '3rem', height: '3rem'}}
                        />
                        <span>Emeka Chuks</span>
                     </th>
                     <td>emeka@gmail.com</td>
                     <td>07036334745</td>
                     <td>50,000</td>
                     <td>10%</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td>12/12/2021</td>
                  </Link>
                  <Link
                     to="/customer/loan/:uuid"
                     className="d-table-row align-items-center">
                     <th
                        scope="row"
                        className="d-flex justify-content-center align-items-center">
                        {select && (
                           <MDBInput
                              className="mr-2"
                              style={{width: '.65rem', height: '.65rem'}}
                              type="checkbox"
                              id="checkbox"
                           />
                        )}
                        <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid"
                           style={{width: '3rem', height: '3rem'}}
                        />
                        <span>Emeka Chuks</span>
                     </th>
                     <td>emeka@gmail.com</td>
                     <td>07036334745</td>
                     <td>50,000</td>
                     <td>10%</td>
                     <td>55,000</td>
                     <td>20,000</td>
                     <td>20,000</td>
                     <td>12/12/2021</td>
                  </Link> */}
      </MDBRow>
   );
}

export * from './view';
