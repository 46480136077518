import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'react-multi-carousel/lib/styles.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from './App';
import {Provider} from 'react-redux';
import store from './_helpers/store';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <Router>
            <App />
         </Router>
      </Provider>
      ,
   </React.StrictMode>,
   document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
