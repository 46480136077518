import React from 'react';
import {MDBListGroup, MDBListGroupItem} from 'mdbreact';
import {Link, matchPath, useLocation} from 'react-router-dom';
import {useLocationCode} from '../../_navigation/useLocationCode';
import classNames from 'classnames';
import './index.scss';

import {ReactComponent as Dashboard} from '../../_assets/icons/1dashboard.svg';
import {ReactComponent as Customer} from '../../_assets/icons/1user2.svg';
import {ReactComponent as Merchant} from '../../_assets/icons/1user2.svg';
import {ReactComponent as Loan} from '../../_assets/icons/11wallet.svg';
import {ReactComponent as Settlement} from '../../_assets/icons/1swap.svg';
// import {ReactComponent as Admin} from '../../_assets/icons/1users.svg';
import {ReactComponent as Team} from '../../_assets/icons/3User.svg';
import {ReactComponent as Settings} from '../../_assets/icons/1settings.svg';
import {ReactComponent as CaretDown} from '../../_assets/icons/1arrow-down.svg';
import {ReactComponent as Logout} from '../../_assets/icons/Logout.svg';
import Brand from '../../_assets/images/BuyGo.png';
import {useSelector} from 'react-redux';
import {userConstants} from '../../_constants';

export default function Sidebar({logoutAdmin}) {
   const {pathname} = useLocation();

   const viewLoanCustomer = matchPath(pathname, '/customer/loan/:uuid');
   const viewWalletCustomer = matchPath(pathname, '/customer/wallet/:uuid');
   const viewRegisteredCustomer = matchPath(
      pathname,
      '/customer/registered/:uuid',
   );
   const loanCustomer = matchPath(pathname, '/customer/loan/');
   const walletCustomer = matchPath(pathname, '/customer/wallet/');
   const registeredCustomer = matchPath(pathname, '/customer/registered/');

   const viewRegularMerchant = matchPath(pathname, '/merchant/regular/:uuid');
   const viewPremiumMerchant = matchPath(pathname, '/merchant/premium/:uuid');
   const regularMerchant = matchPath(pathname, '/merchant/regular');
   const premiumMerchant = matchPath(pathname, '/merchant/premium');
   // const viewBranches = matchPath(pathname, '/teams');
   const viewBranchMarketers = matchPath(pathname, '/teams/:uuid');
   const viewMarketerCustomers = matchPath(
      pathname,
      '/teams/marketers/:uuid/customers',
   );
   const code = useLocationCode();

   const logout = () => {
      logoutAdmin();
   };

   const userDetails = useSelector((s) => s.user.user);
   const userDetail = userDetails?.user;

   return code === 9 ? null : (
      <div className="sidebar">
         <div className="sidebar-inner">
            <div className="logo d-flex justify-content-center pt-3">
               <Link to="/dashboard">
                  <img
                     src={Brand}
                     alt=""
                     className="img-fluid"
                     style={{
                        width: '100%',
                     }}
                  />
               </Link>
            </div>
            <div className="sidebar-panel d-flex justify-content-center">
               <MDBListGroup className="w-100">
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 1,
                        })}
                        to="/dashboard">
                        <Dashboard
                           width=".85rem"
                           height=".85rem"
                           className="mr-2"
                        />{' '}
                        Dashboard
                     </Link>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 2,
                        })}
                        to="/customer/loan">
                        <Customer
                           width=".9rem"
                           height=".9rem"
                           className="mr-2"
                        />{' '}
                        Customer{' '}
                        <CaretDown className="ml-1" style={{width: '.5rem'}} />
                        <div className="dropdownmenu position-absolute w-75">
                           <li>
                              <Link
                                 className={classNames('w-100 d-block', {
                                    active: viewLoanCustomer || loanCustomer,
                                 })}
                                 to="/customer/loan">
                                 Loan
                              </Link>
                           </li>
                           <li>
                              <Link
                                 className={classNames('w-100 d-block', {
                                    active:
                                       viewWalletCustomer || walletCustomer,
                                 })}
                                 to="/customer/wallet">
                                 Wallet
                              </Link>
                           </li>
                           <li>
                              <Link
                                 className={classNames('w-100 d-block', {
                                    active:
                                       viewRegisteredCustomer ||
                                       registeredCustomer,
                                 })}
                                 to="/customer/registered">
                                 Registered
                              </Link>
                           </li>
                        </div>
                     </Link>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 3,
                        })}
                        to="/merchant/regular">
                        <Merchant
                           width=".9rem"
                           height=".9rem"
                           className="mr-2"
                        />{' '}
                        Merchant{' '}
                        <CaretDown className="ml-1" style={{width: '.5rem'}} />
                        <div className="dropdownmenu position-absolute w-75">
                           <li>
                              <Link
                                 className={classNames('w-100 d-block', {
                                    active:
                                       viewRegularMerchant || regularMerchant,
                                 })}
                                 to="/merchant/regular">
                                 Regular
                              </Link>
                           </li>
                           <li>
                              <Link
                                 className={classNames('w-100 d-block', {
                                    active:
                                       viewPremiumMerchant || premiumMerchant,
                                 })}
                                 to="/merchant/premium">
                                 Premium
                              </Link>
                           </li>
                        </div>
                     </Link>
                  </MDBListGroupItem>
                  {!userDetail?.role.includes(
                     userConstants.REGULAR_ADMIN_ROLE_ID,
                  ) && (
                     <MDBListGroupItem className="list-item p-0 mb-2">
                        <Link
                           className={classNames('w-100 d-block', {
                              active: code === 4,
                           })}
                           to="/loan">
                           <Loan
                              width=".8rem"
                              height=".8rem"
                              className="mr-2"
                           />{' '}
                           Loan
                        </Link>
                     </MDBListGroupItem>
                  )}
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 5,
                        })}
                        to="/settlement">
                        <Settlement
                           width=".85rem"
                           height=".85rem"
                           className="mr-2"
                        />{' '}
                        Settlement
                     </Link>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 6,
                        })}
                        to="/transactions">
                        <Settlement
                           width=".85rem"
                           height=".85rem"
                           className="mr-2"
                        />{' '}
                        Transactions
                     </Link>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active:
                              code === 7 ||
                              viewBranchMarketers ||
                              viewMarketerCustomers,
                        })}
                        to="/teams">
                        <Team width=".85rem" height=".85rem" className="mr-2" />{' '}
                        Teams
                     </Link>
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 6,
                        })}
                        to="/transactions">
                        <Admin width=".9rem" height=".9rem" className="mr-2" />{' '}
                        Transactions
                     </Link>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem className="list-item p-0 mb-2">
                     <Link
                        className={classNames('w-100 d-block', {
                           active: code === 8,
                        })}
                        to="/settings">
                        <Settings
                           width=".9rem"
                           height=".9rem"
                           className="mr-2"
                        />{' '}
                        Settings
                     </Link>
                  </MDBListGroupItem>
                  <MDBListGroupItem
                     onClick={logout}
                     className="list-item p-0 mb-3">
                     <Link
                        className={classNames('w-100 d-block')}
                        to="/settings">
                        <Logout width=".9rem" height=".9rem" className="mr-2" />{' '}
                        Logout
                     </Link>
                  </MDBListGroupItem>
               </MDBListGroup>
            </div>
         </div>
      </div>
   );
}
