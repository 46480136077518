import React, {useEffect} from 'react';
import './index.scss';

export default function Snackbar({message, onClose, variant}) {
   useEffect(() => {
      const timer = setTimeout(() => {
         onClose();
      }, 3000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message]);

   // console.log('variant', variant);

   return (
      <div
         class={
            'notice d-flex align-items-center justify-content-center w-100 ' +
            variant
         }>
         <p style={{textAlign: 'center'}}>
            {message}
            {/* Your Details has been Updated Successfully */}
         </p>
      </div>
   );
}

// export default function Snackbar({message, onClose}) {
//    useEffect(() => {
//       const timer = setTimeout(() => {
//          onClose();
//       }, 3000);
//       return () => clearTimeout(timer);
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//    }, [message]);

//    return (
//       <div className="notice success d-flex align-items-center justify-content-center w-100">
//          <p style={{textAlign: 'center'}}>
//             {message}
//             {/* Your Details has been Updated Successfully */}
//          </p>
//       </div>
//    );
// }
