import {merchantConstants} from '../_constants';

const getPremiumMerchant = (model) => ({
   type: merchantConstants.GET_PREMIUM_MERCHANTS,
   data: model,
});

const getAllMerchant = (model) => ({
   type: merchantConstants.GET_ALL_MERCHANTS,
   data: model,
});

const verifyMerchant = (model) => ({
   type: merchantConstants.VERIFY_MERCHANT,
   data: model,
});

const getMerchantDetails = (model) => ({
   type: merchantConstants.GET_MERCHANT_DETAILS,
   data: model,
});

const getMerchantTransactionHistory = (model) => ({
   type: merchantConstants.GET_MERCHANT_TRANSACTION_HISTORY,
   data: model,
});

const getMerchantSettlementHistory = (model) => ({
   type: merchantConstants.GET_MERCHANT_SETTLEMENT_HISTORY,
   data: model,
});

const upgradeMerchant = (model) => ({
   type: merchantConstants.UPGRADE_MERCHANT,
   data: model,
});

const searchMerchant = (model) => ({
   type: merchantConstants.SEARCH_MERCHANT,
   data: model,
});

export const merchantActions = {
   getPremiumMerchant,
   getAllMerchant,
   verifyMerchant,
   getMerchantDetails,
   getMerchantTransactionHistory,
   getMerchantSettlementHistory,
   upgradeMerchant,
   searchMerchant,
};
