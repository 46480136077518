import {combineReducers} from 'redux';
import {transactionConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingAllTransactions = (state = false, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_TRANSACTIONS:
         return true;
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_ERROR:
         return false;
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS:
         return false;
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsSearchingTransaction = (state = false, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_SEARCH_TRANSACTION:
         return true;
      case transactionConstants.SEARCH_TRANSACTION_ERROR:
         return false;
      case transactionConstants.SEARCH_TRANSACTION_SUCCESS:
         return false;
      case transactionConstants.SEARCH_TRANSACTION_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   // title: '',
   // state_id: '',
   // industry_id: '',
   // specialization_id: '',
   // closing_date: '',
   // isPublished: '',
};

const allTransactionsList = (state = [], action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS: {
         return action.allTransactions.data.docs;
      }
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case transactionConstants.SEARCH_TRANSACTION_SUCCESS: {
         return action.transaction.data.docs;
      }
      // case transactionConstants.SEARCH_TRANSACTION_SUCCESS_WITHOUT_DATA:
      //    return updateObject(state, emptyState);
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.allTransactions.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      // case transactionConstants.SEARCH_TRANSACTION_SUCCESS: {
      //    const {hasNextPage, nextPage, totalDocs, limit, page} =
      //       action.Transaction.data;
      //    const result = {hasNextPage, nextPage, totalDocs, limit, page};

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllTransactions,
   allTransactionsList,
   IsSearchingTransaction,
   pagination,
});

export default rootReducer;
