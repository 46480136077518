import {appConstants} from './app.constant';

const login = 'admin/signin';
const user = 'user';
const admin = 'admin';
const bankDetails = 'bank_details/bvn';
const acceptInvitation = 'admin/accept_invitation';

export const userConstants = {
   LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
   LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
   LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

   LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
   LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
   LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

   GET_REQUEST: 'USER_GET_REQUEST',
   GET_SUCCESS: 'USER_GET_SUCCESS',
   GET_FAILURE: 'USER_GET_FAILURE',

   REQUEST_ACCEPT_INVITATION: 'REQUEST_ACCEPT_INVITATION',
   ACCEPT_INVITATION_SUCCESS: 'ACCEPT_INVITATION_SUCCESS',
   ACCEPT_INVITATION_ERROR: 'ACCEPT_INVITATION_ERROR',

   REQUEST_CREATE_ADMIN_USER: 'REQUEST_CREATE_ADMIN_USER',
   CREATE_ADMIN_USER_SUCCESS: 'CREATE_ADMIN_USER_SUCCESS',
   CREATE_ADMIN_USER_ERROR: 'CREATE_ADMIN_USER_ERROR',

   REQUEST_VERIFY_EMAIL: 'REQUEST_VERIFY_EMAIL',
   VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
   VERIFY_EMAIL_ERROR: 'VERIFY_EMAIL_ERROR',

   REQUEST_RESEND_EMAIL_LINK: 'REQUEST_RESEND_EMAIL_LINK',
   RESEND_EMAIL_LINK_SUCCESS: 'RESEND_EMAIL_LINK_SUCCESS',
   RESEND_EMAIL_LINK_ERROR: 'RESEND_EMAIL_LINK_ERROR',

   REQUEST_SET_PASSWORD: 'REQUEST_SET_PASSWORD',
   SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
   SET_PASSWORD_ERROR: 'SET_PASSWORD_ERROR',

   REQUEST_CHANGE_PASSWORD: 'REQUEST_CHANGE_PASSWORD',
   CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
   CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

   REQUEST_VERIFY_USER_BVN: 'REQUEST_VERIFY_USER_BVN',
   VERIFY_USER_BVN_SUCCESS: 'VERIFY_USER_BVN_SUCCESS',
   VERIFY_CUSTOMER_USER_BVN_SUCCESS: 'VERIFY_CUSTOMER_USER_BVN_SUCCESS',
   VERIFY_USER_BVN_ERROR: 'VERIFY_USER_BVNL_ERROR',

   REQUEST_ALL_USERS: 'REQUEST_ALL_USERS',
   REQUEST_ALL_USERS_SUCCESS: 'REQUEST_ALL_USERS_SUCCESS',
   REQUEST_ALL_USERS_ERROR: 'REQUEST_ALL_USERS_ERROR',

   REQUEST_EXPORT_ALL_USERS: 'REQUEST_EXPORT_ALL_USERS',
   REQUEST_EXPORT_ALL_USERS_SUCCESS: 'REQUEST_EXPORT_ALL_USERS_SUCCESS',
   REQUEST_EXPORT_ALL_USERS_ERROR: 'REQUEST_EXPORT_ALL_USERS_ERROR',

   REQUEST_ALL_ADMIN_USERS: 'REQUEST_ALL_ADMIN_USERS',
   REQUEST_ALL_ADMIN_USERS_SUCCESS: 'REQUEST_ALL_ADMIN_USERS_SUCCESS',
   REQUEST_ALL_ADMIN_USERS_ERROR: 'REQUEST_ALL_ADMIN_USERS_ERROR',

   REQUEST_ADMIN_USERS: 'REQUEST_ADMIN_USERS',
   REQUEST_ADMIN_USERS_SUCCESS: 'REQUEST_ADMIN_USERS_SUCCESS',
   REQUEST_ADMIN_USERS_ERROR: 'REQUEST_ADMIN_USERS_ERROR',

   REQUEST_REGISTER_ADMIN_USER: 'REQUEST_REGISTER_ADMIN_USER',
   REGISTER_ADMIN_USER_SUCCESS: 'REGISTER_ADMIN_USER_SUCCESS',
   REGISTER_ADMIN_USER_ERROR: 'REGISTER_ADMIN_USER_ERROR',

   REQUEST_UPDATE_ADMIN_USER: 'REQUEST_UPDATE_ADMIN_USER',
   UPDATE_ADMIN_USER_SUCCESS: 'UPDATE_ADMIN_USER_SUCCESS',
   UPDATE_ADMIN_USER_ERROR: 'UPDATE_ADMIN_USER_ERROR',

   REQUEST_DELETE_ADMIN_USER: 'REQUEST_DELETE_ADMIN_USER',
   DELETE_ADMIN_USER_SUCCESS: 'DELETE_ADMIN_USER_SUCCESS',
   DELETE_ADMIN_USER_ERROR: 'DELETE_ADMIN_USER_ERROR',

   REQUEST_SEARCH_ADMIN_USER: 'REQUEST_SEARCH_ADMIN_USER',
   SEARCH_ADMIN_USER_SUCCESS: 'SEARCH_ADMIN_USER_SUCCESS',
   SEARCH_ADMIN_USER_ERROR: 'SEARCH_ADMIN_USER_ERROR',
   SEARCH_ADMIN_USER_SUCCESS_WITHOUT_DATA:
      'SEARCH_ADMIN_USER_SUCCESS_WITHOUT_DATA',

   GET_USER: 'GET_USER',
   LOGIN: 'LOGIN',
   LOGOUT: 'LOGOUT',

   GET_ADMIN_USERS: 'GET_ADMIN_USERS',
   GET_ALL_ADMIN_USERS: 'GET_ALL_ADMIN__USERS',
   FETCH_ALL_USERS: 'FETCH_ALL_USERS',

   CHANGE_PASSWORD: 'CHANGE_PASSWORD',
   REGISTER_ADMIN_USER: 'REGISTER_ADMIN_USER',
   ACCEPT_INVITATION: 'ACCEPT_INVITATION',

   CREATE_ADMIN_USER: 'CREATE_ADMIN_USER',
   VERIFY_EMAIL: 'VERIFY_EMAIL',
   RESEND_EMAIL_LINK: 'RESEND_EMAIL_LINK',

   SET_PASSWORD: 'SET_PASSWORD',
   VERIFY_USER_BVN: 'VERIFY_USER_BVN',
   UPDATE_ADMIN_USER: 'UPDATE_ADMIN_USER',
   DELETE_ADMIN_USER: 'DELETE_ADMIN_USER',

   EXPORT_ADMIN_USERS: 'EXPORT_ADMIN_USERS',
   SEARCH_ADMIN_USER: 'SEARCH_ADMIN_USER',
   TOKEN_HAS_EXPIRED: 'TOKEN_HAS_EXPIRED',
   ALL_USER_KEY: 'all_user',

   ADMIN_USER_KEY: 'admin_user',
   // ADMIN_ROLE_ID: '60d9a0e8ebe692002293dda3', //test
   // ADMIN_ROLE_ID: '669e8ef31f6e50d15639637f', //test
   // ADMIN_ROLE_ID: '6135fb4af931706b4e131e3d', //prod
   ADMIN_ROLE_ID: '66684e41d3bed7453c3db339', //prod
   REGULAR_ADMIN_ROLE_ID: '669e8fc23800abd2800e22ad', //prod
   INTER_ADMIN_ROLE_ID: '669e8ef31f6e50d15639637f', //prod
   ADMIN: admin,

   // REGISTER_ADMIN_URI: `${appConstants.BASE_URI}${user}/add_admin`,
   REGISTER_ADMIN_URI: `${appConstants.BASE_URI}${admin}`,
   ADD_USER_TO_ADMIN_URI: `${appConstants.BASE_URI}${acceptInvitation}`,
   VERIFY_EMAIL_URI: `${appConstants.BASE_URI}${user}/verify_email/`,
   RESEND_EMAIL_LINK_URI: `${appConstants.BASE_URI}${user}/resend_mail/`,
   LOGIN_URI: `${appConstants.BASE_URI}${user}/${login}`,
   GET_ADMIN_USER_URI: `${appConstants.BASE_URI}${admin}`,
   GET_USER_URI: `${appConstants.BASE_URI}${user}`,
   VERIFY_USER_BVN_URI: `${appConstants.BASE_URI}${bankDetails}`,
   DELETE_ADMIN_USER_URI: `${appConstants.BASE_URI}${admin}`,
   CREATE_ADMIN_USER_URI: `${appConstants.BASE_URI}user/admin_user`,
};
