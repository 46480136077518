import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import LetteredAvatar from 'lettered-avatar';

import './index.scss';
import './view.scss';

// import img from '../../_assets/icons/1custprofile.svg';
// import img2 from '../../_assets/icons/1user-hash.svg';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {
   numberWithCommas,
   readableDateString,
   capitalizeFirstLetter,
} from '../../_helpers';
import {merchantActions} from '../../_actions';

export default function TransactionHistory({match}) {
   const dispatch = useDispatch();
   const IsRequestingMerchantTransactionHistory = useSelector(
      (s) => s.merchant.IsRequestingMerchantTransactionHistory,
   );
   const merchantTransactionList = useSelector(
      (s) => s.merchant.merchantTransactionList,
   );
   const pagination = useSelector((s) => s.merchant.transactionPagination);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      const {uuid} = match.params;

      const model = {
         page: pages,
         uuid: uuid,
         type: 'transaction',
         user: 'merchant',
      };

      dispatch(merchantActions.getMerchantTransactionHistory(model));
   };

   return (
      <>
         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Company Name</th>
                     <th scope="col">Product</th>
                     <th scope="col">Amount</th>
                     <th scope="col">Payment Date</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingMerchantTransactionHistory ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {merchantTransactionList?.length > 0 ? (
                        merchantTransactionList.map((row) => {
                           return (
                              <div className="d-table-row">
                                 <th scope="row">
                                    {/* <img
                                       src={img2}
                                       alt="profile-img"
                                       className="rounded-circle img-fluid"
                                       style={{width: '3rem', height: '3rem'}}
                                    /> */}
                                    <LetteredAvatar
                                       name={row.sender_name}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass:
                                             'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>{row.sender_name}</span>
                                 </th>
                                 <td>{capitalizeFirstLetter(row.narration)}</td>
                                 <td>{numberWithCommas(row.amount)}</td>
                                 <td>{readableDateString(row.created_at)}</td>
                              </div>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           This merchant has not performed any transaction on
                           this platform
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {!IsRequestingMerchantTransactionHistory && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </>
   );
}
