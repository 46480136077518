import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBRow} from 'mdbreact';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import classNames from 'classnames';
import LetteredAvatar from 'lettered-avatar';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';

import './index.scss';
import './view.scss';
import TransactionHistory from './transactionHistory';
import SettlementHistory from './settlementHistory';

// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
import img from '../../_assets/icons/1custprofile.svg';
// import img2 from '../../_assets/icons/1user-hash.svg';
import {merchantActions, userActions} from '../../_actions';
import {roles, userConstants} from '../../_constants';

export function MerchantProfile({match}) {
   const dispatch = useDispatch();
   // const IsRequestingCustomerDetails = useSelector(
   //    (s) => s.customer.IsRequestingCustomerDetails,
   // );
   const {
      merchantDetails,
      IsUpgradingMerchant,
      merchantUpgraded,
      IsVerifyingMerchant,
   } = useSelector((s) => s.merchant);
   const userDetails = useSelector((s) => s.user.user);
   const userDetail = userDetails.user;

   // const merchantDetails = useSelector((s) => s.merchant.merchantDetails);
   // const IsUpgradingMerchant = useSelector(
   //    (s) => s.merchant.IsUpgradingMerchant,
   // );
   // const merchantUpgraded = useSelector((s) => s.merchant.merchantUpgraded);
   // const IsVerifyingMerchant = useSelector(
   //    (s) => s.merchant.IsVerifyingMerchant,
   // );
   const IsVerifyingUserBvn = useSelector((s) => s.user.IsVerifyingUserBvn);
   const userBvnVerified = useSelector((s) => s.user.userBvnVerified);

   useEffect(() => {
      if (merchantUpgraded === true) {
         setUpgradeModalIsOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [merchantUpgraded]);

   useEffect(() => {
      if (userBvnVerified === true) {
         setVerifyBvnModalIsOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userBvnVerified]);

   useEffect(() => {
      const {uuid} = match.params;
      // const {state} = location;

      const settlement = {
         // uuid: state?.merchantId,
         uuid: uuid,
         type: 'settlement',
         user: 'merchant',
      };
      const transaction = {
         // uuid: state?.merchantId,
         uuid: uuid,
         type: 'transaction',
         user: 'merchant',
      };
      dispatch(merchantActions.getMerchantDetails(uuid));
      dispatch(merchantActions.getMerchantTransactionHistory(transaction));
      dispatch(merchantActions.getMerchantSettlementHistory(settlement));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [shown, setShown] = useState(1);

   const [tabshown, setTabshown] = useState(1);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false);
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [verifyBvnModalIsOpen, setVerifyBvnModalIsOpen] = useState(false);
   const [verifyError, setVerifyError] = useState('');

   console.log('merchantDetails', merchantDetails);
   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
      },
   };

   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      setModalIsOpen(false);
   };

   const verifyMerchant = () => {
      setVerifyError('');
      if (merchantDetails?.user_details?.bvn_verified) {
         const {uuid} = match.params;
         dispatch(merchantActions.verifyMerchant(uuid));
      } else {
         setVerifyError('Please verify BVN first!');
      }
   };

   const handleSubmit = () => {
      const {user_details} = merchantDetails;
      const fields = {
         _id: user_details?._id,
         status: true,
         merchant: true,
      };
      dispatch(userActions.verifyUserBvn(fields));
   };

   const handleUpgrade = () => {
      const {uuid} = match.params;
      const model = {
         id: uuid,
         status:
            merchantDetails?.merchant_details?.is_premium === false
               ? true
               : false,
      };

      dispatch(merchantActions.upgradeMerchant(model));
   };

   return (
      <MDBRow className="merchant-page m-0 w-100 mb-5">
         <div className="col-md-8 p-0 pr-3">
            <div className="history d-flex justify-content-between">
               <ul className="d-flex">
                  <li>
                     <div
                        onClick={() => setShown(1)}
                        className={classNames('w-100 d-block', {
                           active: shown === 1,
                        })}>
                        Transaction History
                     </div>
                  </li>
                  <li>
                     <div
                        onClick={() => setShown(2)}
                        className={classNames('w-100 d-block', {
                           active: shown === 2,
                        })}>
                        Settlement History
                     </div>
                  </li>
               </ul>
               {/* <div className="price">#330,000</div> */}
            </div>
            {shown === 1 && <TransactionHistory match={match} />}
            {shown === 2 && <SettlementHistory match={match} />}
         </div>

         <div className="col-md-4 p-0 customer-details">
            {/* <div className="message ">
               <Link onClick={() => setUpgradeModalIsOpen(true)}>
                  Verify BVN
               </Link>
            </div> */}
            <span className="d-flex justify-content-end">
               MERCHANT ID: {merchantDetails?.merchant_details.merchant_id}
            </span>
            <div className="message d-flex justify-content-end">
               {/* {!merchantDetails?.merchant_details?.is_premium && ( */}

               <Link onClick={() => setUpgradeModalIsOpen(true)}>
                  {merchantDetails?.merchant_details?.is_premium === false
                     ? 'Upgrade Merchant'
                     : 'Downgrade Merchant'}
               </Link>
               {/* )} */}
            </div>
            <div className="details">
               <div className="profile">
                  <div className="profile-pic">
                     <div className="img d-flex justify-content-center">
                        {merchantDetails?.merchant_details?.logo_url !== ' ' ? (
                           <img
                              src={
                                 merchantDetails?.merchant_details?.logo_url ||
                                 img
                              }
                              alt="profile-img"
                              className="rounded-circle img-fluid d-flex justify-content-center"
                              style={{width: '3.3rem'}}
                           />
                        ) : (
                           <LetteredAvatar
                              name={
                                 merchantDetails?.user_details?.firstname +
                                 ' ' +
                                 merchantDetails?.user_details?.lastname
                              }
                              options={{
                                 size: 50,
                                 twoLetter: true,
                                 //shape: 'square',
                                 bgColor: '',
                                 imgClass: 'image-responsive user-image',
                                 imgWidth: '3rem',
                                 imgHeight: '3rem',
                              }}
                           />
                        )}
                     </div>
                     <span className="d-flex justify-content-center">
                        {merchantDetails?.user_details?.firstname +
                           ' ' +
                           merchantDetails?.user_details?.lastname}
                     </span>
                     <p className="text-center verify-error">{verifyError}</p>
                  </div>
                  <div className="verify-loan d-flex justify-content-around">
                     {/* <div onClick={verifyBVN}>Verify BVN</div> */}
                     {merchantDetails?.user_details?.bvn && (
                        <div
                           onClick={
                              merchantDetails?.user_details?.bvn &&
                              !merchantDetails?.user_details?.bvn_verified
                                 ? () =>
                                      setVerifyBvnModalIsOpen(
                                         true,
                                         setVerifyError(''),
                                      )
                                 : null
                           }>
                           {merchantDetails?.user_details?.bvn &&
                           merchantDetails?.user_details?.bvn_verified
                              ? 'BVN Verified'
                              : 'Verify BVN'}
                        </div>
                     )}
                     {/* {userDetail?.role.includes(userConstants.ADMIN_ROLE_ID) && ( */}

                     {!userDetail?.role.includes(
                        userConstants.REGULAR_ADMIN_ROLE_ID,
                     ) && !merchantDetails?.merchant_details?.verified ? (
                        <div onClick={verifyMerchant}>
                           {IsVerifyingMerchant ? (
                              // <div class="text-white" role="status">
                              <>
                                 <i class="fa fa-spinner fa-spin"></i> Verifying
                              </>
                           ) : (
                              // </div>
                              'Verify'
                           )}
                        </div>
                     ) : (
                        <div>Verified</div>
                     )}
                  </div>
               </div>

               <div className="detail-tabs">
                  <ul className="d-flex justify-content-between">
                     <li>
                        <div
                           onClick={() => setTabshown(1)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 1,
                           })}>
                           Personal Info
                        </div>
                     </li>
                     <li>
                        <div
                           onClick={() => setTabshown(2)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 2,
                           })}>
                           Bank Details
                        </div>
                     </li>
                     <li>
                        <div
                           onClick={() => setTabshown(3)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 3,
                           })}>
                           Users
                        </div>
                     </li>
                  </ul>
               </div>

               {tabshown === 1 && (
                  <div className="data d-flex">
                     <div className="mr-5">
                        <h6>Full Name</h6>
                        <p>
                           {merchantDetails?.user_details?.firstname +
                              ' ' +
                              merchantDetails?.user_details?.lastname}
                        </p>
                        <h6>Phone Number</h6>
                        <p>
                           {merchantDetails?.user_details?.phone_number?.replace(
                              /^.{3}/g,
                              '0',
                           )}
                        </p>
                        <h6>Company Address</h6>
                        <p>
                           {merchantDetails?.merchant_details?.merchant_address}
                        </p>
                     </div>
                     <div>
                        <h6>Email</h6>
                        <p>
                           {merchantDetails?.merchant_details?.merchant_email}
                        </p>
                        <h6>Company Name</h6>
                        <p className="text-capitalize">
                           {merchantDetails?.merchant_details?.merchant_name}
                        </p>
                        <h6>BVN</h6>
                        <p>{merchantDetails?.user_details?.bvn}</p>
                     </div>
                  </div>
               )}
               {tabshown === 2 && (
                  <div className="data d-flex">
                     {merchantDetails?.bank_details?.length > 0 ? (
                        merchantDetails?.bank_details.map((row) => {
                           return (
                              <div className="mr-3">
                                 <h6>Bank</h6>
                                 <p>GTBank</p>
                                 <h6>Account Number</h6>
                                 <p>{row.account_number}</p>
                                 <h6>Account Name</h6>
                                 <p>{row.account_name}</p>
                              </div>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                     {/* <div>
                        <h6>Bank</h6>
                        <p>GTBank</p>
                        <h6>Account Number</h6>
                        <p>1234567890</p>
                        <h6>Account Name</h6>
                        <p>Emeka Chuks Champion</p>
                     </div> */}
                  </div>
               )}
               {tabshown === 3 && (
                  <div className="data">
                     {merchantDetails?.sub_users?.length > 0 ? (
                        merchantDetails?.sub_users.map((row) => {
                           return (
                              <div className="container">
                                 <div className="row">
                                    <div className="col-xs-4 mr-3">
                                       <h6>Full Name</h6>
                                       <p className="text-capitalize">
                                          {row?.username ||
                                             row?.user_id?.firstname +
                                                ' ' +
                                                row?.user_id?.lastname}
                                       </p>
                                    </div>
                                    {row?.user_id && (
                                       <div className="col-xs-4 mr-3">
                                          <h6>Email</h6>
                                          <p>{row?.user_id?.email}</p>
                                       </div>
                                    )}
                                    <div className="col-xs-4 mr-3">
                                       <h6>Role</h6>
                                       <p>
                                          {
                                             roles.find(
                                                (role) =>
                                                   role.value === row?.role,
                                             )?.title
                                          }
                                       </p>
                                    </div>
                                    <div className="col-xs-4 mr-3">
                                       <h6>Phone Number</h6>
                                       <p>
                                          {row?.phone_number?.replace(
                                             /^.{3}/g,
                                             '0',
                                          ) ||
                                             row?.user_id?.phone_number?.replace(
                                                /^.{3}/g,
                                                '0',
                                             )}
                                       </p>
                                    </div>
                                    <hr className="w-100 h-100" />
                                 </div>
                              </div>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </div>
               )}
            </div>

            <Modal
               isOpen={modalIsOpen}
               style={customStyles}
               onRequestClose={() => setModalIsOpen(false)}>
               <Formsy className="__form d-flex flex-column">
                  <h4 className="text-left mb-3">Grant Loan</h4>
                  <p className="text-left loan mb-4">
                     Loans are transferred to customer's account
                  </p>
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-2 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="Customer Name"
                     className="input1 border-0 w-100"
                     placeholder="Lade jones"
                     disabled
                  />
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="Amount"
                     className="input2 border-0 w-100"
                     placeholder="20,000"
                  />
                  <button>
                     <div onClick={setSecModalIsOpenToTrue} className="ml-auto">
                        Submit
                     </div>
                  </button>
               </Formsy>
            </Modal>
            <Modal
               isOpen={secModalIsOpen}
               style={customStyles}
               onRequestClose={() => setSecModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <p className="py-2">Customer's account has been credited</p>
                  <button>
                     <Link to="/dashboard">Home</Link>
                  </button>
               </div>
            </Modal>
            <Modal
               isOpen={secModalIsOpen}
               style={customStyles}
               onRequestClose={() => setSecModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <p className="py-2">Customer's account has been credited</p>
                  <button>
                     <Link to="/dashboard">Home</Link>
                  </button>
               </div>
            </Modal>
            <Modal
               isOpen={upgradeModalIsOpen}
               style={customStyles}
               onRequestClose={() => setUpgradeModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  {merchantDetails?.merchant_details?.is_premium === false ? (
                     <p className="py-2">
                        Are you sure you want to upgrade this merchant?
                     </p>
                  ) : (
                     <p className="py-2">
                        Are you sure you want to downgrade this merchant?
                     </p>
                  )}
                  <button>
                     <Link onClick={handleUpgrade}>
                        {IsUpgradingMerchant ? (
                           <div class="spinner-border text-white" role="status">
                              <span class="sr-only">Loading...</span>
                           </div>
                        ) : (
                           'Proceed'
                        )}
                     </Link>
                  </button>
               </div>
            </Modal>
            <Modal
               isOpen={verifyBvnModalIsOpen}
               style={customStyles}
               onRequestClose={() => setVerifyBvnModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <p className="py-2">Verify Merchant's BVN</p>
                  <p className="py-2">
                     BVN: {merchantDetails?.user_details?.bvn}
                  </p>
                  {/* <p></p> */}
                  <button onClick={handleSubmit}>
                     <Link>
                        {IsVerifyingUserBvn ? (
                           <div class="spinner-border text-white" role="status">
                              <span class="sr-only">Loading...</span>
                           </div>
                        ) : (
                           'Proceed'
                        )}
                     </Link>
                  </button>
               </div>
            </Modal>
         </div>
      </MDBRow>
   );
}
