import {combineReducers} from 'redux';
import {roleConstants, appConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingRoles = (state = false, action) => {
   switch (action.type) {
      case roleConstants.REQUEST_ALL_ROLES:
         return true;
      case roleConstants.REQUEST_ALL_ROLES_ERROR:
         return false;
      case roleConstants.REQUEST_ALL_ROLES_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingRole = (state = false, action) => {
   switch (action.type) {
      case roleConstants.REQUEST_CREATE_ROLE:
         return true;
      case roleConstants.CREATE_ROLE_SUCCESS:
         return false;
      case roleConstants.CREATE_ROLE_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingRole = (state = false, action) => {
   switch (action.type) {
      case roleConstants.REQUEST_UPDATE_ROLE:
         return true;
      case roleConstants.UPDATE_ROLE_SUCCESS:
         return false;
      case roleConstants.UPDATE_ROLE_ERROR:
         return false;
      default:
         return state;
   }
};

const roleCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case roleConstants.REQUEST_CREATE_ROLE:
         return false;
      case roleConstants.CREATE_ROLE_SUCCESS:
         return true;
      case roleConstants.CREATE_ROLE_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const roleUpdatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case roleConstants.REQUEST_UPDATE_ROLE:
         return false;
      case roleConstants.UPDATE_ROLE_SUCCESS:
         return true;
      case roleConstants.UPDATE_ROLE_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const roleList = (state = [], action) => {
   switch (action.type) {
      case roleConstants.REQUEST_ALL_ROLES_SUCCESS: {
         return action.roles.data;
      }
      case roleConstants.REQUEST_ALL_ROLES_WITHOUT_PAGINATION_SUCCESS: {
         return action.roles.data;
      }
      case roleConstants.CREATE_ROLE_SUCCESS: {
         const newList = [...state, action.role.data];

         return newList;
      }
      case roleConstants.UPDATE_ROLE_SUCCESS: {
         const newList = updateItemInArray(
            state,
            action.role.data._id,
            true,
            (role) => {
               return updateObject(role, action.role.data);
            },
         );

         return newList;
      }
      case roleConstants.DELETE_ROLE_SUCCESS: {
         return state.filter((role) => role._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case roleConstants.CREATE_ROLE_ERROR:
         return updateObject(state, error);
      case roleConstants.REQUEST_CREATE_ROLE:
         return null;
      case roleConstants.UPDATE_ROLE_ERROR:
         return updateObject(state, error);
      case roleConstants.REQUEST_UPDATE_ROLE:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      // case roleConstants.REQUEST_ALL_ROLES_SUCCESS: {
      //    const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
      //       action.roles.roles;
      //    const result = {
      //       hasNextPage,
      //       nextPage,
      //       totalDocs,
      //       totalPages,
      //       limit,
      //       page,
      //    };

      //    return updateObject(state, result);
      // }
      case roleConstants.CREATE_ROLE_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = state;
         const TotalDocs = totalDocs + 1;
         const result = {hasNextPage, nextPage, TotalDocs, limit, page};

         return updateObject(state, result);
      }
      case roleConstants.DELETE_ROLE_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = state;
         const TotalDocs = totalDocs - 1;
         const result = {hasNextPage, nextPage, TotalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   roleList,
   IsRequestingRoles,
   IsCreatingRole,
   IsUpdatingRole,
   roleCreatedSuccessfully,
   roleUpdatedSuccessfully,
   errorMessage,
   pagination,
});

export default rootReducer;
