import {roleConstants} from '../_constants';

const getRoles = (model) => ({
   type: roleConstants.GET_ALL_ROLES,
   data: model,
});

const createRole = (model) => ({
   type: roleConstants.CREATE_ROLE,
   data: model,
});

const updateRole = (model) => ({
   type: roleConstants.UPDATE_ROLE,
   data: model,
});

const deleteRole = (_id) => ({
   type: roleConstants.DELETE_ROLE,
   _id,
});

export const roleActions = {
   getRoles,
   createRole,
   updateRole,
   deleteRole,
};
