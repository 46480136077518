import {appConstants} from './app.constant';

const role = 'role';

export const roleConstants = {
   REQUEST_CREATE_ROLE: 'REQUEST_CREATE_ROLE',
   CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
   CREATE_ROLE_ERROR: 'CREATE_ROLE_ERROR',

   REQUEST_ALL_ROLES: 'REQUEST_ALL_ROLES',
   REQUEST_ALL_ROLES_SUCCESS: 'REQUEST_ALL_ROLES_SUCCESS',
   REQUEST_ALL_ROLES_ERROR: 'REQUEST_ALL_ROLES_ERROR',

   REQUEST_ALL_ROLES_WITHOUT_PAGINATION: 'REQUEST_ALL_ROLES_WITHOUT_PAGINATION',
   REQUEST_ALL_ROLES_WITHOUT_PAGINATION_SUCCESS:
      'REQUEST_ALL_ROLES_WITHOUT_PAGINATION_SUCCESS',
   REQUEST_ALL_ROLES_WITHOUT_PAGINATION_ERROR:
      'REQUEST_ALL_ROLES_WITHOUT_PAGINATION_ERROR',

   REQUEST_DELETE_ROLE: 'REQUEST_DELETE_ROLE',
   DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
   DELETE_ROLE_ERROR: 'DELETE_ROLE_ERROR',

   REQUEST_UPDATE_ROLE: 'REQUEST_UPDATE_ROLE',
   UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
   UPDATE_ROLE_ERROR: 'UPDATE_ROLE_ERROR',

   GET_ALL_ROLES: 'GET_ALL_ROLES',
   CREATE_ROLE: 'CREATE_ROLE',
   DELETE_ROLE: 'DELETE_ROLE',
   UPDATE_ROLE: 'UPDATE_ROLE',

   ROLE_URI: `${appConstants.BASE_URI}${role}/`,
};
