import {call, put, takeLatest, all} from 'redux-saga/effects';
import {
   settlementConstants,
   userConstants,
   transactionConstants,
} from '../../_constants';
import {
   createRequestWithToken,
   checkStatus,
   getObjectFromStorage,
} from '../../_helpers';

function* getSuccessfulSettlements({data}) {
   yield put({type: settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let allTransactionsUri = `${settlementConstants.ALL_SETTLEMENTS_URI}`;

      if (data?.page) {
         allTransactionsUri = `${allTransactionsUri}?page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allTransactionsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_SUCCESS,
         successfulSettlements: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: settlementConstants.REQUEST_SUCCESSFUL_SETTLEMENTS_ERROR,
      });
   }
}

function* getFailedSettlements({data}) {
   yield put({type: settlementConstants.REQUEST_FAILED_SETTLEMENTS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let failedSettlementsUri = `${settlementConstants.ALL_CUSTOMERS_URI}`;

      if (data?.page) {
         failedSettlementsUri = `${failedSettlementsUri}?page=${data.page + 1}`;
      }

      const req = createRequestWithToken(failedSettlementsUri, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: settlementConstants.REQUEST_FAILED_SETTLEMENTS_SUCCESS,
         failedSettlements: jsonResponse,
      });
   } catch (error) {
      yield put({type: settlementConstants.REQUEST_FAILED_SETTLEMENTS_ERROR});
   }
}

function* searchSettlement({data}) {
   yield put({type: settlementConstants.REQUEST_SEARCH_SETTLEMENT});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );
      // ?type=${data.type}
      let searchSettlementUri = `${transactionConstants.ALL_TRANSACTIONS_URI}/search`;

      const req = createRequestWithToken(searchSettlementUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: settlementConstants.SEARCH_SETTLEMENT_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: settlementConstants.SEARCH_SETTLEMENT_SUCCESS,
         settlement: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: settlementConstants.SEARCH_SETTLEMENT_ERROR,
         error: errorMessage,
      });
   }
}

function* getSuccessfulSettlementsSagaWatcher() {
   yield takeLatest(
      settlementConstants.GET_SUCCESSFUL_SETTLEMENTS,
      getSuccessfulSettlements,
   );
}

function* getFailedSettlementsSagaWatcher() {
   yield takeLatest(
      settlementConstants.GET_FAILED_SETTLEMENTS,
      getFailedSettlements,
   );
}

function* searchSettlementWatcher() {
   yield takeLatest(settlementConstants.SEARCH_SETTLEMENT, searchSettlement);
}

export default function* rootSaga() {
   yield all([
      getSuccessfulSettlementsSagaWatcher(),
      getFailedSettlementsSagaWatcher(),
      searchSettlementWatcher(),
   ]);
}
