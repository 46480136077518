import {combineReducers} from 'redux';
import userRootReducer from './user.reducer';
import authRootReducer from './auth.reducer';
import branchRootReducer from './branch.reducer';
import customerRootReducer from './customer.reducer';
import dashboardRootReducer from './dashboard.reducer';
import forgotPasswordRootReducer from './forgotPassword.reducer';
import permissionRootReducer from './permission.reducer';
import roleRootReducer from './role.reducer';
import loanRootReducer from './loan.reducer';
import merchantRootReducer from './merchant.reducer';
import settlementRootReducer from './settlement.reducer';
import snackbarRootReducer from './snackbar.reducer';
import transactionRootReducer from './transaction.reducer';

const rootReducer = combineReducers({
   user: userRootReducer,
   auth: authRootReducer,
   branch: branchRootReducer,
   customer: customerRootReducer,
   dashboard: dashboardRootReducer,
   forgotPassword: forgotPasswordRootReducer,
   permission: permissionRootReducer,
   role: roleRootReducer,
   snackbar: snackbarRootReducer,
   loan: loanRootReducer,
   merchant: merchantRootReducer,
   settlement: settlementRootReducer,
   transaction: transactionRootReducer,
});

export default rootReducer;
