import {appConstants} from './app.constant';

const dashboard = 'admin/dashboard';

export const dashboardConstants = {
   GET_DASHBOARD_METRICS: 'GET_DASHBOARD_METRICS',

   REQUEST_DASHBOARD_METRICS: 'REQUEST_DASHBOARD_METRICS',
   REQUEST_DASHBOARD_METRICS_ERROR: 'REQUEST_DASHBOARD_METRICS_ERROR',
   REQUEST_DASHBOARD_METRICS_SUCCESS: 'REQUEST_DASHBOARD_METRICS_SUCCESS',

   GET_DASHBOARD_METRICS_URI: `${appConstants.BASE_URI}${dashboard}`,
};
