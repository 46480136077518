import React, {useEffect, useState} from 'react';
import {MDBRow} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
// import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import './index.scss';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
// import Checkbox from '@material-ui/core/Checkbox';
// import Chip from '@material-ui/core/Chip';

import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
// import {ReactComponent as Edit} from '../../_assets/icons/1edit.svg';
//import {ReactComponent as ArrowDown} from '../../_assets/icons/1arrow-down.svg';
import {merchantConstants, customerConstants} from '../../_constants';
import {userActions, roleActions} from '../../_actions';

// const MenuProps = {
//    PaperProps: {
//       style: {
//          //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//          width: 250,
// &&
//          customerConstants.CUSTOMER_ROLE_ID)
//       },
//    },
// };

export default function AdminUserForm({type}) {
   const dispatch = useDispatch();
   const rolesList = useSelector((s) => s.role.roleList);
   const adminUserRegistered = useSelector((s) => s.user.adminUserRegistered);
   const roles = rolesList.filter(
      (role) => role._id !== merchantConstants.MERCHANT_ROLE_ID,
   );

   const adminRoles = roles.filter(
      (role) => role._id !== customerConstants.CUSTOMER_ROLE_ID,
   );

   // console.log('roles', roles);
   // console.log('adminRoles', adminRoles);
   // console.log('rolesList', rolesList);

   useEffect(() => {
      const model = {
         pagination: 'none',
      };
      dispatch(roleActions.getRoles(model));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   const setModalIsOpenToTrue = () => {
      setModalIsOpen(true);
   };

   useEffect(() => {
      if (adminUserRegistered) {
         setModalIsOpen(false);
      }
   }, [adminUserRegistered]);

   const [canSubmit, setCanSubmit] = useState(false);
   const [role, setRole] = useState('');

   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   // const setSecModalIsOpenToTrue = () => {
   //    setSecModalIsOpen(true);
   //    setModalIsOpen(false);
   // };

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   // const [isOpen, setIsOpen] = useState(false);
   // const toggleList = () => {
   //    setIsOpen((wasOpen) => !wasOpen);
   // };

   // const [selected, setSelected] = useState(0);

   // const options = [
   //    {label: 'Customer Relation', val: 0},
   //    {label: 'Admin', val: 1},
   //    {label: 'Super Admin', val: 2},
   // ];

   const user = useSelector((s) => s.user);
   const errorMessage = useSelector((s) => s.user.errorMessage);
   const emailError = errorMessage?.errors.filter(
      (error) => error.param === 'email',
   );
   // const firstNameError = errorMessage?.errors.filter(
   //    (error) => error.param === 'firstname',
   // );
   // const lastNameError = errorMessage?.errors.filter(
   //    (error) => error.param === 'lastname',
   // );

   const handleChange = (e) => {
      setRole(e.target.value);
   };

   const handleSubmit = (data) => {
      data['role'] = role;

      dispatch(userActions.registerAdminUser(data));
   };

   return (
      <MDBRow className=" m-0 w-100">
         <div className="user-management w-100">
            {type === 'add' ? (
               <div onClick={setModalIsOpenToTrue} className="button ml-auto">
                  Add New
               </div>
            ) : (
               <p onClick={setModalIsOpenToTrue}>Edit</p>
            )}
            <div className="add-new">
               <Modal
                  isOpen={modalIsOpen}
                  style={customStyles}
                  onRequestClose={() => setModalIsOpen(false)}>
                  <Formsy
                     onValidSubmit={handleSubmit}
                     onValid={() => setCanSubmit(true)}
                     onInvalid={() => setCanSubmit(false)}
                     className="__form d-flex flex-column">
                     <h4 className="text-left mb-3">
                        {type === 'add'
                           ? 'Invite New User'
                           : "Update User's Information"}
                     </h4>
                     <p className="text-left loan mb-4">
                        {type === 'add'
                           ? "Fill in the new Admin User's details"
                           : "Fill in the new Admin User's details"}
                     </p>
                     {/* <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="firstname"
                        type="text"
                        labelClassName="label"
                        labelText="First Name"
                        className="input11 border-0 w-100"
                        placeholder="Kobe"
                        required
                     />
                     <p class="text-red">{firstNameError?.[0]?.msg}</p> */}
                     {/* <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="lastname"
                        type="text"
                        labelClassName="label"
                        labelText="Last Name"
                        className="input11 border-0 w-100"
                        placeholder="Brown"
                        required
                     />
                     <p class="text-red">{lastNameError?.[0]?.msg}</p> */}
                     {/* <div className="form-group w-100">
                        <label className="label mb-0">Role</label>
                        <div
                           onClick={toggleList}
                           className="input222 d-flex position-relative align-items-center justify-content-between ">
                           <span className="d-block">
                              {options[selected].label}
                           </span>
                           <ArrowDown style={{width: '1rem'}} />
                           {isOpen && (
                              <div
                                 onRequestClose={() => setIsOpen(false)}
                                 className="list position-absolute">
                                 <ul className="list-items">
                                    {options
                                       // .filter((i) => i.val !== 0)
                                       .map((i, n) => (
                                          <li
                                             key={n}
                                             onClick={(e) => {
                                                e.stopPropagation();
                                                setIsOpen(false);
                                                setSelected(i.val);
                                             }}>
                                             {i.label}
                                          </li>
                                       ))}
                                 </ul>
                              </div>
                           )}
                        </div>
                     </div> */}
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="email"
                        type="email"
                        labelClassName="label"
                        labelText="Email"
                        className="input11 border-0 w-100"
                        placeholder="finance123"
                        validations="isEmail"
                        validationError="The email is invalid!"
                        required
                     />
                     <p>{emailError && emailError[0] && emailError[0].msg}</p>
                     <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                     >
                        <InputLabel id="demo-simple-select-outlined-label">
                           Role
                        </InputLabel>
                        <Select
                           labelId="demo-simple-select-outlined-label"
                           id="demo-simple-select-outlined"
                           value={role}
                           name="role_id"
                           onChange={handleChange}
                           label="Role">
                           {adminRoles.map((option) => (
                              <MenuItem value={option._id}>
                                 {option.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <button
                        disabled={!canSubmit}
                        className="w-100 mt-3 mt-s-4">
                        {user.IsRegisteringAdminUser ? (
                           <div class="text-white" role="status">
                              <i class="fa fa-spinner fa-spin"></i> Adding
                           </div>
                        ) : (
                           <div className="submit-btn w-100">Add User</div>
                        )}
                     </button>
                     {/* <button disabled={!canSubmit} className=" w-100">
                        <div className="submit-btn w-100">
                           {user.IsRegisteringAdminUser ? (
                              <div class="text-white" role="status">
                                 <i class="fa fa-spinner fa-spin"></i> Adding
                              </div>
                           ) : (
                              'Add User'
                           )}
                        </div>
                     </button> */}
                  </Formsy>
               </Modal>
               <Modal
                  isOpen={secModalIsOpen}
                  style={customStyles}
                  onRequestClose={() => setSecModalIsOpen(false)}>
                  <div className="content d-flex flex-column">
                     <AcctCredited
                        className="credited"
                        style={{width: '6rem'}}
                     />
                     <h3 className="m-0 d-flex justify-content-center py-2">
                        Invite Sent
                     </h3>
                     <p>Invite has been sent to the mail</p>
                  </div>
               </Modal>
            </div>
         </div>
      </MDBRow>
   );
}
