import {all} from 'redux-saga/effects';
import userRootSaga from './user.saga';
import authenticationRootSaga from './auth.saga';
import branchRootSaga from './branch.saga';
import customerRootSaga from './customer.saga';
import dashboardRootSaga from './dashboard.saga';
import forgotPasswordRootSaga from './forgotPassword.saga';
import permissionRootSaga from './permission.saga';
import roleRootSaga from './role.saga';
import loanRootSaga from './loan.saga';
import merchantRootSaga from './merchant.saga';
import settlementRootSaga from './settlement.saga';
import transactionRootSaga from './transaction.saga';

export default function* rootSaga() {
   yield all([
      userRootSaga(),
      authenticationRootSaga(),
      branchRootSaga(),
      customerRootSaga(),
      dashboardRootSaga(),
      forgotPasswordRootSaga(),
      permissionRootSaga(),
      roleRootSaga(),
      loanRootSaga(),
      merchantRootSaga(),
      settlementRootSaga(),
      transactionRootSaga(),
   ]);
}
