import React, {useEffect, useState} from 'react';
import {MDBRow} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'react-modal';
import './index.scss';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
import Select from '@material-ui/core/Select';

import {branchActions} from '../../_actions';
import {marketerRoles} from '../../_constants';
import {FormControl, InputLabel, MenuItem} from '@material-ui/core';

export default function MarketerForm({branchId}) {
   const dispatch = useDispatch();

   const marketerCreatedSuccessfully = useSelector(
      (s) => s.branch.marketerCreatedSuccessfully,
   );
   const IsCreatingMarketer = useSelector((s) => s.branch.IsCreatingMarketer);
   const errorMessage = useSelector((s) => s.branch.errorMessage);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   const setModalIsOpenToTrue = () => {
      setModalIsOpen(true);
   };

   useEffect(() => {
      if (marketerCreatedSuccessfully) {
         setModalIsOpen(false);
      }
   }, [marketerCreatedSuccessfully]);

   const [canSubmit, setCanSubmit] = useState(false);
   const [role, setRole] = useState('');

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '15px',
      },
   };

   const nameError = errorMessage?.errors.filter(
      (error) => error.param === 'firstname',
   );
   const lastnameError = errorMessage?.errors.filter(
      (error) => error.param === 'lastname',
   );
   const phoneError = errorMessage?.errors.filter(
      (error) => error.param === 'phone_number',
   );

   const handleChange = (e) => {
      setRole(e.target.value);
   };

   const handleSubmit = (data) => {
      data['branch'] = branchId;
      data['role'] = role;
      // console.log('data', data);
      dispatch(branchActions.createMarketer(data));
   };

   return (
      <MDBRow className=" m-0 w-100">
         <div className="user-management w-100">
            <div onClick={setModalIsOpenToTrue} className="button ml-auto">
               Add Marketer
            </div>
            <div className="add-new">
               <Modal
                  isOpen={modalIsOpen}
                  style={customStyles}
                  onRequestClose={() => setModalIsOpen(false)}>
                  <Formsy
                     onValidSubmit={handleSubmit}
                     onValid={() => setCanSubmit(true)}
                     onInvalid={() => setCanSubmit(false)}
                     className="__form d-flex flex-column">
                     <h4 className="text-left mb-3">Add a New Marketer</h4>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="firstname"
                        type="text"
                        labelClassName="label"
                        labelText="First Name"
                        className="input11 border-0 w-100"
                        placeholder="Kunle"
                        required
                     />
                     <p style={{color: 'red'}}>{nameError?.[0]?.msg}</p>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="lastname"
                        type="text"
                        labelClassName="label"
                        labelText="Last Name"
                        className="input11 border-0 w-100"
                        placeholder="Life"
                        required
                     />
                     <p style={{color: 'red'}}>{lastnameError?.[0]?.msg}</p>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="phone_number"
                        type="text"
                        labelClassName="label"
                        labelText="Phone Number"
                        className="input11 border-0 w-100"
                        placeholder="09099999999"
                        required
                        validations={{isNumeric: true, isLength: 11}}
                        validationErrors={{
                           isNumeric: 'Invalid phone number',
                           isLength: 'Phone number incomplete',
                        }}
                     />
                     <p style={{color: 'red'}}>{phoneError?.[0]?.msg}</p>
                     <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                        className="mb-2 mb-s-4">
                        <InputLabel id="demo-simple-select-outlined-label">
                           Role
                        </InputLabel>
                        <Select
                           labelId="demo-simple-select-outlined-label"
                           id="demo-simple-select-outlined"
                           value={role}
                           name="role_id"
                           onChange={handleChange}
                           label="Role">
                           {marketerRoles.map((option) => (
                              <MenuItem value={option.value} key={option.value}>
                                 {option.title}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="loanBudget"
                        type="text"
                        labelClassName="label"
                        labelText="Loan Budget"
                        className="input11 border-0 w-100"
                        placeholder="100000"
                        required
                     />
                     <p style={{color: 'red'}}>{lastnameError?.[0]?.msg}</p>
                     <TextInput
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-2 mb-s-4"
                        useFocusedError
                        name="topupBudget"
                        type="text"
                        labelClassName="label"
                        labelText="Topup Budget"
                        className="input11 border-0 w-100"
                        placeholder="100000"
                        required
                     />
                     {/* <p class="text-red">{locationError?.[0]?.msg}</p> */}
                     <button
                        disabled={!canSubmit}
                        className="w-100 mt-3 mt-s-4">
                        {IsCreatingMarketer ? (
                           <div class="text-white" role="status">
                              <i class="fa fa-spinner fa-spin"></i> Adding
                           </div>
                        ) : (
                           <div className="submit-btn w-100">Add</div>
                        )}
                     </button>
                  </Formsy>
               </Modal>
            </div>
         </div>
      </MDBRow>
   );
}
