import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import './index.scss';
import './view.scss';

import TablePaginationActions from '../../_components/TablePaginationAction';
import Loader from '../../_helpers/loader';
import {numberWithCommas, readableDateString} from '../../_helpers';
import {customerActions} from '../../_actions';

export default function LoanHistory({match}) {
   const dispatch = useDispatch();
   const IsRequestingCustomerLoanHistory = useSelector(
      (s) => s.customer.IsRequestingCustomerLoanHistory,
   );
   const customerLoanList = useSelector((s) => s.customer.customerLoanList);
   const pagination = useSelector((s) => s.customer.loanPagination);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const handleChangePage = (event, pages) => {
      const {uuid} = match.params;

      const model = {
         page: pages,
         uuid: uuid,
      };

      dispatch(customerActions.getCustomerLoanHistory(model));
   };

   return (
      <>
         <div className="customer-list mt-3 w-100">
            <MDBTable
               className="profile"
               responsive
               borderless
               hover
               align="middle">
               <MDBTableHead>
                  <tr>
                     <th scope="col">Amount</th>
                     <th scope="col">Utilized Amount</th>
                     <th scope="col">Date Disbursed</th>
                     <th scope="col">Return Date</th>
                  </tr>
               </MDBTableHead>
               {IsRequestingCustomerLoanHistory ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {customerLoanList?.length > 0 ? (
                        customerLoanList.map((row) => {
                           return (
                              <div className="d-table-row">
                                 <td>{numberWithCommas(row.amount)}</td>
                                 <td>
                                    {numberWithCommas(
                                       row?.loan_details.utilized_amount,
                                    )}
                                 </td>
                                 <td>
                                    {readableDateString(
                                       row?.loan_details.date_granted,
                                    )}
                                 </td>
                                 <td>{readableDateString(row.created_at)}</td>
                              </div>
                           );
                        })
                     ) : (
                        <td align="center" colSpan={5}>
                           This customer has not been given any loan on this
                           platform
                        </td>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
         </div>
         {!IsRequestingCustomerLoanHistory && totalPages > 1 && (
            <TablePaginationActions
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </>
   );
}
