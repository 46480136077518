import React from 'react';
import {MDBCol, MDBRow} from 'mdbreact';
import './index.scss';

export default function SharedBackground({children}) {
   return (
      <MDBRow className="h-100 w-100 position-relative shared-background">
         <MDBCol className="h-100 w-100 position-absolute p-0 children">
            {children}
         </MDBCol>
         <div className="background">
            <div className="circle">
               <div className="sec-circle"></div>
            </div>
         </div>
      </MDBRow>
   );
}
