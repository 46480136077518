import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {userActions} from '../../_actions';
// import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import {MDBContainer, MDBCol} from 'mdbreact';

import {Link} from 'react-router-dom';
import {ReactComponent as Error} from '../../_assets/icons/error.svg';
// import BlueYellow from '../../_assets/images/blueandyellow.svg';
import {ReactComponent as Bluetick} from '../../_assets/icons/1acct-credited.svg';
import './index.scss';

export default function VerifyEmail({match}) {
   const dispatch = useDispatch();

   const emailVerificationSuccessful = useSelector(
      (s) => s.auth.emailVerificationSuccessful,
   );
   const emailVerificationFailed = useSelector(
      (s) => s.auth.emailVerificationFailed,
   );
   const IsResendingEmailLinkSuccessful = useSelector(
      (s) => s.auth.IsResendingEmailLinkSuccessful,
   );
   const resendEmailLinkSuccessful = useSelector(
      (s) => s.auth.resendEmailLinkSuccessful,
   );
   const errorMessage = useSelector((s) => s.auth.errorMessage);

   useEffect(() => {
      window.scrollTo(0, 0);
      const {token, email} = match.params;
      const data = {
         token: token,
         email: email,
      };
      dispatch(userActions.verifyEmail(data));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const resendLink = () => {
      const {user_id} = errorMessage;
      const data = {
         id: user_id,
      };
      dispatch(userActions.resendEmailLink(data));
   };
   if (emailVerificationSuccessful) {
      return (
         <section className="emailverify">
            {/* <img src={BlueYellow} alt="" className="img-fluid image" /> */}
            <MDBContainer className="align-items-center">
               <MDBCol className="smallbox2">
                  <Bluetick className="img-fluid tick" />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h3>Email Verification Successful</h3>
                  <br />
                  <Link to="/login">
                     <button
                        // to="/verify"
                        // disabled={!canSubmit}
                        type="submit"
                        className="signupbtn">
                        Proceed to Login
                     </button>
                  </Link>
               </MDBCol>
            </MDBContainer>
         </section>
      );
   } else if (emailVerificationFailed) {
      return (
         <section className="emailverify">
            {/* <img src={BlueYellow} alt="" className="img-fluid image" /> */}
            <MDBContainer className="align-items-center">
               <MDBCol className="smallbox2">
                  <Error className="img-fluid tick" />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h3>
                     Oops!...
                     <br />
                     {errorMessage?.message}
                  </h3>
                  <br />
                  {errorMessage?.user_id ? (
                     <Link>
                        <button
                           onClick={resendLink}
                           type="submit"
                           className="signupbtn">
                           {IsResendingEmailLinkSuccessful ? (
                              <div
                                 class="spinner-border text-blue"
                                 role="status">
                                 <span class="sr-only">Loading...</span>
                              </div>
                           ) : (
                              'Resend'
                           )}
                        </button>
                     </Link>
                  ) : (
                     <Link to="/login">
                        <button
                           // onClick={resendLink}
                           type="submit"
                           className="signupbtn">
                           Proceed to Login
                        </button>
                     </Link>
                  )}
               </MDBCol>
            </MDBContainer>
         </section>
      );
   } else if (resendEmailLinkSuccessful) {
      return (
         <section className="emailverify">
            {/* <img src={BlueYellow} alt="" className="img-fluid image" /> */}
            <MDBContainer className="align-items-center">
               <MDBCol className="smallbox2">
                  <Bluetick className="img-fluid tick" />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h3>Email Link Successfully Sent to your Email Address</h3>
                  <br />
                  <p>
                     A link has been sent to your email
                     <br />
                     to verify your account
                  </p>
               </MDBCol>
            </MDBContainer>
         </section>
      );
   } else {
      return null;
   }
}
