import {call, put, takeLatest, all} from 'redux-saga/effects';
import {
   customerConstants,
   userConstants,
   // loanConstants,
   merchantConstants,
} from '../../_constants';
import {appActions} from '../../_actions';
import {
   createRequestWithToken,
   checkStatus,
   getObjectFromStorage,
   clearObjectFromStorage,
} from '../../_helpers';

function* getLoanCustomers({data}) {
   yield put({type: customerConstants.REQUEST_LOAN_CUSTOMERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let loanCustomersUri = `${customerConstants.ALL_CUSTOMERS_URI}/loan`;

      // if (data?.page) {
      //    loanCustomersUri = `${loanCustomersUri}?page=${data.page + 1}`;
      // }

      const req = createRequestWithToken(loanCustomersUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      // const req = createRequestWithToken(loanCustomersUri, {method: 'GET'})(
      //    user?.token,
      // );
      const response = yield call(fetch, req);

      if (response?.status === 401) {
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.TOKEN_HAS_EXPIRED});
         return;
      }

      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_LOAN_CUSTOMERS_SUCCESS,
         loanCustomers: jsonResponse,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_LOAN_CUSTOMERS_ERROR});
   }
}

function* getWalletCustomers({data}) {
   yield put({type: customerConstants.REQUEST_WALLET_CUSTOMERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let walletCustomersUri = `${customerConstants.ALL_CUSTOMERS_URI}/wallet`;

      // if (data?.page) {
      //    walletCustomersUri = `${walletCustomersUri}?page=${data.page + 1}`;
      // }

      const req = createRequestWithToken(walletCustomersUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      // const req = createRequestWithToken(walletCustomersUri, {method: 'GET'})(
      //    user?.token,
      // );
      const response = yield call(fetch, req);

      if (response?.status === 401) {
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.TOKEN_HAS_EXPIRED});
         return;
      }

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_WALLET_CUSTOMERS_SUCCESS,
         walletCustomers: jsonResponse,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_WALLET_CUSTOMERS_ERROR});
   }
}

function* getAllCustomers({data}) {
   yield put({type: customerConstants.REQUEST_ALL_CUSTOMERS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let allCustomersUri = `${customerConstants.ALL_CUSTOMERS_URI}/all`;

      const req = createRequestWithToken(allCustomersUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_ALL_CUSTOMERS_SUCCESS,
         allCustomers: jsonResponse,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_ALL_CUSTOMERS_ERROR});
   }
}

function* getCustomerDetails({data}) {
   yield put({type: customerConstants.REQUEST_CUSTOMER_DETAILS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const detailsUrl = `${userConstants.GET_USER_URI}/${data}/info`;

      const req = createRequestWithToken(detailsUrl, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response, customerConstants.CUSTOMER_KEY);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_CUSTOMER_DETAILS_SUCCESS,
         customerDetails: jsonResponse,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_CUSTOMER_DETAILS_ERROR});
   }
}

function* getCustomerTransactionHistory({data}) {
   yield put({type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let customerTransactionsUri = `${customerConstants.CUSTOMER_TRANSACTIONS_URI}${data.user}/${data.uuid}`;
      // let customerTransactionsUri = `${merchantConstants.MERCHANT_TRANSACTIONS_URI}${data.user}/${data.uuid}?type=transaction`;

      if (data?.page) {
         customerTransactionsUri = `${customerTransactionsUri}?page=${
            data.page + 1
         }`;
      }

      // const req = createRequestWithToken(customerTransactionsUri, {
      //    method: 'GET',
      // })(user?.token);
      const req = createRequestWithToken(customerTransactionsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS,
         customerTransactions: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_ERROR,
      });
   }
}

function* getCustomerWalletHistory({data}) {
   yield put({type: customerConstants.REQUEST_CUSTOMER_WALLET_HISTORY});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let customerWalletUri = `${customerConstants.CUSTOMER_WALLET_URI}${data.uuid}`;

      if (data?.page) {
         customerWalletUri = `${customerWalletUri}?page=${data.page + 1}`;
      }

      const req = createRequestWithToken(customerWalletUri, {
         method: 'GET',
         // body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: customerConstants.REQUEST_CUSTOMER_WALLET_HISTORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_CUSTOMER_WALLET_HISTORY_SUCCESS,
         customerWalletHistory: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: customerConstants.REQUEST_CUSTOMER_WALLET_HISTORY_ERROR,
      });
   }
}

function* getCustomerLoanHistory({data}) {
   yield put({type: customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let customerLoanUri = `${customerConstants.CUSTOMER_LOAN_URI}${data.uuid}`;
      // let customerLoanUri = `${merchantConstants.MERCHANT_TRANSACTIONS_URI}${data.user}/${data.uuid}?type=loan`;
      // let customerLoanUri = `${merchantConstants.MERCHANT_TRANSACTIONS_URI}${data.user}/${data.uuid}?type=loan`;
      if (data?.page) {
         customerLoanUri = `${customerLoanUri}?page=${data.page + 1}`;
      }

      // const req = createRequestWithToken(customerLoanUri, {method: 'GET'})(
      //    user?.token,
      // );
      const req = createRequestWithToken(customerLoanUri, {
         method: 'GET',
         // body: JSON.stringify(data),
      })(user?.token);
      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS,
         customerLoan: jsonResponse.userLoan,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_ERROR});
   }
}

function* changeUserStatus({data}) {
   yield put({type: customerConstants.REQUEST_CHANGE_USER_STATUS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${customerConstants.CHANGE_USER_STATUS_URI}/${data._id}`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      //const jsonResponse = yield call(response.json.bind(response))

      yield put({
         type: customerConstants.CHANGE_USER_STATUS_SUCCESS,
         userDetails: data,
      });

      yield put(
         appActions.setSnackBar({
            message: 'status successfully changed',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: customerConstants.CHANGE_USER_STATUS_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchCustomer({data}) {
   yield put({type: customerConstants.REQUEST_SEARCH_CUSTOMER});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let searchCustomerUri =
         data.type === 'loan'
            ? `${customerConstants.ALL_CUSTOMERS_URI}/search/loan`
            : data.type === 'registered'
            ? `${customerConstants.ALL_CUSTOMERS_URI}/search`
            : `${customerConstants.ALL_CUSTOMERS_URI}/search/wallet`;
      // data.type === 'registered'
      //    ? `${userConstants.GET_ADMIN_USER_URI}/search/${data.searchName}?role=customer&type=${data.type}`
      //    : `${loanConstants.LOAN_URI}search/${data.searchName}`;

      // const req = createRequestWithToken(searchCustomerUri, {method: 'GET'})(
      //    user?.token,
      // );
      const req = createRequestWithToken(searchCustomerUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: customerConstants.SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type:
            data.type === 'registered'
               ? customerConstants.SEARCH_REGISTERED_CUSTOMER_SUCCESS
               : customerConstants.SEARCH_LOAN_CUSTOMER_SUCCESS,
         customer: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: customerConstants.SEARCH_CUSTOMER_ERROR,
         error: errorMessage,
      });
   }
}

function* getLoanCustomersSagaWatcher() {
   yield takeLatest(customerConstants.GET_LOAN_CUSTOMERS, getLoanCustomers);
}

function* getWalletCustomersSagaWatcher() {
   yield takeLatest(customerConstants.GET_WALLET_CUSTOMERS, getWalletCustomers);
}

function* getAllCustomersSagaWatcher() {
   yield takeLatest(customerConstants.GET_ALL_CUSTOMERS, getAllCustomers);
}

function* getCustomerDetailsSagaWatcher() {
   yield takeLatest(customerConstants.GET_CUSTOMER_DETAILS, getCustomerDetails);
}

function* getCustomerTransactionHistorySagaWatcher() {
   yield takeLatest(
      customerConstants.GET_CUSTOMER_TRANSACTION_HISTORY,
      getCustomerTransactionHistory,
   );
}

function* getCustomerWalletHistorySagaWatcher() {
   yield takeLatest(
      customerConstants.GET_CUSTOMER_WALLET_HISTORY,
      getCustomerWalletHistory,
   );
}

function* getCustomerLoanHistorySagaWatcher() {
   yield takeLatest(
      customerConstants.GET_CUSTOMER_LOAN_HISTORY,
      getCustomerLoanHistory,
   );
}

function* changeUserStatusSagaWatcher() {
   yield takeLatest(customerConstants.CHANGE_USER_STATUS, changeUserStatus);
}

function* searchCustomerWatcher() {
   yield takeLatest(customerConstants.SEARCH_CUSTOMER, searchCustomer);
}

export default function* rootSaga() {
   yield all([
      getLoanCustomersSagaWatcher(),
      getWalletCustomersSagaWatcher(),
      getAllCustomersSagaWatcher(),
      getCustomerDetailsSagaWatcher(),
      getCustomerTransactionHistorySagaWatcher(),
      getCustomerWalletHistorySagaWatcher(),
      getCustomerLoanHistorySagaWatcher(),
      changeUserStatusSagaWatcher(),
      searchCustomerWatcher(),
   ]);
}
