import {combineReducers} from 'redux';
import {customerConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingLoanCustomers = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_LOAN_CUSTOMERS:
         return true;
      case customerConstants.REQUEST_LOAN_CUSTOMERS_ERROR:
         return false;
      case customerConstants.REQUEST_LOAN_CUSTOMERS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingWalletCustomers = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_WALLET_CUSTOMERS:
         return true;
      case customerConstants.REQUEST_WALLET_CUSTOMERS_ERROR:
         return false;
      case customerConstants.REQUEST_WALLET_CUSTOMERS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAllCustomers = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_ALL_CUSTOMERS:
         return true;
      case customerConstants.REQUEST_ALL_CUSTOMERS_ERROR:
         return false;
      case customerConstants.REQUEST_ALL_CUSTOMERS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingCustomerDetails = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_DETAILS:
         return true;
      case customerConstants.REQUEST_CUSTOMER_DETAILS_ERROR:
         return false;
      case customerConstants.REQUEST_CUSTOMER_DETAILS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingCustomerTransactionHistory = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY:
         return true;
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_ERROR:
         return false;
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS:
         return false;
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingCustomerLoanHistory = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY:
         return true;
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_ERROR:
         return false;
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS:
         return false;
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsUpdatingCustomer = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_UPDATE_CUSTOMER:
         return true;
      case customerConstants.UPDATE_CUSTOMER_SUCCESS:
         return false;
      case customerConstants.UPDATE_CUSTOMER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsSearchingCustomer = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_SEARCH_CUSTOMER:
         return true;
      case customerConstants.SEARCH_REGISTERED_CUSTOMER_SUCCESS:
         return false;
      case customerConstants.SEARCH_LOAN_CUSTOMER_SUCCESS:
         return false;
      case customerConstants.SEARCH_WALLET_CUSTOMER_SUCCESS:
         return false;
      case customerConstants.SEARCH_CUSTOMER_ERROR:
         return false;
      default:
         return state;
   }
};

const loanCustomersList = (state = [], action) => {
   switch (action.type) {
      case customerConstants.REQUEST_LOAN_CUSTOMERS_SUCCESS: {
         return action.loanCustomers.data.docs;
      }
      case customerConstants.SEARCH_LOAN_CUSTOMER_SUCCESS: {
         return action.customer.data.docs;
      }
      case customerConstants.SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const walletCustomersList = (state = [], action) => {
   switch (action.type) {
      case customerConstants.REQUEST_WALLET_CUSTOMERS_SUCCESS: {
         return action.walletCustomers.data.docs;
      }
      case customerConstants.SEARCH_WALLET_CUSTOMER_SUCCESS: {
         return action.customer.data.docs;
      }
      case customerConstants.SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const allCustomersList = (state = [], action) => {
   switch (action.type) {
      case customerConstants.REQUEST_ALL_CUSTOMERS_SUCCESS: {
         return action.allCustomers.data.docs;
      }
      case customerConstants.SEARCH_REGISTERED_CUSTOMER_SUCCESS: {
         return action.customer.data.docs;
      }
      case customerConstants.SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const customerDetails = (
   state = null,
   {type, customerDetails, userDetails},
) => {
   switch (type) {
      case customerConstants.REQUEST_CUSTOMER_DETAILS_SUCCESS:
         return updateObject(state, customerDetails);
      // case customerConstants.CHANGE_USER_STATUS_SUCCESS:
      //    return updateObject(state, userDetails);

      default:
         return state;
   }
};

const emptyState = {
   company_name: '',
   product: '',
   amount: '',
   created_at: '',
};

const customerLoanList = (state = [], action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS: {
         return action.customerLoan.docs;
      }
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS_WITHOUT_DATA: {
         return updateObject(state, emptyState);
      }
      default:
         return state;
   }
};

const customerTransactionList = (state = [], action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS: {
         return action.customerTransactions.transactions.docs;
      }
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA: {
         return updateObject(state, emptyState);
      }
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_LOAN_CUSTOMERS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.loanCustomers.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case customerConstants.REQUEST_WALLET_CUSTOMERS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.walletCustomers.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case customerConstants.REQUEST_ALL_CUSTOMERS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.allCustomers.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case customerConstants.SEARCH_REGISTERED_CUSTOMER_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.customer.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const transactionPagination = (state = {}, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.customerTransactions.transactions;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const loanPagination = (state = {}, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_CUSTOMER_LOAN_HISTORY_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.customerLoan;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingLoanCustomers,
   IsRequestingWalletCustomers,
   IsRequestingAllCustomers,
   IsRequestingCustomerDetails,
   IsRequestingCustomerTransactionHistory,
   IsRequestingCustomerLoanHistory,
   IsUpdatingCustomer,
   IsSearchingCustomer,
   loanCustomersList,
   walletCustomersList,
   allCustomersList,
   customerTransactionList,
   customerLoanList,
   customerDetails,
   pagination,
   transactionPagination,
   loanPagination,
});

export default rootReducer;
