import {combineReducers} from 'redux';
import {
   merchantConstants,
   customerConstants,
   userConstants,
} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingPremiumMerchants = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_PREMIUM_MERCHANTS:
         return true;
      case merchantConstants.REQUEST_PREMIUM_MERCHANTS_ERROR:
         return false;
      case merchantConstants.REQUEST_PREMIUM_MERCHANTS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAllMerchants = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_ALL_MERCHANTS:
         return true;
      case merchantConstants.REQUEST_ALL_MERCHANTS_ERROR:
         return false;
      case merchantConstants.REQUEST_ALL_MERCHANTS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingMerchantDetails = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_DETAILS:
         return true;
      case merchantConstants.REQUEST_MERCHANT_DETAILS_ERROR:
         return false;
      case merchantConstants.REQUEST_MERCHANT_DETAILS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingMerchantTransactionHistory = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY:
         return true;
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_ERROR:
         return false;
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS:
         return false;
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingMerchantSettlementHistory = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY:
         return true;
      case merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_ERROR:
         return false;
      case merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS:
         return false;
      case merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsUpgradingMerchant = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_UPGRADE_MERCHANT:
         return true;
      case merchantConstants.REQUEST_UPGRADE_MERCHANT_SUCCESS:
         return false;
      case merchantConstants.REQUEST_UPGRADE_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const merchantUpgraded = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_UPGRADE_MERCHANT:
         return false;
      case merchantConstants.REQUEST_UPGRADE_MERCHANT_SUCCESS:
         return true;
      case merchantConstants.REQUEST_UPGRADE_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const IsVerifyingMerchant = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_VERIFY_MERCHANT:
         return true;
      case merchantConstants.REQUEST_VERIFY_MERCHANT_SUCCESS:
         return false;
      case merchantConstants.REQUEST_VERIFY_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const IsSearchingMerchant = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_SEARCH_MERCHANT:
         return true;
      case merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS:
         return false;
      case merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS:
         return false;
      case merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS_WITHOUT_DATA:
         return false;
      case merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS_WITHOUT_DATA:
         return false;
      case merchantConstants.SEARCH_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   children: [],
   city: '',
   country: '',
   created_at: '',
   is_active: false,
   is_premium: false,
   merchant_address: '',
   merchant_email: '',
   merchant_name: '',
   merchant_phone: '',
   registrationComplete: false,
   state: '',
   updated_at: '',
   user_id: '',
   verified: false,
   __v: 0,
   _id: '',
};

const premiumMerchantsList = (state = [], action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_PREMIUM_MERCHANTS_SUCCESS: {
         return action.premiumMerchants.merchants.docs;
      }
      case merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS: {
         return action.merchant.data.docs;
      }
      case merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const allMerchantsList = (state = [], action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_ALL_MERCHANTS_SUCCESS: {
         return action.allMerchants.merchants.docs;
      }
      case merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS: {
         return action.merchant.data.docs;
      }
      case merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const merchantDetails = (
   state = null,
   {type, merchantDetails, userDetails, upgradedMerchant},
) => {
   switch (type) {
      case merchantConstants.REQUEST_MERCHANT_DETAILS_SUCCESS:
         return updateObject(state, merchantDetails);
      case customerConstants.CHANGE_USER_STATUS_SUCCESS:
         return updateObject(state, userDetails);
      case merchantConstants.REQUEST_VERIFY_MERCHANT_SUCCESS: {
         const user = {
            ...state,
            merchant_details: {
               ...state.merchant_details,
               verified: true,
            },
         };
         return updateObject(state, user);
      }
      case userConstants.VERIFY_USER_BVN_SUCCESS: {
         const user = {
            ...state,
            user_details: {
               ...state.user_details,
               bvn_verified: true,
            },
         };
         return updateObject(state, user);
      }
      case merchantConstants.REQUEST_UPGRADE_MERCHANT_SUCCESS: {
         const user = {
            ...state,
            merchant_details: upgradedMerchant.data,
         };
         return updateObject(state, user);
      }
      default:
         return state;
   }
};

const merchantSettlementList = (state = [], action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS: {
         return action.merchantSettlement.merchant_history.docs;
      }
      case merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS_WITHOUT_DATA: {
         return updateObject(state, emptyState);
      }
      default:
         return state;
   }
};

const merchantTransactionList = (state = [], action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS: {
         return action.merchantTransactions.transactions.docs;
      }
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA: {
         return updateObject(state, emptyState);
      }
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_PREMIUM_MERCHANTS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.premiumMerchants.merchants;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case merchantConstants.REQUEST_ALL_MERCHANTS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.allMerchants.merchants;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.merchant.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.merchant.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const transactionPagination = (state = {}, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.merchantTransactions.transactions;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingPremiumMerchants,
   IsRequestingAllMerchants,
   IsRequestingMerchantDetails,
   IsRequestingMerchantTransactionHistory,
   IsRequestingMerchantSettlementHistory,
   IsUpgradingMerchant,
   merchantUpgraded,
   IsVerifyingMerchant,
   IsSearchingMerchant,
   premiumMerchantsList,
   allMerchantsList,
   merchantDetails,
   merchantSettlementList,
   merchantTransactionList,
   pagination,
   transactionPagination,
});

export default rootReducer;
