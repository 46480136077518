import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MDBRow} from 'mdbreact';
import {Link, matchPath, useLocation} from 'react-router-dom';
import Modal from 'react-modal';
import classNames from 'classnames';
// import Switch from '@material-ui/core/Switch';
import LetteredAvatar from 'lettered-avatar';

import Formsy from 'formsy-react';
import TextInput from '../../_components/TextInput';
import Loader from '../../_helpers/loader';
import {numberWithCommas} from '../../_helpers';

import './index.scss';
import './view.scss';
import TransactionHistory from './transactionHistory';
import WalletHistory from './walletHistory';
import LoanHistory from './loanHistory';

// import {ReactComponent as Prev} from '../../_assets/icons/1arr-left.svg';
// import {ReactComponent as Next} from '../../_assets/icons/1arr-right.svg';
import {ReactComponent as AcctCredited} from '../../_assets/icons/1acct-credited.svg';
// import img from '../../_assets/icons/1custprofile.svg';
// import img2 from '../../_assets/icons/1user-hash.svg';
// import {ReactComponent as Pdf} from '../../_assets/icons/1pdf-file.svg';

// import file from '../../_assets/icons/1pdf-file.svg';
import {customerActions, userActions} from '../../_actions';

export function CustomerProfile({match, location}) {
   const {pathname} = useLocation();
   const dispatch = useDispatch();
   const IsRequestingCustomerDetails = useSelector(
      (s) => s.customer.IsRequestingCustomerDetails,
   );
   const customerDetails = useSelector((s) => s.customer.customerDetails);
   const IsVerifyingUserBvn = useSelector((s) => s.user.IsVerifyingUserBvn);
   const viewLoanCustomer = matchPath(pathname, '/customer/loan/:uuid');

   console.log('location', location);
   useEffect(() => {
      const {uuid} = match.params;
      const {state} = location;
      const wallet = {
         uuid: state?.customerId,
         // uuid: uuid,
         type: 'transaction',
         user: 'customer',
      };
      const loan = {
         uuid: state?.customerId,
         // uuid: uuid,
         type: 'loan',
         user: 'customer',
      };
      dispatch(customerActions.getCustomerDetails(uuid));
      dispatch(customerActions.getCustomerTransactionHistory(wallet));
      dispatch(customerActions.getCustomerWalletHistory(loan));
      dispatch(customerActions.getCustomerLoanHistory(loan));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [shown, setShown] = useState(1);

   const [tabshown, setTabshown] = useState(1);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [verifyBvnModalIsOpen, setVerifyBvnModalIsOpen] = useState(false);

   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [status, setStatus] = useState(false);

   useEffect(() => {
      if (customerDetails?.user !== null) {
         setStatus(customerDetails?.user?.isActive);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [customerDetails]);

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
      },
   };

   const handleSwitchChange = () => {
      const {user} = customerDetails;
      const fields = {
         _id: user._id,
         status: !status,
      };
      setStatus(!status);
      dispatch(customerActions.changeUserStatus(fields));
   };

   const handleSubmit = () => {
      const {user} = customerDetails;
      const fields = {
         _id: user._id,
         status: true,
         // bvn: user.bvn,
      };
      dispatch(userActions.verifyUserBvn(fields));
   };

   // const setModalIsOpenToTrue = () => {
   //    setModalIsOpen(true);
   // };

   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      setModalIsOpen(false);
   };

   return (
      <MDBRow className="customer-page m-0 w-100 mb-5">
         <div className="col-md-6 col-12">
            <div className="history d-flex justify-content-between">
               <ul className="d-flex align-items-center text-nowrap">
                  <li>
                     <div
                        onClick={() => setShown(1)}
                        className={classNames('w-100 d-block', {
                           active: shown === 1,
                        })}>
                        Transaction History
                     </div>
                  </li>
                  {!viewLoanCustomer && (
                     <li>
                        <div
                           onClick={() => setShown(2)}
                           className={classNames('w-100 d-block', {
                              active: shown === 2,
                           })}>
                           Wallet History
                        </div>
                     </li>
                  )}
                  {viewLoanCustomer && (
                     <li>
                        <div
                           onClick={() => setShown(2)}
                           className={classNames('w-100 d-block', {
                              active: shown === 2,
                           })}>
                           Loan History
                        </div>
                     </li>
                  )}
               </ul>
               {/* <div className="price">#330,000</div> */}
            </div>
            {/* {shown === 1 && <TransactionHistory match={match} />}
            {shown === 2 && <LoanHistory match={match} />} */}
            {shown === 1 && <TransactionHistory match={match} />}
            {shown === 2 && !viewLoanCustomer && (
               <WalletHistory match={match} />
            )}
            {shown === 2 && viewLoanCustomer && <LoanHistory match={match} />}
         </div>

         <div className="customer-details col-md-6 col-12">
            <div className="message d-flex justify-content-end">
               {customerDetails?.user?.bvn && (
                  <Link
                     onClick={
                        !customerDetails?.user?.bvn_verified
                           ? () => setVerifyBvnModalIsOpen(true)
                           : null
                     }>
                     {customerDetails?.user?.bvn_verified
                        ? 'BVN Verified'
                        : 'Verify BVN'}
                  </Link>
               )}
            </div>
            <div className="details">
               {IsRequestingCustomerDetails ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <div className="profile">
                     <div className="profile-pic">
                        <div className="img d-flex justify-content-center">
                           {/* <img
                           src={img}
                           alt="profile-img"
                           className="rounded-circle img-fluid d-flex justify-content-center"
                           style={{width: '3.3rem'}}
                        /> */}
                           <LetteredAvatar
                              name={
                                 customerDetails?.user?.firstname +
                                 ' ' +
                                 customerDetails?.user?.lastname
                              }
                              options={{
                                 size: 50,
                                 twoLetter: true,
                                 //shape: 'square',
                                 bgColor: '',
                                 imgClass: 'image-responsive user-image',
                                 imgWidth: '3rem',
                                 imgHeight: '3rem',
                              }}
                           />
                        </div>
                        <span className="d-flex justify-content-center">
                           {customerDetails?.user?.firstname +
                              ' ' +
                              customerDetails?.user?.lastname}
                        </span>
                     </div>
                     <div className="verify-loan d-flex justify-content-center">
                        <h6 className="py-2 pr-2 bold">Activate/Deactivate:</h6>
                        <label class="switch">
                           <input
                              type="checkbox"
                              onChange={handleSwitchChange}
                              checked={status}
                           />
                           <span class="slider round"></span>
                        </label>
                     </div>
                  </div>
               )}

               <div className="detail-tabs">
                  <ul className="d-flex justify-content-between">
                     <li>
                        <div
                           onClick={() => setTabshown(1)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 1,
                           })}>
                           Personal Info
                        </div>
                     </li>
                     <li>
                        <div
                           onClick={() => setTabshown(2)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 2,
                           })}>
                           Bank Details
                        </div>
                     </li>
                     <li>
                        <div
                           onClick={() => setTabshown(3)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 3,
                           })}>
                           Card Details
                        </div>
                     </li>
                     <li>
                        <div
                           onClick={() => setTabshown(4)}
                           className={classNames('w-100 d-block', {
                              active: tabshown === 4,
                           })}>
                           Next of Kin
                        </div>
                     </li>
                  </ul>
               </div>

               {tabshown === 1 && (
                  <div className="data d-flex">
                     <div className="mr-5">
                        <h6>Full Name</h6>
                        <p>
                           {customerDetails?.user?.firstname +
                              ' ' +
                              customerDetails?.user?.lastname}
                        </p>
                        <h6>Email</h6>
                        <p>{customerDetails?.user?.email}</p>
                        <h6>Phone Number</h6>
                        <p>
                           {customerDetails?.user?.phone_number?.replace(
                              /^.{3}/g,
                              '0',
                           )}
                        </p>
                        <h6>Company Name</h6>
                        <p>
                           {
                              customerDetails?.data?.employment_details
                                 ?.company_name
                           }
                        </p>
                        <h6>Company Address</h6>
                        <p>
                           {
                              customerDetails?.data?.employment_details
                                 ?.company_address
                           }
                        </p>
                     </div>
                     <div>
                        <h6>Salary Date</h6>
                        <p>
                           {
                              customerDetails?.data?.employment_details
                                 ?.salary_date
                           }
                        </p>
                        <h6>Occupation</h6>
                        <p>
                           {
                              customerDetails?.data?.employment_details
                                 ?.job_description
                           }
                        </p>
                        <h6>Salary</h6>
                        <p>
                           {numberWithCommas(
                              customerDetails?.data?.employment_details
                                 ?.monthly_salary,
                           )}
                        </p>
                        {/* <h6>Staff Number</h6>
                        <p>27/2/2021</p> */}
                        {/* <h6>Statement 1</h6>
                        <p>
                           <a download="" href={file}>
                              <Pdf style={{width: '1rem'}} />
                           </a>
                        </p>
                        <h6>Statement 2</h6>
                        <p>
                           <a download="" href={file}>
                              <Pdf style={{width: '1rem'}} />
                           </a>
                        </p>
                        <h6>Statement 2</h6>
                        <p>
                           <a download="" href={file}>
                              <Pdf style={{width: '1rem'}} />
                           </a>
                        </p> */}
                     </div>
                  </div>
               )}
               {tabshown === 2 && (
                  <div className="data d-flex">
                     {customerDetails.data?.bank_details?.length > 0 ? (
                        customerDetails.data?.bank_details.map((row) => {
                           return (
                              <div className="mr-3">
                                 <h6>Bank</h6>
                                 <p>{row.bank_name}</p>
                                 <h6>Account Number</h6>
                                 <p>{row.account_number}</p>
                                 <h6>Account Name</h6>
                                 <p>{row.account_name}</p>
                              </div>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </div>
               )}
               {tabshown === 3 && (
                  <div className="data d-flex">
                     {customerDetails.data?.card_details?.length > 0 ? (
                        customerDetails.data?.card_details.map((row) => {
                           return (
                              <div className="mr-3">
                                 <h6>Card Number</h6>
                                 <p>
                                    {row.first_6digits +
                                       '******' +
                                       row.last_4digits}
                                 </p>
                                 <h6>Card Type</h6>
                                 <p>{row.type}</p>
                                 <h6>Expiry Date</h6>
                                 <p>{row.expiry}</p>
                              </div>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </div>
               )}
               {tabshown === 4 && (
                  <div className="data d-flex">
                     {customerDetails?.data?.next_of_kin ? (
                        <>
                           <div className="mr-5">
                              <h6>Full Name</h6>
                              <p>
                                 {customerDetails?.data?.next_of_kin
                                    ?.firstname +
                                    ' ' +
                                    customerDetails?.data?.next_of_kin
                                       ?.lastname}
                              </p>
                              <h6>Phone Number</h6>
                              <p>
                                 {
                                    customerDetails?.data?.next_of_kin
                                       ?.phone_number
                                 }
                              </p>
                              <h6>Relationship</h6>
                              <p>
                                 {
                                    customerDetails?.data?.next_of_kin
                                       ?.relationship
                                 }
                              </p>
                           </div>
                           <div>
                              <h6>Email</h6>
                              <p>{customerDetails?.data?.next_of_kin?.email}</p>
                              <h6>Gender</h6>
                              <p>
                                 {customerDetails?.data?.next_of_kin?.gender}
                              </p>
                              <h6>Address</h6>
                              <p>
                                 {
                                    customerDetails?.data?.next_of_kin
                                       ?.house_address
                                 }
                              </p>
                              {/* <h6>Salary Date</h6>
                              <p>27/2/2021</p>
                              <h6>Staff Number</h6>
                              <p>27/2/2021</p> */}
                              {/* <h6>Statement 1</h6>
                              <p>
                                 <a download="" href={file}>
                                    <Pdf style={{width: '1rem'}} />
                                 </a>
                              </p>
                              <h6>Statement 2</h6>
                              <p>
                                 <a download="" href={file}>
                                    <Pdf style={{width: '1rem'}} />
                                 </a>
                              </p>
                              <h6>Statement 2</h6>
                              <p>
                                 <a download="" href={file}>
                                    <Pdf style={{width: '1rem'}} />
                                 </a>
                              </p> */}
                           </div>
                        </>
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </div>
               )}
               {/* {
               tabshown === 3 && (customerDetails?.data?.next_of_kin
                  ? (
                  <div className="data d-flex">
                     <div className="mr-5">
                        <h6>Full Name</h6>
                        <p>
                           {customerDetails?.data?.next_of_kin?.firstname +
                              ' ' +
                              customerDetails?.data?.next_of_kin?.lastname}
                        </p>
                        <h6>Email</h6>
                        <p>{customerDetails?.data?.next_of_kin?.email}</p>
                        <h6>Phone Number</h6>
                        <p>
                           {customerDetails?.data?.next_of_kin?.phone_number}
                        </p>
                        <h6>Gender</h6>
                        <p>{customerDetails?.data?.next_of_kin?.gender}</p>
                        <h6>Relationship</h6>
                        <p>
                           {customerDetails?.data?.next_of_kin?.relationship}
                        </p>
                        <h6>Address</h6>
                        <p>
                           {customerDetails?.data?.next_of_kin?.house_address}
                        </p>
                     </div>
                     <div>
                        <h6>Salary Date</h6>
                        <p>27/2/2021</p>
                        <h6>Staff Number</h6>
                        <p>27/2/2021</p>
                        <h6>Statement 1</h6>
                        <p>
                           <a download="" href={file}>
                              <Pdf style={{width: '1rem'}} />
                           </a>
                        </p>
                        <h6>Statement 2</h6>
                        <p>
                           <a download="" href={file}>
                              <Pdf style={{width: '1rem'}} />
                           </a>
                        </p>
                        <h6>Statement 2</h6>
                        <p>
                           <a download="" href={file}>
                              <Pdf style={{width: '1rem'}} />
                           </a>
                        </p>
                     </div>
                  </div>
                  )
                  :
                  
                     (<div className="data d-flex">
                              <p align="center" colSpan={5}>
                                 No result to display
                              </p>
                              <div className="data d-flex">)
                  )
               } */}
            </div>
            {/* // <div className="data d-flex">
                  //    <div className="mr-3">
                  //       <h6>Bank</h6>
                  //       <p>GTBank</p>
                  //       <h6>Account Name</h6>
                  //       <p>Emeka Chuks Champion</p>
                  //       <h6>Relationship</h6>
                  //       <p>Wife</p>
                  //    </div>
                  //    <div>
                  //       <h6>Account Number</h6>
                  //       <p>1234567890</p>
                  //       <h6>Address</h6>
                  //       <p>4, Lade Street, Gbagada</p>
                  //    </div>
                  // </div> */}
            <Modal
               isOpen={modalIsOpen}
               style={customStyles}
               onRequestClose={() => setModalIsOpen(false)}>
               <Formsy className="__form d-flex flex-column">
                  <h4 className="text-left mb-3">Grant Loan</h4>
                  <p className="text-left loan mb-4">
                     Loans are transferred to customer's account
                  </p>
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-2 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="Customer Name"
                     className="input1 border-0 w-100"
                     placeholder="Lade jones"
                     disabled
                  />
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     useFocusedError
                     name="limit"
                     type="text"
                     labelClassName="label"
                     labelText="Limit"
                     className="input2 border-0 w-100"
                     placeholder="20,000"
                  />
                  <TextInput
                     wrapperClassName="input-wrapper"
                     mainWrapperClassName="w-100 mb-3 mb-s-4"
                     useFocusedError
                     name="name"
                     type="text"
                     labelClassName="label"
                     labelText="Amount"
                     className="input2 border-0 w-100"
                     placeholder="20,000"
                  />
                  <div className="input-wrapper">
                     <label className="label d-flex">Due Date</label>
                     <input
                        type="text"
                        placeholder="20,000"
                        className="input2"
                     />
                  </div>
                  <button>
                     <div onClick={setSecModalIsOpenToTrue} className="button">
                        Submit
                     </div>
                  </button>
               </Formsy>
            </Modal>

            <Modal
               isOpen={secModalIsOpen}
               style={customStyles}
               onRequestClose={() => setSecModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <p className="py-2">Customer's account has been credited</p>
                  <button className="content-btn">
                     <Link to="/customer/loan">Home</Link>
                  </button>
               </div>
            </Modal>
            <Modal
               isOpen={verifyBvnModalIsOpen}
               style={customStyles}
               onRequestClose={() => setVerifyBvnModalIsOpen(false)}>
               <div className="content d-flex flex-column">
                  <AcctCredited className="credited" style={{width: '6rem'}} />
                  <p className="py-2">Verify Customer's BVN</p>
                  <p className="py-2">BVN: {customerDetails?.user?.bvn}</p>
                  {/* <p></p> */}
                  <button onClick={handleSubmit}>
                     <Link>
                        {IsVerifyingUserBvn ? (
                           <div class="spinner-border text-white" role="status">
                              <span class="sr-only">Loading...</span>
                           </div>
                        ) : (
                           'Proceed'
                        )}
                     </Link>
                  </button>
               </div>
            </Modal>
         </div>
      </MDBRow>
   );
}
