import React, {useCallback} from 'react';
import {MDBContainer, MDBCol} from 'mdbreact';
import {useDispatch, useSelector} from 'react-redux';
// import BlueYellow from '../../_assets/images/blueandyellow.svg';
import {ReactComponent as Bluetick} from '../../_assets/icons/bluetick.svg';
import './index.scss';
import {userActions} from '../../_actions';

function Verification() {
   const dispatch = useDispatch();
   const {IsSendingVerificationEmail, unverifiedUserId} = useSelector(
      (s) => s.auth,
   );

   const handleClick = useCallback(() => {
      if (!IsSendingVerificationEmail) {
         dispatch(userActions.resendEmailLink({id: unverifiedUserId}));
      }
      // console.log('unverifiedUserId', unverifiedUserId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [unverifiedUserId, IsSendingVerificationEmail]);

   return (
      <section className="verify">
         {/* <img src={BlueYellow} alt="" className="img-fluid image" /> */}
         <MDBContainer className="align-items-center">
            <MDBCol className="smallbox2">
               <Bluetick className="img-fluid tick" />
               <br />
               <br />
               <br />
               <br />
               <h3>Email Verification</h3>
               <p>
                  A link has been sent to your email
                  <br />
                  to verify your account
               </p>
               <p>
                  Didn't get a mail?{' '}
                  <span className="resend" onClick={handleClick}>
                     {IsSendingVerificationEmail ? 'Resending...' : 'Resend'}
                  </span>
               </p>
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default Verification;
