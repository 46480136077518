import {call, put, takeLatest, all} from 'redux-saga/effects';
import {
   merchantConstants,
   userConstants,
   // transactionConstants,
} from '../../_constants';
import {appActions} from '../../_actions';
import {
   createRequestWithToken,
   checkStatus,
   getObjectFromStorage,
} from '../../_helpers';

function* getPremiumMerchants({data}) {
   yield put({type: merchantConstants.REQUEST_PREMIUM_MERCHANTS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let merchantsUri = `${merchantConstants.ALL_MERCHANTS_URI}?type=premium`;

      if (data?.page) {
         merchantsUri = `${merchantsUri}&page=${data.page + 1}`;
      }

      const req = createRequestWithToken(merchantsUri, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_PREMIUM_MERCHANTS_SUCCESS,
         premiumMerchants: jsonResponse,
      });
   } catch (error) {
      yield put({type: merchantConstants.REQUEST_PREMIUM_MERCHANTS_ERROR});
   }
}

function* getAllMerchants({data}) {
   yield put({type: merchantConstants.REQUEST_ALL_MERCHANTS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let allMerchantsUri = `${merchantConstants.ALL_MERCHANTS_URI}?type=basic`;

      if (data?.page) {
         allMerchantsUri = `${allMerchantsUri}&page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allMerchantsUri, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_ALL_MERCHANTS_SUCCESS,
         allMerchants: jsonResponse,
      });
   } catch (error) {
      yield put({type: merchantConstants.REQUEST_ALL_MERCHANTS_ERROR});
   }
}

function* verifyMerchant({data}) {
   yield put({type: merchantConstants.REQUEST_VERIFY_MERCHANT});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const url = `${merchantConstants.GET_MERCHANTS_URI}${data}/verify`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         // body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_VERIFY_MERCHANT_SUCCESS,
         verifiedMerchant: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'merchant successfully verified',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: merchantConstants.REQUEST_VERIFY_MERCHANT_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getMerchantDetails({data}) {
   yield put({type: merchantConstants.REQUEST_MERCHANT_DETAILS});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      const detailsUrl = `${merchantConstants.GET_MERCHANTS_URI}${data}/info`;

      const req = createRequestWithToken(detailsUrl, {method: 'GET'})(
         user?.token,
      );

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_MERCHANT_DETAILS_SUCCESS,
         merchantDetails: jsonResponse,
      });
   } catch (error) {
      yield put({type: merchantConstants.REQUEST_MERCHANT_DETAILS_ERROR});
   }
}

function* getMerchantTransactionHistory({data}) {
   yield put({type: merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let merchantTransactionsUri = `${merchantConstants.MERCHANT_TRANSACTIONS_URI}${data.user}/${data.uuid}?type=${data.type}`;

      if (data?.page) {
         merchantTransactionsUri = `${merchantTransactionsUri}?page=${
            data.page + 1
         }`;
      }

      const req = createRequestWithToken(merchantTransactionsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_SUCCESS,
         merchantTransactions: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: merchantConstants.REQUEST_MERCHANT_TRANSACTION_HISTORY_ERROR,
      });
   }
}

function* getmerchantSettlementHistory({data}) {
   yield put({type: merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let merchantSettlementUri = `${merchantConstants.MERCHANT_TRANSACTIONS_URI}${data.user}/${data.uuid}?type=${data.type}`;

      if (data?.page) {
         merchantSettlementUri = `${merchantSettlementUri}?page=${
            data.page + 1
         }`;
      }

      const req = createRequestWithToken(merchantSettlementUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_SUCCESS,
         merchantSettlement: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: merchantConstants.REQUEST_MERCHANT_SETTLEMENT_HISTORY_ERROR,
      });
   }
}

function* upgradeMerchant({data}) {
   yield put({type: merchantConstants.REQUEST_UPGRADE_MERCHANT});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      // const url = `${merchantConstants.GET_MERCHANTS_URI}${data}/upgrade`;
      const url = `${merchantConstants.GET_MERCHANTS_URI}${data?.id}/premium`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.REQUEST_UPGRADE_MERCHANT_SUCCESS,
         upgradedMerchant: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'MERCHANT successfully upgraded',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: merchantConstants.REQUEST_UPGRADE_MERCHANT_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message
               ? errorMessage.message
               : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchMerchant({data}) {
   yield put({type: merchantConstants.REQUEST_SEARCH_MERCHANT});

   try {
      const user = yield call(
         getObjectFromStorage,
         userConstants.ADMIN_USER_KEY,
      );

      let searchMerchantUri = `${merchantConstants.GET_MERCHANTS_URI}search/${data.searchName}?type=${data.type}`;
      // let searchMerchantUri = `${merchantConstants.GET_MERCHANTS_URI}search/${data.searchName}?type=${data.type}`;
      // let searchMerchantUri = `${merchantConstants.GET_MERCHANTS_URI}search`;

      const req = createRequestWithToken(searchMerchantUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);
      // const req = createRequestWithToken(searchMerchantUri, {method: 'GET'})(
      //    user?.token,
      // );

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type:
               data.type === 'basic'
                  ? merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS_WITHOUT_DATA
                  : merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type:
            data.type === 'basic'
               ? merchantConstants.SEARCH_BASIC_MERCHANT_SUCCESS
               : merchantConstants.SEARCH_PREMIUM_MERCHANT_SUCCESS,
         merchant: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: merchantConstants.SEARCH_MERCHANT_ERROR,
         error: errorMessage,
      });
   }
}

function* getPremiumMerchantsSagaWatcher() {
   yield takeLatest(
      merchantConstants.GET_PREMIUM_MERCHANTS,
      getPremiumMerchants,
   );
}

function* getAllMerchantsSagaWatcher() {
   yield takeLatest(merchantConstants.GET_ALL_MERCHANTS, getAllMerchants);
}

function* verifyMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.VERIFY_MERCHANT, verifyMerchant);
}

function* getMerchantDetailsSagaWatcher() {
   yield takeLatest(merchantConstants.GET_MERCHANT_DETAILS, getMerchantDetails);
}

function* getMerchantTransactionHistorySagaWatcher() {
   yield takeLatest(
      merchantConstants.GET_MERCHANT_TRANSACTION_HISTORY,
      getMerchantTransactionHistory,
   );
}

function* getmerchantSettlementHistorySagaWatcher() {
   yield takeLatest(
      merchantConstants.GET_MERCHANT_SETTLEMENT_HISTORY,
      getmerchantSettlementHistory,
   );
}

function* upgradeMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.UPGRADE_MERCHANT, upgradeMerchant);
}

function* searchMerchantWatcher() {
   yield takeLatest(merchantConstants.SEARCH_MERCHANT, searchMerchant);
}

export default function* rootSaga() {
   yield all([
      getPremiumMerchantsSagaWatcher(),
      getAllMerchantsSagaWatcher(),
      verifyMerchantSagaWatcher(),
      getMerchantDetailsSagaWatcher(),
      getMerchantTransactionHistorySagaWatcher(),
      getmerchantSettlementHistorySagaWatcher(),
      upgradeMerchantSagaWatcher(),
      searchMerchantWatcher(),
   ]);
}
